import axios from '../api';
import { _initiateHistory } from './endpoints';
import { getProcessStatus } from './getProcessStatus';

export const initiateHistory = async (id, dateRange) => {
  const [startDate, endDate] = dateRange;

  const status = await getProcessStatus(id);
  if (status?.data === 'Today this datamove is not initiated!!') {
    const res = await axios.get(_initiateHistory(id, startDate, endDate));
    return res;
  }
  return status;
};
