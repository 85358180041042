import axios from '../api';
import { crtReport } from './endpoints';

export const createReport = async report => {
  const isReportFilterEnabled =
      report.isReportFilterEnabled === 'True' ? true : false,
    isReportActionBar = report.isReportActionBar === 'True' ? true : false;

  const userName = JSON.parse(localStorage.getItem('user')).username;

  const body = {
    ...report,
    isReportFilterEnabled,
    isReportActionBar,
    createdBy: userName,
  };

  const res = await axios.post(crtReport, body);
  return res;
};
