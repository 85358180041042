import ampLogo from '../../assets/img/amp_login_logo.png';
import { Outlet, useNavigate } from 'react-router';

import { useSelector } from 'react-redux';

export default function InfoLayout() {
  const navigate = useNavigate();

  const isLogin = useSelector(state => state.loginReducer.data?.token);

  const BackTo = _ => {
    const txt = isLogin ? 'Back' : 'Back to Sign in',
      route = isLogin ? -1 : '/auth';
    return (
      <div className="back_To">
        <span onClick={() => navigate(route)}>{txt}</span>
      </div>
    );
  };

  return (
    <main className="background_holder">
      <section className="page_holder">
        <header className="d-flex justify-content-between mb-1">
          <img className="logo" alt="logo" src={ampLogo} />
          <BackTo />
        </header>
        {/** child component */}
        <Outlet />
      </section>
    </main>
  );
}
