import { all, takeEvery } from 'redux-saga/effects';
import * as type from '../types';

// users
import loginSaga from './users/loginSaga';
import usersSaga from './users/usersSaga';

// roles
import allRolesSaga from './roles/allRolesSaga';

// clients
import allCientsSaga from './clients/allClientsSaga';

// reports
import allReportsSaga from './reports/allReportsSaga';

// udm
import dataMovesSaga from './udm/dataMovesSaga';

export default function* rootSaga() {
  yield all([
    takeEvery(type.SIGNIN_REQUESTED, loginSaga),
    takeEvery(type.FETCH_USERS_REQUESTED, usersSaga),
    takeEvery(type.FETCH_ROLES_REQUESTED, allRolesSaga),
    takeEvery(type.FETCH_CLIENTS_REQUESTED, allCientsSaga),
    takeEvery(type.FETCH_REPORTS_REQUESTED, allReportsSaga),
    takeEvery(type.FETCH_DATAMOVES_REQUESTED, dataMovesSaga),
  ]);
}
