import { call, put } from 'redux-saga/effects';
import { authenticate } from '../../../api/users/authenticate';
import * as type from '../../types';

export default function* loginSaga(action) {
  try {
    const res = yield call(_ => authenticate(action.payload));
    // checking user status

    if (!res?.data?.userStatus) {
      yield put({
        type: type.SIGNIN_FAILED,
        error: 'This user is inactive please contact portal admin',
      });
      return;
    }

    yield put({ type: type.SIGNIN_SUCCESS, response: res?.data });

    // set usersData in localStorage
    localStorage.setItem('user', JSON.stringify(res?.data));
  } catch (e) {
    let msg = e?.response?.data?.message;
    if (!!!msg) {
      msg = e.response;
    }

    yield put({ type: type.SIGNIN_FAILED, error: msg });
  }
}
