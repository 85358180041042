import React from 'react';
import ModelTable from '../../../pages/xReferencePages/modelTable';

const PnLContent = ({ handleTabChange,reportTypeName,clientName,LookUpTableName }) => {

  const check = clientName;
  

  return (
    <>
      <ModelTable tabs="pnl"
      reportTypeName ={reportTypeName}
      clientName={clientName}
      LookUpTableName={LookUpTableName} />
      
     
    </>
  );
};

export default PnLContent;
