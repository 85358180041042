import { useState, useEffect } from 'react';
import { toastError } from '../../utils/toast';
import { _getDataMoveId } from '../../helper/_getDataMoveId';
import DropDown from '../../components/modalComp/dropDown';
import { useNavigate } from 'react-router-dom';
import { MainButton } from '../../components';
import { getXrefClients } from '../../api/xReference/getXrefClients';
import { getLookupTable } from '../../api/xReference/getLookupTable';
import { getReportByLookup } from '../../api/xReference/getReportByLookup';

const commonStyles = {
  margin: 0,
  padding: 0,
};

const ViewFormatter = () => {
  const navigate = useNavigate();

  const [clients, setclients] = useState({
    load: false,
    data: [],
  });

  const [LookupTable, setLookupTable] = useState({
    load: false,
    data: [],
  });

  const [ReportType, setReportType] = useState({
    load: false,
    data: [],
  });

  const [viewData, setviewData] = useState({
    AllClients: [],
    AllLookUpTble: [],
    AllReportType: [],
  });

  const [disabled, setDisabled] = useState(true);

  //get clients
  const getAllclients = _ => {
    if (clients.data.length === 0) {
      (async _ => {
        try {
          setclients(prev => ({
            ...prev,
            load: true,
          }));
          const res = await getXrefClients();
          setclients({ load: false, data: res?.data });
          setLookupTable({ data: [], load: true });
          setReportType({ data: [], load: true });
        } catch (e) {
          setclients(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  };

  //get lookuptables
  useEffect(() => {
    if (viewData.AllClients.length > 0) {
      (async _ => {
        try {
          setLookupTable({ data: [], load: true });
          setReportType({ data: [], load: false });
          setviewData(state => ({ ...state, AllLookUpTble: [], AllReportType: [] }));
          const res = await getLookupTable();
          setLookupTable({ load: false, data: res?.data });
        } catch (e) {
          setLookupTable(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }

    return () => {
      console.log('true');
    };
  }, [JSON.stringify(viewData.AllClients)]);

  //get report names
  useEffect(() => {
    if (viewData.AllLookUpTble.length > 0) {
      const lookupName = viewData.AllLookUpTble[0].value;
      (async _ => {
        try {
          setReportType({ data: [], load: false });
          setviewData(state => ({ ...state, AllReportType: [] }));
          const res = await getReportByLookup(lookupName);
          setReportType({ load: false, data: res?.data });
        } catch (e) {
          setReportType(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }

    return () => {
      console.log('');
    };
  }, [JSON.stringify(viewData.AllLookUpTble)]);

  const handleDropDownState = (list, { name }) => {
    setviewData(state => ({ ...state, [name]: [list] }));

    if (name == 'AllReportType') {
      setDisabled(false);
    }
  };

  //Nvigates to modeltable when clicked
  const handleClickEvent = e => {
    navigate(`/viewFormatter?clientName=${viewData.AllClients[0].value}&accountType=${viewData.AllLookUpTble[0].value}&reportType=${viewData.AllReportType[0].value}`);
 };


  //clears all felds
  const clear = () => {
    setviewData({
      AllClients: [],
      AllLookUpTble: [],
      AllReportType: [],
    });
    setReportType({
      load: false,
      data: [],
    });
    setLookupTable({
      load: false,
      data: [],
    });
    setDisabled(true);
  };

  return (
    <>
      <main className="userPageCont">
        <section className="userPageHead">
          <h4 className="userHeading">View Formatter</h4>
        </section>

        <section className="userPageTable p-3">
          <form className="row g-3">
            <div className="col-md-4 col-lg-3">
              <DropDown
                title="Select Clients"
                isSelectAll={false}
                style={commonStyles}
                name="AllClients"
                value={viewData.AllClients}
                onChange={handleDropDownState}
                onMenuOpen={getAllclients}
                data={clients.data}
                isLoading={clients.load}
                noOptionsMessage={() => 'client does not exist '}
                IndicatorSeparator={false}
                maxMenuHeight={150}
              />
            </div>

            <div className="col-md-4 col-lg-3">
              <DropDown
                title="Select LookupTable"
                isSelectAll={false}
                style={commonStyles}
                name="AllLookUpTble"
                value={viewData.AllLookUpTble}
                onChange={handleDropDownState}
                data={LookupTable.data}
                isLoading={LookupTable.load}
                maxMenuHeight={150}
                noOptionsMessage={() =>
                  viewData.AllClients.length === 0
                    ? 'Select the Clients to proceed'
                    : 'Lookup table does not exist'
                }
              />
            </div>

            <div className="col-md-4 col-lg-3">
              <DropDown
                title="Select Report Type"
                isSelectAll={false}
                style={commonStyles}
                name="AllReportType"
                value={viewData.AllReportType}
                onChange={handleDropDownState}
                data={ReportType.data}
                isLoading={ReportType.load}
                maxMenuHeight={150}
                noOptionsMessage={() =>
                  viewData.AllLookUpTble.length === 0
                    ? 'Select the Lookup table to proceed'
                    : 'Report Does not exist'
                }
              />
            </div>
            <main className="tabsBotmCont">
              <section className="d-flex justify-content-end">
                <div className="tabBtnCont">
                  <MainButton onClick={clear}>Clear</MainButton>
                  <MainButton
                    style={{ background: '#3fbc92' }}
                    onClick={handleClickEvent}
                    disabled={disabled}
                  >
                    Submit
                  </MainButton>
                </div>
              </section>
              <br />
              <hr />
            </main>
          </form>
        </section>
      </main>
    </>
  );
};

export default ViewFormatter;
