import "./styles.css";

const CheckBox = ({ label, ...props }) => {
  return (
    <div className="checkboxCont">
      <label>{label}</label>
      <input type="checkbox" {...props} />
      <div className="checkboxElement" />
    </div>
  );
};

export default CheckBox;
