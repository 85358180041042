import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { userActions } from '../../../../store/actions';

import { updateUser } from '../../../../api/users/updateUser';

// modal
import RightModal from '../../base/rightModal';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';
import { OptionBox } from '../../../common/optionBox';

// validate
import { validateFields, validateLength } from '../../../../helper/validate';

// toast
import { toastSuccess, toastError } from '../../../../utils/toast';

const EditUserModal = ({ row, ...props }) => {
  const { id, ...fields } = row;

  const defaultFields = {
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    userStatus: '',
    mobileNumber: '',
  };

  const dispach = useDispatch();

  // useStates
  const [user, setUser] = useState(defaultFields);

  useEffect(() => {
    const userStatus = fields.userStatus ? 'Active' : 'Inactive',
      userFields = { ...fields, userStatus };

    props.show ? setUser(userFields) : setUser(defaultFields);
  }, [props.show]);

  const handleStateChange = e => {
    const { name, value } = e.target;

    if (validateLength(user[name], value, 60)) {
      setUser(state => ({ ...state, [name]: value }));
    }
  };

  const handleCancelEditUser = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleEditUser = () => {
    const validate = validateFields(user);

    if (!validate) {
      (async _ => {
        try {
          await updateUser(user, id);

          // fetch users after edit
          dispach(userActions.fetchAllusers({ pageNo: '1', pageSize: '1000' }));

          toastSuccess('User edited');

          // close modal
          handleCancelEditUser();
        } catch (e) {
          if (e.message.indexOf('400') !== -1) {
            toastError(`Email already exists`);
          } else {
            toastError(`${e.message}`);
          }
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const FooterComp = () => (
    <div className="d-flex justify-content-between">
      <ModalButt
        text="Cancel"
        className="grayButn"
        onClick={handleCancelEditUser}
      />
      <ModalButt text="Save" className="greenButn" onClick={handleEditUser} />
    </div>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      fullHeight
      headerTxt="Edit User"
      FooterContnt={FooterComp}
    >
      <form>
        <ModalInpF
          type="text"
          req
          title="Username(email)"
          name="userName"
          value={user.userName}
          onChange={handleStateChange}
          disabled
        />
        <ModalInpF
          type="text"
          req
          title="First Name"
          name="firstName"
          value={user.firstName}
          onChange={handleStateChange}
        />
        <ModalInpF
          type="text"
          req
          title="Last Name"
          name="lastName"
          value={user.lastName}
          onChange={handleStateChange}
        />
        <ModalInpF
          type="email"
          req
          title="Email"
          name="email"
          value={user.email}
          onChange={handleStateChange}
          // disabled
        />
        <OptionBox
          title="Status"
          req
          name="userStatus"
          value={user.userStatus}
          onChange={handleStateChange}
          // disabled
        >
          <option></option>
          <option>Active</option>
          <option>Inactive</option>
        </OptionBox>
        <ModalInpF
          field="NumberFormatField"
          req
          title="Mobile"
          allowNegative={false}
          format="+1 (###)-###-####"
          name="mobileNumber"
          value={user.mobileNumber}
          onChange={handleStateChange}
        />
      </form>
    </RightModal>
  );
};

export default EditUserModal;
