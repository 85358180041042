import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const NavToolTip = Tprops => {
  const renderTooltip = props => (
    <Tooltip {...props} id="navItem_toolTip">
      {Tprops.txt}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 150, hide: 0 }}
      overlay={renderTooltip}
    >
      {Tprops.children}
    </OverlayTrigger>
  );
};

export default NavToolTip;
