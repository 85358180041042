import { useState, useEffect } from 'react';
import createNewIcon from '../../assets/img/create_new.png';

import {
  MainButton,
  BootStrapTable,
  CreateClientModal,
  EditClientModal,
  DeleteClientModal,
} from '../../components';

import { useSelector, useDispatch } from 'react-redux';

import { clientActions } from '../../store/actions';

import clientTableConfig from './clientTableConfig';

export default function ClientManagementPage() {
  const dispach = useDispatch();
  const clientsData = useSelector(state => state.clients);

  const [showCrtClntModl, setCrtClntModl] = useState(false);
  const [showEdtClntModl, setEdtClntModl] = useState(false);
  const [deletClntModl, setDeletClntModl] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // getAllRoles
  useEffect(() => {
    dispach(clientActions.fetchAllclients({ pageNo: '1', pageSize: '1000' }));
  }, []);

  const editActionsHandle = row => {
    setSelectedRow(row);
    setEdtClntModl(true);
  };

  const deleteActionsHandle = row => {
    setSelectedRow(row);
    setDeletClntModl(true);
  };

  // table header options
  const tableConfig = clientTableConfig(editActionsHandle, deleteActionsHandle);

  return (
    <main className="userPageCont">
      <section className="userPageHead">
        <h4 className="userHeading">Client Management</h4>
        <MainButton
          buttIcon={createNewIcon}
          onClick={() => setCrtClntModl(true)}
        >
          Create New Client
        </MainButton>

        {/** Modal */}
        <CreateClientModal
          show={showCrtClntModl}
          handleClose={() => setCrtClntModl(false)}
        />
        <EditClientModal
          show={showEdtClntModl}
          handleClose={() => setEdtClntModl(false)}
          row={selectedRow}
        />
        <DeleteClientModal
          show={deletClntModl}
          handleClose={() => setDeletClntModl(false)}
          row={selectedRow}
          delMsg={`Are you sure you want to delete the client ${selectedRow?.name}`}
        />
      </section>

      <section className="userPageTable">
        <BootStrapTable
          enableSearch={{ placeHolder: 'Search By Client, Created By, etc' }}
          tableColumns={tableConfig}
          tableData={clientsData}
        />
      </section>
    </main>
  );
}
