// get
export const _getConnector = `/GetUDMConnectors`;
export const _getClientsByConnector = connectorName =>
  `/GetClientsByConnector?ConnectorName=${connectorName}`;

export const _getConnectorDesktop = `/GetUDMConnectors_Desktop`;
export const _getClientsByConnectorDesktop = connectorName =>
  `/GetClientsByConnector_Desktop?ConnectorName=${connectorName}`;
export const _getDataMoves = `/GetDataMoves`;
export const _getProcessDetails = tab => `/GetProcessDetails?historyorsynch=${tab}`;
export const _getProcessDetailsDesktop = `/GetProcessDetails_Desktop`;
export const _initiateSync = dataMoveId => `/InitiateSync?id=${dataMoveId}`;
export const _initiateHistory = (id, stDate, edDate) =>
  `/InitiateHistory?id=${id}&StartDate=${stDate}&EndDate=${edDate}`;
export const _getprocessStatus = id => `/IsDataMoveRunning?datamoveid=${id}`;
export const _initiateNonUDM = (clientName, historyorsync,startdate,enddate) =>
 `/InitiateNonUDM?clientName=${clientName}&historyorsync=${historyorsync}&startdate=${startdate}&enddate=${enddate}`;
// export const _initiateNonUDM = (clientName, historyorsync,startdate,enddate) =>
//   `http://10.31.2.139:47100/InitiateNonUDM?clientName=${clientName}&historyorsync=${historyorsync}&startdate=${startdate}&enddate=${enddate}`;

  //(string client, string historyorsync,  string startdate, string enddate)
