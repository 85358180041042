import axios from '../api';
import { Level1ByReportType } from './endpoints';


export const getLevel1ByReportType = async (ClientsName,LookuptableName,ReporttypeName,ReportName) => {

const res = await axios.get(Level1ByReportType(ClientsName,LookuptableName,ReporttypeName,ReportName)); 
return res;
};

