import './styles.css';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { reportActions } from '../../../store/actions';

import { useLocation, useSearchParams } from 'react-router-dom';

// api
import { fetchAllReportsById } from '../../../api/reports/getAllReportsById';

// get role
import { _findRoles } from '../../../helper/_findRoles';
import { _sortAsc } from '../../../helper/_sort';

// icons
import ampLogo from '../../../assets/img/amp_login_logo.png';
import userAvator from '../../../assets/img/navartar.jpg';
import {
  BsBarChart,
  BsPerson,
  BsQuestionCircle,
  BsPieChart,
  BsGear
} from 'react-icons/bs';
// import asterisk from '../../assets/img/edit_gray.png'
// nav elements
import NavItem from './NavItem';
import { adminRoutes } from './adminRoutes';
import { xRefRoutes } from './xRefRoutes';

const NavBar = () => {
  const pathName = useLocation().pathname.slice(1);

  const [searchParams, setSearchParams] = useSearchParams(),
    reportId = searchParams.get('reportId');

  const dispatch = useDispatch();

  // use selectors
  const currentUser = useSelector(state => state.loginReducer.data);
  const allReports = useSelector(state => state.reports);

  const firstName = currentUser?.firstName,
    isAdmin = _findRoles('admin') || _findRoles('super admin'),
    isXrefUser =_findRoles('XrefUser');
  const isXref = _findRoles('xrefUser')
 

  const defaultToggleState = {
    reports: false,
    admin: false,
    xRef: false,
  };

  // useStates
  const [toggleDD, setToggleDD] = useState(defaultToggleState);
  const [reports, setReports] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  // fetch reports
  useEffect(() => {
    (async _ => {
      setisLoading(true);
      try {
        if (!!isAdmin) {
          if (allReports.data.length === 0) {
            dispatch(
              reportActions.fetchAllReports({ pageNo: '1', pageSize: '1000' })

            );
           
          }
        } else {
          const resp = await fetchAllReportsById(currentUser?.userId);
          setReports(makeReports(resp.data));
          setisLoading(false);
        }
      } catch (e) {
        setisLoading(false);
        console.log(e.message);
      }
    })();
  }, []);

  // set admin reports
  useEffect(() => {
    if (allReports.data.length > 0 && isAdmin) {
      setReports(makeReports(allReports.data));
      setisLoading(false);
    }
  }, [allReports.data]);

  // to first report
  useEffect(
    _ => {
      if (!!!reportId) {
        if (reports.length > 0 && pathName === 'reports') {
          const { label, id } = reports[0] || {};
          setSearchParams({ reportId: id, name: label });
        } else if (pathName === 'reports') {
          // no reports
          setSearchParams({ reportId: -1 });
        }
      }
    },
    [reports]
  );

  const handleDdToggle = inp => {
    setToggleDD(preObj => ({ ...defaultToggleState, [inp]: !preObj[inp] }));
  };

  const displayUsername = name => {
    return name
      ? name.length > 10
        ? `${name.slice(0, 6).trim()}...`
        : name
      : '';
  };

  const makeReports = data => {
    if (Array.isArray(data)) {
      const reports = data.map(({ name, id }) => {
        return {
          id: id,
          label: name,
          link: `/reports?reportId=${id}&name=${name}`,
          toolTipTxt: name,
        };
      });

      return _sortAsc(reports, 'label');
    }

    return [];
  };

  const handleDropDown = e => {
    const { value } = e.target;
    if (value.trim() === 'Log out') {
      localStorage.clear();
      window.location.reload();
    }
  };
  

  return (
    <nav className="ampNav">
      {/** logo */}
      <span className="nav_logo">
        <a href={process.env.REACT_APP_FRONT_END}>
          <img src={ampLogo} alt="ampliFi logo" />
        </a>
      </span>
    

      {/**  nav Items */}
      <ul className="navBar">
        <span>
          <NavItem
            text="Dashboard"
            Icon={BsBarChart}
            pathname={pathName}
            toggle={{
              name: 'reports', // do not change name
              open: toggleDD.reports,
              setOpen: handleDdToggle,
              getRoutes: {
                routes: reports,
                isLoading: isLoading,
                noRouteMsg: 'No reports',
                toolTip: true,
              },
            }}
          />
        </span>

        <span className="rightItems">
          {isAdmin && (
            <>
              <NavItem
                text="Administration"
                Icon={BsPerson}
                pathname={pathName}
                toggle={{
                  name: 'admin', // do not change name
                  open: toggleDD.admin,
                  setOpen: handleDdToggle,
                  getRoutes: {
                    routes: adminRoutes,
                  },
                }}
              />
              <NavItem
                text="X-Reference Manager"
                Icon={BsGear}
                pathname={pathName}
                toggle={{
                  name: 'xRef', // do not change name
                  open: toggleDD.xRef,
                  setOpen: handleDdToggle,
                  getRoutes: {
                    routes: xRefRoutes,
                  },
                }}
              />
              <NavItem
                text="UDM"
                Icon={BsPieChart}
                link="/udm"
                pathname={pathName}
              />
            </>
          )}

          {
            !!isXref && !isAdmin && (
              <NavItem
              text="X-Reference Manager"
              Icon={BsGear}
              pathname={pathName}
              toggle={{
                name: 'xRef', // do not change name
                open: toggleDD.xRef,
                setOpen: handleDdToggle,
                getRoutes: {
                  routes: xRefRoutes,
                },
              }}
            />
            )

          }

          <NavItem
            text="Help"
            Icon={BsQuestionCircle}
            link="/info/help"
            pathname={pathName}
          />
        </span>
      </ul>

      {/** right buttons */}
      <span className="ampNav_right">
        <div className="userAvator">
          <img src={userAvator} alt="avatar" />
          <label>{displayUsername(firstName)}</label>
        </div>

        <select className="rgt_dropDown" onChange={handleDropDown}>
          <option style={{ display: 'none' }}></option>
          <option>&emsp;Log out&emsp;</option>
        </select>
      </span>
    </nav>
  );
};

export default NavBar;
