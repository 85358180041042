const TermsPage = () => {
  return (
    <main className="info_page_cont">
      <header className="info_head">
        <h1>Terms and Conditions of Use</h1>
        <p>Last Updated: January 5, 2022</p>
      </header>

      <div className="info_contnt_cont">
        <p>
          This policy applies to the AmpliFi Capital Corp (collectively
          amplificap.com, AmpliFi) website located at www.amplificap.com, its
          affiliated network of websites (including any versions optimized for
          viewing on a wireless or tablet device); all email newsletters
          published or distributed by AMPLIFI; all apps published by AMPLIFI;
          and all other interactive features, services, and communications
          provided by AMPLIFI (&ldquo;Sites&rdquo; &ldquo;Portal&rdquo;.),
          however accessed and/or used, that are operated by us, made available
          by us, or produced and maintained by us, and our affiliated companies
          (&ldquo;Affiliates&rdquo;). In addition to the Content on the Sites,
          the Sites may provide you with various opportunities to download
          content and participate in various services (&ldquo;Services&rdquo;).
          BY USING OUR SITES AND SERVICES AND DOWNLOADING CONTENT, YOU ARE
          ACCEPTING THE PRACTICES DESCRIBED IN THESE TERMS OF USE. IF YOU DO NOT
          AGREE TO THESE TERMS OF USE, PLEASE DO NOT USE THE SITES AND EXIT
          IMMEDIATELY. WE RESERVE THE RIGHT TO MODIFY OR AMEND THESE TERMS OF
          USE FROM TIME TO TIME WITHOUT NOTICE. YOUR CONTINUED USE OF OUR SITES
          AND ANY AFFILIATE SITES FOLLOWING THE POSTING OF CHANGES TO THESE
          TERMS WILL MEAN YOU ACCEPT THOSE CHANGES. UNLESS WE PROVIDE YOU WITH
          SPECIFIC NOTICE, NO CHANGES TO OUR TERMS OF USE WILL APPLY
          RETROACTIVELY. By using the Sites, you agree to such terms and
          conditions.
        </p>
        <p>&nbsp;</p>
        <p>
          This is a legal agreement between you (&ldquo;you&rdquo; or
          &ldquo;user&rdquo;) and AMPLIFI that states the material terms and
          conditions that govern your use of the Sites. This agreement, together
          with all updates, supplements, additional terms, and all of
          AMPLIFI&rsquo;s rules and policies collectively constitute this
          &ldquo;Agreement&rdquo; between you and AMPLIFI. BY ACCESSING THE
          SITES, YOU AGREE TO BE LEGALLY BOUND BY THIS AGREEMENT. IF YOU DO NOT
          AGREE TO THE TERMS OF SERVICE AND CONDITIONS OF USE STATED HEREIN,
          PLEASE IMMEDIATELY LEAVE THIS WEB SITE/ PORTAL.
        </p>
        <p>&nbsp;</p>
        <h3>Site Access License</h3>
        <p>&nbsp;</p>
        <p>
          AMPLIFI grants you a limited, revocable, non-exclusive,
          non-transferable license to access and make use of the Sites and their
          content. AMPLIFI does not grant you a license to modify all or any
          portion of the Sites or their content. This license does not include
          any resale or commercial use of the Sites or their contents; any
          collection and use of any images or third party content on the Sites;
          any derivative use of the Sites or their contents; any downloading or
          copying of information for the benefit of another company or user; or
          any use of data mining, robots, or similar data gathering and
          extraction tools. The Sites and/or any portion of the Sites may not be
          reproduced, sold, resold, visited or otherwise exploited for any
          commercial purpose without AMPLIFI&rsquo;s express written consent.
          You will not access or attempt to access content on the Sites through
          any interface except for the publicly-provided interfaces provided by
          AMPLIFI. You will not frame or utilize framing techniques to enclose
          any trademark, logo or other proprietary information (including
          images, text, page layout or form) of AMPLIFI, its content providers
          or its Affiliates without express written consent. You will not use
          any meta tags or any other &ldquo;hidden text&rdquo; utilizing our
          name or trademarks without our express written consent. Additionally,
          you agree that you will not: (a) take any action that imposes, or may
          impose in our sole discretion, an unreasonable or disproportionately
          large load on our infrastructure; (b) interfere or attempt to
          interfere with the proper working of the Sites or any activities
          conducted on the Sites; or (c) bypass any measures we may use to
          prevent or restrict access to the Sites. Any unauthorized use
          automatically terminates the permissions and/or licenses granted by us
          to you.
        </p>
        <p>&nbsp;</p>
        <h3>Copyright and Ownership</h3>
        <p>&nbsp;</p>
        <p>
          All of the content featured or displayed on the Sites, including
          without limitation text, graphics, photographs, images, moving images,
          sound, and illustrations (&ldquo;Content&rdquo;), is owned by AMPLIFI,
          its licensors, vendors, agents and/or its Content providers. All
          elements of the Sites, including without limitation the general design
          and the Content, are protected by trade dress, copyright, moral
          rights, trademark, and other laws relating to intellectual property
          rights. The Services and the Sites may only be used for the intended
          purpose for which such Sites and Services are being made available.
          Except as may be otherwise indicated in specific documents within the
          Sites or as permitted by copyright law, you are authorized to view,
          play, print and download copyrighted documents, audio and video found
          on our Sites for personal, informational, and noncommercial purposes
          only. Except as permitted by copyright law, you may not modify any of
          the materials and you may not copy, distribute, transmit, display,
          perform, reproduce, publish, license, create derivative works from,
          transfer or sell any information or work contained on the Sites.
          Except as authorized under the copyright laws, you are responsible for
          obtaining permission before reusing any copyrighted material that is
          available on the Sites. You shall comply with all applicable domestic
          and international laws, statutes, ordinances and regulations regarding
          your use of the Sites and Services. The Sites, their Content, and all
          related rights shall remain the exclusive property of AMPLIFI or its
          licensors unless otherwise expressly agreed. You will not remove any
          copyright, trademark or other proprietary notices from material found
          on these Sites.
        </p>
        <p>&nbsp;</p>
        <h3>Trademarks/No Endorsement</h3>
        <p>&nbsp;</p>
        <p>
          All trademarks, service marks, and trade names of AMPLIFI used herein
          (including but not limited to: AMPLIFI name, the Sites design, and any
          logos) (collectively &ldquo;Marks&rdquo;) are trademarks or registered
          trademarks of AMPLIFI or its affiliates, partners, vendors, or
          licensors. You may not use, copy, reproduce, republish, upload, post,
          transmit, distribute, or modify AMPLIFI trademarks in any way,
          including in advertising or publicity pertaining to distribution of
          materials on the Sites, without AMPLIFI&rsquo;s prior written consent.
          The use of AMPLIFI trademarks on any other web site or network
          computer environment is not allowed. AMPLIFI prohibits the use of
          AMPLIFI trademarks as a &ldquo;hot&rdquo; link on or to any other web
          site unless establishment of such a link is approved in advance. You
          shall not use AMPLIFI&rsquo;s name or any language, pictures, or
          symbols which could, in AMPLIFI&rsquo;s judgment, imply
          AMPLIFI&rsquo;s endorsement in any (a) written or oral advertising or
          presentation, or (b) brochure, newsletter, book, or other written
          material of whatever nature, without prior written consent.
        </p>
        <p>&nbsp;</p>
        <h3>Submission of Information</h3>
        <p>&nbsp;</p>
        <p>
          In consideration of your use of the Sites, you will: (a) provide true,
          accurate, current and complete information about yourself as prompted
          by the Sites&rsquo; submission page (such information being the
          &ldquo;Contact Information&rdquo;). If you provide any information
          that is untrue, inaccurate, not current or incomplete, or AMPLIFI has
          reasonable grounds to suspect that such information is untrue,
          inaccurate, not current or incomplete, AMPLIFI has the right to refuse
          any and all current or future use of the Sites (or any portion
          thereof). We have the right to provide user Content or use records and
          related information under certain circumstances (such as in response
          to legal responsibility, lawful process, orders, subpoenas, or
          warrants, or to protect our rights, customers or business).
        </p>
        <p>&nbsp;</p>
        <h3>Inappropriate Material</h3>
        <p>&nbsp;</p>
        <p>
          You are prohibited from using the Sites to post or send any unlawful,
          threatening, defamatory, libelous, obscene, pornographic or profane
          material or any material that could constitute or encourage conduct
          that would be considered a criminal offense or give rise to civil
          liability, or otherwise violate any law. You further understand and
          agree that sending unsolicited advertisements or &ldquo;spam&rdquo; to
          any user of the Sites or the Sites or through AMPLIFI computer systems
          is expressly prohibited by these Terms of Use. In addition to any
          remedies that we may have at law or in equity, if we determine, in our
          sole discretion, that you have violated or are likely to violate the
          foregoing prohibitions, we may take any action we deem necessary to
          cure or prevent the violation, including without limitation, banning
          you from leaving comments or participating in our forums and
          communities and/or the immediate removal of the related materials from
          the Sites at any time without notice. We will fully cooperate with any
          law enforcement authorities or court order or subpoena requesting or
          directing us to disclose the identity of anyone posting such
          materials.
        </p>
        <p>&nbsp;</p>
        <h3>Third Party Links</h3>
        <p>&nbsp;</p>
        <p>
          From time to time, the Sites may contain links to web sites that are
          not owned, operated or controlled by AMPLIFI or its Affiliates. All
          such links are provided solely as a convenience to you. If you use
          these links, you will leave the Sites. Neither we nor any of our
          respective affiliates are responsible for any content, materials or
          other information located on or accessible from any other web site.
          Neither we nor any of our respective Affiliates endorse, guarantee, or
          make any representations or warranties regarding any other web sites,
          or any content, materials or other information located or accessible
          from any other web sites, or the results that you may obtain from
          using any other web sites. If you decide to access any other web sites
          linked to or from this Sites, you do so entirely at your own risk.
        </p>
        <p>&nbsp;</p>
        <h3>Access and Interference</h3>
        <p>&nbsp;</p>
        <p>
          You agree that you will not use any robot, spider, scraper, or other
          automated means to access the Sites for any purpose without our
          express written permission. Additionally, you agree that you will not:
          (a) take any action that imposes, or may impose in our sole discretion
          an unreasonable or disproportionately large load on our
          infrastructure; (b) interfere or attempt to interfere with the proper
          working of the site or any activities conducted on the Sites; or (c)
          bypass any measures we may use to prevent or restrict access to the
          Sites.
        </p>
        <p>&nbsp;</p>
        <h3>Right to Takedown Content</h3>
        <p>&nbsp;</p>
        <p>
          AMPLIFI shall have the right in our sole discretion to edit, refuse to
          post, or remove any material submitted to or posted on the Sites at
          any time without notice. Without limiting the foregoing, we shall have
          the right to remove any material that we find to be in violation of
          the provisions hereof or otherwise objectionable, and the additional
          right to deny access to any user who fails to conform to any provision
          of these Terms of Use.
        </p>
        <p>&nbsp;</p>
        <h3>Representations and Warranties</h3>
        <p>&nbsp;</p>
        <p>
          You represent that you are over the age of 18, have the right and
          authority to enter into this Agreement, are fully able and competent
          to satisfy the terms, conditions, and obligations herein, and your use
          of the Sites and Services is and will be in compliance with all
          applicable laws.
        </p>
        <p>&nbsp;</p>
        <h3>DISCLAIMERS</h3>
        <p>&nbsp;</p>
        <p>
          YOUR USE OF THIS SITE IS AT YOUR RISK. THE INFORMATION, MATERIALS AND
          SERVICES PROVIDED ON OR THROUGH THE SITES ARE PROVIDED &ldquo;AS
          IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; WITHOUT ANY WARRANTIES OF ANY
          KIND INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, SECURITY OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY.
          NEITHER AMPLIFI, NOR ANY OF ITS AFFILIATES, SUPPLIERS, SERVICE
          PROVIDERS OR LICENSORS WARRANT THE ACCURACY OR COMPLETENESS OF THE
          INFORMATION, MATERIALS, OR SERVICES PROVIDED ON OR THROUGH THE SITES.
          THE INFORMATION, MATERIALS AND SERVICES PROVIDED ON OR THROUGH THE
          SITES MAY BE OUT OF DATE, AND NEITHER AMPLIFI, NOR ANY OF ITS
          AFFILIATES MAKES ANY COMMITMENT OR ASSUMES ANY DUTY TO UPDATE SUCH
          INFORMATION, MATERIALS, OR SERVICES. THE FOREGOING EXCLUSIONS OF
          IMPLIED WARRANTIES DO NOT APPLY TO THE EXTENT PROHIBITED BY LAW.
          PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH PROHIBITIONS.
        </p>
        <p>&nbsp;</p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
          AMPLIFI OR THROUGH THE SITES, CONTENT, AND SERVICES WILL CREATE ANY
          WARRANTY NOT EXPRESSLY MADE HEREIN. WITHOUT LIMITATION OF THE
          FOREGOING, AMPLIFI ASSUMES NO OBLIGATION TO REVIEW AND EVALUATE, AND
          EXPRESSLY DISCLAIMS ALL RESPONSIBILITY FOR YOUR USE OF, ANY
          INDIVIDUAL, FIRM OR COMPANY LISTED ON, LINKED FROM, OR MADE AVAILABLE
          THROUGH THE SITE.
        </p>
        <p>&nbsp;</p>
        <h3>LIMITATIONS OF LIABILITY</h3>
        <p>&nbsp;</p>
        <p>
          AMPLIFI does not assume any responsibility, nor will be liable, for
          any damages to, or any viruses that may infect your computer,
          telecommunication equipment, or other property caused by or arising
          from your access to, use of, or browsing these Sites, or your
          downloading of any information or materials from these Sites. IN NO
          EVENT WILL AMPLIFI, OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES,
          SHAREHOLDERS, AFFILIATES, AGENTS, SUCCESSORS OR ASSIGNS, NOR ANY PARTY
          INVOLVED IN THE CREATION, PRODUCTION, HOSTING, DISPLAY, OR
          TRANSMISSION OF THE SITES, BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
          INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
          (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS,
          LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE USE, INABILITY
          TO USE, OR THE RESULTS OF USE OF THE SITES, ANY WEBSITES LINKED TO THE
          SITES, OR THE MATERIALS, INFORMATION OR SERVICES CONTAINED ON ANY OR
          ALL SUCH SITES, WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER
          LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. THE FOREGOING LIMITATIONS OF LIABILITY DO NOT APPLY TO THE
          EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH
          PROHIBITIONS.
        </p>
        <p>&nbsp;</p>
        <p>
          IN THE EVENT OF ANY PROBLEM WITH THE SITES OR ANY CONTENT, YOU AGREE
          THAT YOUR SOLE REMEDY IS TO CEASE USING THE SITES. IN NO EVENT SHALL
          AMPLIFI&rsquo;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND
          CAUSES OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED
          TO, NEGLIGENCE), OR OTHERWISE EXCEED THE GREATER OF (A) TWENTY FIVE
          DOLLARS (US $25.00) OR (B) THE VALUE OF ANY PRODUCTS OR SERVICES
          PURCHASED BY YOU ON THE SITES.
        </p>
        <p>&nbsp;</p>
        <h3>Indemnity</h3>
        <p>&nbsp;</p>
        <p>
          You agree to defend, indemnify and hold AMPLIFI and any Affiliate or
          individual harmless from any and all liabilities, costs, and expenses,
          including reasonable attorneys&rsquo; fees, related to or in
          connection with (a) the use of the Sites or the Internet or your
          placement or transmission of any message or information on the Sites
          by you or your authorized users; (b) your violation of any term of
          this Agreement, including without limitation, your breach of any of
          the representations and warranties above; (c) your violation of any
          third party right, including without limitation any right of privacy,
          publicity rights or intellectual property rights; (d) your violation
          of any law, rule, or regulation of the United States or any other
          country; or (e) any claim or damages that arise as a result of any
          information or Content that you provide to AMPLIFI.
        </p>
        <p>&nbsp;</p>
        <h3>Release</h3>
        <p>&nbsp;</p>
        <p>
          In the event that you have a dispute with one or more other users of
          the Sites or with any other third party arising from your use of
          information obtained from the Sites, you release AMPLIFI (and our
          officers, directors, agents, subsidiaries, joint ventures and
          employees) from claims, demands and damages (actual and consequential)
          of every kind and nature, known and unknown, suspected and
          unsuspected, disclosed and undisclosed, arising out of or in any way
          connected with such disputes, including without limitation, any
          intellectual property dispute or any dispute over the payment of the
          challenge.
        </p>
        <p>&nbsp;</p>
        <h3>Termination</h3>
        <p>&nbsp;</p>
        <p>
          You or we may suspend or terminate your account or your use of the
          Sites at any time, for any reason or for no reason. You are personally
          liable for any orders placed or charges incurred through your account
          prior to termination. We may also block your access to our Sites in
          the event that (a) you breach these Terms of Use; (b) we are unable to
          verify or authenticate any information you provide to us; or (c) we
          believe that your actions may cause financial loss or legal liability
          for you, our users, or us.
        </p>
        <p>&nbsp;</p>
        <h3>Force Majeure</h3>
        <p>&nbsp;</p>
        <p>
          Neither AMPLIFI nor you shall be responsible for damages or for delays
          or failures in performance resulting from acts or occurrences beyond
          our or your, as applicable, reasonable control, including, without
          limitation: fire, lightning, explosion, power surge or failure, water,
          acts of God, war, revolution, civil commotion or acts of civil or
          military authorities or public enemies; any law, order, regulation,
          ordinance, or requirement of any government or legal body or any
          representative of any such government or legal body; labor unrest,
          including without limitation, strikes, slowdowns, picketing, or
          boycotts; or inability to secure raw materials, transportation
          facilities, fuel or energy shortages, or acts or omissions of other
          common carriers.
        </p>
        <p>&nbsp;</p>
        <h3>Privacy</h3>
        <p>&nbsp;</p>
        <p>
          Data collection and use, including data collection and use of
          personally identifiable information is governed by AMPLIFI&rsquo;s
          Privacy Policy which is incorporated into and is a part of this
          Agreement.
        </p>
        <p>&nbsp;</p>
        <h3>General</h3>
        <p>&nbsp;</p>
        <p>
          Any claim relating to, and the use of, the Sites and the materials
          contained herein is governed by the laws of the state of Delaware. A
          printed version of these Terms of Use will be admissible in judicial
          and administrative proceedings based upon or relating to these Terms
          of Use to the same extent and subject to the same conditions as other
          business documents and records originally generated and maintained in
          printed form. We do not guarantee continuous, uninterrupted, or secure
          access to our Sites or Services, and operation of the Sites may be
          interfered with by numerous factors outside of our control. If any
          provision of these Terms of Use is held to be invalid or
          unenforceable, such provision shall be struck and the remaining
          provisions shall be enforced. You agree that these Terms of Use and
          all incorporated agreements may be automatically assigned by AMPLIFI
          in our sole discretion. Headings are for reference purposes only and
          in no way define, limit, construe or describe the scope or extent of
          such section. Our failure to act with respect to a breach by you or
          others does not waive our right to act with respect to subsequent or
          similar breaches. These Terms of Use set forth the entire
          understanding and agreement between us with respect to the subject
          matter hereof. Sections: (Access and Interference),&nbsp; (Limitations
          of Liability),&nbsp; (Indemnity),&nbsp; (Release),&nbsp; (General);
          and&nbsp; (Entire Agreement) shall survive any termination or
          expiration of this Agreement.
        </p>
        <p>&nbsp;</p>
        <h3>Entire Agreement</h3>
        <p>&nbsp;</p>
        <p>
          These terms and conditions are the entire agreement between you and
          AMPLIFI and supersede any prior understandings or agreements (written
          or oral).
        </p>
        <p>&nbsp;</p>
        <h3>Additional Assistance</h3>
        <p>&nbsp;</p>
        <p>
          If you do not understand any of the foregoing Terms of Use or if you
          have any questions or comments, we invite you to contact our Help Desk
          at <a href="mailto:support@amplificap.com">support@amplificap.com</a>
          .Notice. All Site design, graphics, text selections, arrangements, and
          all software are Copyright &copy; Amplifi Capital Corp. or its
          licensors. ALL RIGHTS RESERVED.
        </p>
        <p>&nbsp;</p>
      </div>
    </main>
  );
};

export default TermsPage;
