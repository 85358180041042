import PT from 'prop-types';

import './styles.css';

// assets
import sort1 from '../../assets/img/sort_by.png';
import sort2 from '../../assets/img/sort_by_green.png';

// loader
import { Loader } from '..';

// table components
import { ColumnToggle } from './ColumnToggle';
import { TableSearchBar } from './TableSearchBar';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

const BootStrapTable = ({
  enableColumnToggle,
  enableSearch,
  selectRow,
  tableColumns,
  tableData,
  sizePerPageList,
  keyField,
}) => {
  // on row Clicked
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     alert(`clicked on row with index: ${rowIndex}`);
  //   },
  // };

  // on search
  const onSearch = ({ searchText, value, column, row }) => {
    if (typeof value !== 'undefined') {
      return value.startsWith(searchText);
    }
    return false;
  };

  // sort caret desgin
  const sortOption = {
    sortCaret: (order, column) => {
      const sortIcon = !order
        ? sort1
        : order === 'asc'
        ? sort1
        : order === 'desc'
        ? sort2
        : null;

      if (sortIcon !== null) {
        return (
          <span className="caretIcon">
            <img src={sortIcon} alt="sortIcon" />
          </span>
        );
      }
      return null;
    },
  };

  const NoData_Loading = () => (
    <div className="NoDataCont">
      {tableData.loading ? <Loader size="25" /> : <p>No Data Found</p>}
    </div>
  );

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
    return (
      <div>
        {pageWithoutIndication.map((p, index) => (
          <button
            key={index}
            className="pagn_butn"
            onClick={() => onPageChange(p.page)}
          >
            {p.page}
          </button>
        ))}
      </div>
    );
  };

  const Pagin_Options = {
    pageListRenderer,
    sizePerPageList: sizePerPageList,
  };

  return (
    <main className="custmTbleCont">
      <ToolkitProvider
        keyField={keyField}
        columns={tableColumns}
        data={tableData.loading ? [] : tableData.data}
        columnToggle
        search={{ onSearch }}
        exportCSV={{
          fileName: 'amplifyData.csv',
          exportAll: true,
        }}
      >
        {props => (
          <>
            {enableSearch && (
              <TableSearchBar {...props} searchBarP={enableSearch} />
            )}

            {!!enableColumnToggle && (
              <ColumnToggle {...props.columnToggleProps} />
            )}
            <BootstrapTable
              {...props.baseProps}
              headerWrapperClasses="custmTbleHead"
              rowClasses="custmTbleRow"
              sort={sortOption}
              selectRow={selectRow}
              // rowEvents={rowEvents}
              pagination={paginationFactory(Pagin_Options)}
              noDataIndication={NoData_Loading}
            />
          </>
        )}
      </ToolkitProvider>
    </main>
  );
};

BootStrapTable.defaultProps = {
  sizePerPageList: [
    {
      text: '50',
      value: 50,
    },
    {
      text: '60',
      value: 60,
    },
    {
      text: '70',
      value: 70,
    },
  ],
  keyField: 'id',
};

BootStrapTable.propTypes = {
  enableColumnToggle: PT.bool,
  enableSearch: PT.shape({
    placeHolder: PT.string.isRequired,
  }),
  selectRow: PT.object,
  tableColumns: PT.array.isRequired,
  tableData: PT.shape({
    data: PT.array.isRequired,
    loading: PT.bool.isRequired,
  }).isRequired,
  sizePerPageList: PT.arrayOf(
    PT.shape({
      text: PT.string.isRequired,
      value: PT.number.isRequired,
    })
  ),
  keyField: PT.string,
};

export default BootStrapTable;
