import { useEffect } from 'react';

// import './styles.css';
import { Tab, Tabs } from 'react-bootstrap';
import PnLContent from './PnL';
import BSContent from './BS';
import CFContent from './CF';
import ReportNameTab from './reportNameTab';

{/* <XrefTabsForm handleTabChange={handleTabChange} reportTypeName={reportTypeName} clientName={clientName}
          LookUpTableName={LookUpTableName} */}

export default function XrefTabsForm({ handleTabChange,reportTypeName,clientName,LookUpTableName }) {
  useEffect(() => {
    // defaultActiveKey
    handleTabChange('pnl');
  }, []);
const reportName= reportTypeName.charAt(0).toUpperCase() + reportTypeName.slice(1)+"- ";

const getTitle =()=>{

  // reportTypeName !== "n/a" ? {reportName} : null
  if (reportTypeName !== "na"){
    return reportName
  }
  else return "" ;
  // return reportTypeName.charAt(0).toUpperCase() + reportTypeName.slice(1)+"-"

}
  return (

    <>
    <div  >
        <div >
    <Tabs fill justify  id="uncontrolled-tab-example"
      defaultActiveKey="pnl"
      onSelect={eventKey => handleTabChange(eventKey)}
      unmountOnExit={true}
      mountOnEnter={true}
      transition={false}
    >
      <Tab eventKey= "pnl" title={getTitle().toUpperCase()+""+" P&L"}>
        <PnLContent  
        reportTypeName ={reportTypeName}
        clientName={clientName}
        LookUpTableName={LookUpTableName} />
      </Tab>

      <Tab eventKey="bs" title={getTitle().toUpperCase()+""+" BS"}>
        <BSContent
        reportTypeName ={reportTypeName}
        clientName={clientName}
        LookUpTableName={LookUpTableName}/>
      </Tab>

      <Tab eventKey="cf" title={getTitle().toUpperCase()+""+" CF"}>
        <CFContent
        reportTypeName ={reportTypeName}
        clientName={clientName}
        LookUpTableName={LookUpTableName}/>
      </Tab>
      

      {/* {reportName !== "n/a" 
      ? <Tab eventKey={reportName}
       style={{textTransform:"capitalize"}}
        title={reportTypeName.charAt(0).toUpperCase() + reportTypeName.slice(1)}><ReportNameTab/></Tab> : console.warn({reportName})} */}

    </Tabs>
    </div>
    </div>
    </>
  );
}
