import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { clientActions } from '../../../../store/actions';

// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';

import { createClient } from '../../../../api/clients/createClient';

// validate
import validator from 'validator';

// toast
import { toastError } from '../../../../utils/toast';

const CreateClientModal = props => {
  const dispach = useDispatch();

  // states
  const [isClientCreated, setClientCreated] = useState(false);
  const [client, setClient] = useState({
    name: '',
  });

  const handleStateChange = e => {
    const { name, value } = e.target;
    setClient(state => ({ ...state, [name]: value }));
  };

  const emptyFields = () => {
    setClient({
      name: '',
    });
  };

  const validateFields = ({ name }) => {
    if (validator.isEmpty(name)) return 'Please enter client Name';

    // validated
    return false;
  };

  const handleCreateClient = () => {
    const validate = validateFields(client);
    if (!validate) {
      (async _ => {
        try {
          const res = await createClient(client);

          // fetch all clients
          dispach(
            clientActions.fetchAllclients({ pageNo: '1', pageSize: '1000' })
          );

          // empty fields
          emptyFields();
          // show success message
          setClientCreated(true);
        } catch (e) {
          toastError(`${e.message}`);
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const handleCloseClient = () => {
    const { handleClose } = props;

    setTimeout(() => setClientCreated(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!isClientCreated ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Clear" className="grayButn" onClick={emptyFields} />
          <ModalButt
            text="Create"
            className="greenButn"
            onClick={handleCreateClient}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseClient}
        />
      )}
    </>
  );

  const ClientCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Created</p>
      <span>Client created successfully.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      headerTxt="Create New Client"
      FooterContnt={FooterComp}
    >
      {!isClientCreated ? (
        <form>
          <ModalInpF
            title="Client Name"
            req
            name="name"
            value={client.name}
            onChange={handleStateChange}
          />
        </form>
      ) : (
        <ClientCreatedComp />
      )}
    </RightModal>
  );
};

export default CreateClientModal;
