import axios from '../api';
import { updateReport } from './endpoints';

export const editReport = async (report, id) => {
  const isReportFilterEnabled =
      report.isReportFilterEnabled === 'True' ? true : false,
    isReportActionBar = report.isReportActionBar === 'True' ? true : false;

  const userName = JSON.parse(localStorage.getItem('user')).username;

  const body = {
    ...report,
    isReportFilterEnabled,
    isReportActionBar,
    modifiedBy: userName,
  };

  const res = await axios.put(updateReport(id), body);
  return res;
};
