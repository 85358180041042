import axios from '../api';
import { createRle } from './endpoints';

export const createRole = async role => {
  const userName = JSON.parse(localStorage.getItem('user')).username;
  const body = { ...role, createdBy: userName };

  const { data } = await axios.post(createRle, body);
  return data;
};
