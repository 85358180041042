import { useState, useEffect } from 'react';
import { Cell } from 'rsuite-table';

export const EditCell = ({ rowData, dataKey, onChange, onClick, ...props }) => {
  const editing = rowData.status === 'EDIT' && rowData[dataKey];
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setEdit(editing);

    return () => {};
  }, [editing]);

  const handleState = e => {
    try {
      
      //Prevent the input box from disappearing when its blank or the pre existing dats is deleted
      if (e.target.value.length > 1 && e.target.value.trim()) {
        onChange && onChange(rowData.id, dataKey, e.target.value.trim());
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //Saves the data when the enter  is clicked
  const handleEnter = () => {
    onClick && onClick(rowData.id);
    setEdit(false);
  };
  return (
    <Cell {...props} className={editing ? 'table-content-editing' : ''}>
      {/* input box is displayed when the edit sates is true */}
      {edit ? (
        <input
          className="input"
          defaultValue={rowData[dataKey]}
          onChange={handleState}
          style={{ height: '40px' }}
          maxLength={50}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleEnter();
            }
          }}
          type="text"
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};
