import axios from '../api';
import { getProcessStatus } from './getProcessStatus';
import {_initiateNonUDM} from './endpoints'

export const initiateDesktop = async (selectedConnector,historyorsync,startdate,enddate) => {
  // const status = await getProcessStatus(payload?.datamoveid);
  // if (status?.data === 'Today this datamove is not initiated!!') {
  //   let url;
  //   if (selectedConnector === 'fishbowl')
  //     url = 'https://websvc1.amplificap.com/fishbowl/fbextractorapi/pull';
  //   else if (selectedConnector === 'locate')
  //     url = 'https://websvc1.amplificap.com/locate/fbextractorapi/pull';
  //   else {
  //     const env = process.env.REACT_APP_ENV;
  //     if (env === 'dev' || env === 'devServer') url = 'http://10.31.2.139:6016/fbextractorapi/pull';
  //     else if (env === 'stage') url = 'http://10.31.2.208:6016/fbextractorapi/pull';
  //     else if (env === 'prod') url = 'http://192.168.17.11:6016/fbextractorapi/pull';
  //   }

  //   const body = payload;
  //   return await axios.post(url, body);
  // }
  // return status;
  // 
  const res = await axios.get(_initiateNonUDM(selectedConnector,historyorsync,startdate,enddate));
 
  return res;
  

};
