import React, { useEffect, useState } from 'react';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import './xrefStyles.css';
import { EditCell } from '../../components/rSuiteTable/EditCell';
import { ActionCell } from '../../components/rSuiteTable/ActionCell';
import { MainButton } from '../../components';
import { _sortAsc } from '../../helper/_sort';
import { toastError } from '../../utils/toast';
import { toastSuccess } from '../../utils/toast';
import { toastInfo } from '../../utils/toast';
import { getAllViewData } from '../../api/xReference/getViewModel';
import { updateLevel1, updateLevel2, updateLevel3 } from '../../api/xReference/updateLevelsData';
import { newLevel1, newLevel2, newLevel3 } from '../../api/xReference/newLevelData';

//To sort the data from api
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';

const tableStyle = {
  marginLeft: 'auto',
  marginRight: 'auto',
};

const ModelTable = ({ tabs, reportTypeName, clientName, LookUpTableName }) => {
  //Get the infos
  const tabForm = tabs;
  const reportNameSelected = reportTypeName;
  const clientNameSelected = clientName;
  const lookUpTableNameSelected = LookUpTableName;

  // Data Variables
  const [data, setData] = useState([]);
  const [Copydata, setCopyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clickData, setClickData] = useState({});
  const [levels, setlevels] = useState({
    one: '',
    two: '',
    three: '',
  });

  //Input field disabling
  const [disabled, setDisabled] = useState(false);
  const [disabledTwo, setDisabledTwo] = useState(false);
  const [disabledThree, setDisabledThree] = useState(false);
  const [disabledSubmit, setdisabledSubmit] = useState(false);

  //get data  when page is loaded
  useEffect(() => {
    getTableData();
  }, []);

  //perform api get request
  const getTableData = async _ => {
    try {
      const tabForm = tabs;
      const reportNameSelected = reportTypeName;
      const clientNameSelected = clientName;
      const lookUpTableNameSelected = LookUpTableName;

      const res = await getAllViewData(
        clientNameSelected,
        lookUpTableNameSelected,
        reportNameSelected,
        tabForm
      );

      //sort the api response
      let sortedData = orderBy(res.data, ['parent', 'id'], ['asc', 'asc']);

      const newArr = sortedData.map(object => {
        if (object.level2 !== null) {
          return { ...object, level2: object.level2.trim(), level3: '' };
        } else if (object.level1 !== null) {
          return { ...object, level1: object.level1.trim(), level2: '', level3: '' };
        }

        return object;
      });

      setData(newArr);
      CopyResponse();
      setLoading(false);
    } catch (e) {
      toastError(e.message);
    }
  };

  // get the data for checking if the value is manipulated
  const CopyResponse = async _ => {
    const res = await getAllViewData(
      clientNameSelected,
      lookUpTableNameSelected,
      reportNameSelected,
      tabForm
    );
    const resData = res.data;
    const dummy = cloneDeep(resData);
    setCopyData(dummy);
    clear();
  };

  // for values entered in the text box
  const handleChange = (id, key, value) => {
    try {
      clear();
      const nextData = Object.assign([], data);
      nextData.find(item => item.id === id)[key] = value;
      setData(nextData);
    } catch (e) {
      toastError(e.message);
    }
  };

  //find the data with id
  const dataAll = id => {
    const demoData = Object.assign([], Copydata);
    const actualData = demoData.find(item => item.id === id);
    return actualData;
  };

  //Edit the values on the table
  const handleEditState = async id => {
    try {
      clear();

      const nextData = Object.assign([], data);

      const activeItem = nextData.find(item => item.id === id);
      const actualData = dataAll(id);

      const sele = activeItem;
      activeItem.status = activeItem.status ? null : 'EDIT';
      setData(nextData);
      //Level 3 data
      if (activeItem.level3 !== '') {
        const id = activeItem.id;
        const level3 = activeItem.level3;

        const body = {
          level3: activeItem.level3,
          id: activeItem.id,
        };
        if (activeItem.status !== 'EDIT') {
          if (actualData.level3 !== level3) {
            const res = await updateLevel3(id, level3);
            if (res.status == 200) {
              toastSuccess('updated successfully');
              clear();
              CopyResponse();
              getTableData();
            } else {
              toastError(res.status);
            }
          } else {
            toastInfo('Not Changed');
            handleRefresh();
          }
        }
      }
      //level2
      else if (activeItem.level2 !== '') {
        const level2 = activeItem.level2;
        const id = activeItem.id;
        const body = {
          level2: activeItem.level2,
          id: activeItem.id,
        };

        if (activeItem.status !== 'EDIT') {
          if (actualData.level2 !== level2) {
            const res = await updateLevel2(id, level2);
            if (res.status == 200) {
              toastSuccess('updated successfully');
              CopyResponse();
              clear();
            } else {
              toastError(res.status);
            }
          } else {
            toastInfo('Not Changed');
            handleRefresh();
          }
        }
      }
      //level1
      else if (activeItem.level1 !== '') {
        const level1 = activeItem.level1;
        const id = activeItem.id;

        {
          if (activeItem.status !== 'EDIT') {
            if (actualData.level1 !== level1) {
              const res = await updateLevel1(id, level1);
              if (res.status == 200) {
                toastSuccess('updated successfully');
                CopyResponse();
                clear();
              } else {
                toastError(res.status);
              }
            } else {
              toastInfo('Not Changed');
              handleRefresh();
            }
          }
        }
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleRefresh = () => {
    setClickData({});
    getTableData();
  };

  //Disabling the input fields when one is active
  const levelOneInput = e => {
    try {
      if (e === 'one') {
        setDisabledTwo(true);
        setDisabledThree(true);
      } else if (e === 'two') {
        setDisabled(true);
        setDisabledThree(true);
      } else if (e === 'three') {
        setDisabledTwo(true);
        setDisabled(true);
      }
    } catch (e) {}
  };

  // values entered  in the levels input box
  const handleState = e => {
    try {
      const { name, value } = e.target;
      setlevels(state => ({ ...state, [name]: value }));
      name !== '' ? levelOneInput(name) : setDisabledTwo(false);

      if (value.length < 1) {
        clear();
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  //new levels post
  const handleSubmit = async _ => {
    try {
      setdisabledSubmit(true);
      setTimeout(() => {
        setdisabledSubmit(false);
      }, 2000);

      if (levels.three !== '') {
        const level3 = levels.three.trim();
        const searchIndex = data.findIndex(one => one.level3 === level3);
        if (level3 != '') {
          if (searchIndex === -1) {
            const res = await newLevel3(
              clientNameSelected,
              lookUpTableNameSelected,
              reportNameSelected,
              tabs,
              levels.three,
              level3
            );
            clear();
            handleRefresh();
            if (res.status == 200) {
              toastSuccess('New level data added');
            } else {
              toastError(res.error);
            }
          } else {
            toastError('The data already exists');
          }
        } else {
          toastError('Please enter a valid input');
        }
      } else if (levels.two !== '') {
        if (Object.keys(clickData).length > 0) {
          const level2 = levels.two.trim();
          if (level2 != '') {
            const parent = clickData.parent;
            const res = await newLevel2(
              clientNameSelected,
              lookUpTableNameSelected,
              reportNameSelected,
              tabs,
              parent,
              level2
            );
            if (res.status == 200) {
              toastSuccess('New Level data added');

              clear();
              handleRefresh();
            } else {
              toastError(res.error);
            }
          } else {
            toastError('Please enter the valid input');
          }
        } else {
          toastError('Please select a table row to enter in this level');
        }
      } else if (levels.one !== '') {
        if (Object.keys(clickData).length > 0) {
          const level1 = levels.one.trim();
          if (level1 != '') {
            const parent = clickData.parent;
            const res = await newLevel1(
              clientNameSelected,
              lookUpTableNameSelected,
              reportNameSelected,
              tabs,
              parent,
              level1
            );
            if (res.status == 200) {
              toastSuccess('New Level added');

              clear();
              handleRefresh();
            } else {
              toastError(res.error);
            }
          } else {
            toastError('Please enter valid input');
          }
        } else {
          toastError('Please select a data to enter in this level');
        }
      } else {
        toastError('please enter a value in input fields');
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  //clears  input field and clickdata
  const clear = () => {
    setlevels({ one: '', two: '', three: '' });
    setClickData({});
    // e.preventDefault();
    setDisabled(false);
    setDisabledTwo(false);
    setDisabledThree(false);
  };

  //Get the row details when it is clicked
  const getRow = data => {
    setClickData(data);
  };

  return (
    <>
      <main className="userPageCont" style={{ boxShadow: 'none', padding: 0 }}>
        <section className="userPageTable p-3" style={{ boxShadow: 'none' }}>
          <form className="row g-3">
            <div className="col-md-4 col-lg-3">
              <input
                name="three"
                value={levels.three}
                onChange={handleState}
                placeholder="Enter to add Level 3"
                disabled={disabledThree}
                className="input"
              />
            </div>

            <div className="col-md-4 col-lg-3">
              <input
                label="Level 2"
                name="two"
                value={levels.two}
                onChange={handleState}
                placeholder="Enter to add Level 2"
                disabled={disabledTwo}
                className="input"
              />
            </div>

            <div className="col-md-4 col-lg-3">
              <input
                label="Level 1"
                name="one"
                value={levels.one}
                onChange={handleState}
                placeholder="Enter to add Level 1"
                disabled={disabled}
                className="input"
              />
            </div>
          </form>
          <br />
          <div>
            <br />
            {clickData.parent != null && (
              <span
                style={{ color: 'black', paddingLeft: '20rem', paddingTop: '4rem', zIndex: -1 }}
              >
                Selected: {clickData.parent}
              </span>
            )}
          </div>

          <div className="tabsBotmCont">
            <section className="d-flex justify-content-end">
              <div className="tabBtnCont">
                <MainButton onClick={clear}>Clear</MainButton>
                <MainButton
                  style={{ background: '#3fbc92' }}
                  onClick={handleSubmit}
                  disabled={disabledSubmit}
                >
                  Add
                </MainButton>
              </div>
            </section>

            <hr />
          </div>
          <div id="relative">
            <div style={{ float: 'right' }}>
              {' '}
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleRefresh}
              >
                {' '}
                Refresh Data{' '}
              </span>
            </div>
            <Table
              id="center"
              rowKey="id"
              cellBordered={false}
              rowHeight={50}
              bordered={false}
              showHeader={false}
              autoHeight={true}
              width={560}
              data={data}
              onRowClick={getRow}
              // rowClassName="testtherowname"
              loading={loading}
              tabIndex="0"
              style={tableStyle}
            >
              <Column align={'left'} width={25} colSpan={3}>
                <HeaderCell></HeaderCell>
                <EditCell dataKey="level3" onChange={handleChange} onClick={handleEditState} />
              </Column>

              <Column align={'left'} width={50} colSpan={2}>
                <HeaderCell></HeaderCell>
                <EditCell dataKey="level2" onChange={handleChange} onClick={handleEditState} />
              </Column>

              <Column align={'left'} width={440}>
                <HeaderCell></HeaderCell>
                <EditCell dataKey="level1" onChange={handleChange} onClick={handleEditState} />
              </Column>

              <Column calign={'left'} width={50}>
                <HeaderCell></HeaderCell>
                <ActionCell dataKey="id" onClick={handleEditState} />
              </Column>
            </Table>
          </div>
        </section>
      </main>
    </>
  );
};

export default ModelTable;
