import React from 'react'
import { BsArrowRight } from 'react-icons/bs';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import ItemTable from './itemTable'
import ItemTableDataGrid from './itemTableDataGrid';


const ItemPage = () => {
 
  const [searchParams, setSearchParams] = useSearchParams();
  
  const clientName =searchParams.get('clientName') +" ";
  const clientName1 =" "+searchParams.get('clientName') +" ";
  const LookUpTableName = " "+searchParams.get('accountType');
  
  // const clientName = location.state.itemCategory.AllClients[0].value+" ";
  // const LookUpTableName = " "+location.state.itemCategory.AllLookUpTble[0].value;

  
  return (
    <>
    <main className="userPageCont">
        <section className="userPageHead">
          <h4 style={{textTransform:'capitalize'}}>
            Item Categorization :
            {clientName1}<BsArrowRight/>{LookUpTableName}
          </h4>
        </section>
       <section className="userPageTable p-3">
      {/* <ItemTable clientName={clientName} LookUpTableName={LookUpTableName} /> */}
      <ItemTableDataGrid clientName={clientName} LookUpTableName={LookUpTableName} masterAccountValue=" "/>
       </section>
        
        {/* <section id= "inner" className="userPageTable p-3" > <XrefTabsForm handleTabChange={handleTabChange}/>  </section> */}
      </main>
    </>
  )
}

export default ItemPage
