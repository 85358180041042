import axios from '../api';
import { updateClient } from './endpoints';

export const editClient = async (client, id) => {
  const userName = JSON.parse(localStorage.getItem('user')).username;
  const body = { ...client, modifiedBy: userName };

  const { data } = await axios.put(updateClient(id), body);
  return data;
};
