import { _normStr } from './_normStr';

export const _getDataMoveId = (
  dataMoves,
  connectorKey,
  connectorVal,
  clientKey,
  clientVal
) => {
  for (const obj of dataMoves) {
    if (
      _normStr(obj[connectorKey]) === _normStr(connectorVal) &&
      _normStr(obj[clientKey]) === _normStr(clientVal)
    )
      return obj;
  }

  return -1;
};
