import React, { useEffect, useState } from 'react';
import { fetchAllUsers } from '../../api/users/getAllUsers';
import { toastError } from '../../utils/toast';

import createNewIcon from '../../assets/img/create_new.png';

import {
  MainButton,
  BootStrapTable,
} from '../../components';
import { reportActions } from '../../store/actions';
import notifyEmailconfig from './notifyMapperTableConfig';
import EditNotifyMapperModal from '../../components/modals/xRef/editNotifyMapper';
import CreateNotifyModal from '../../components/modals/xRef/createNotify';
import { getallEmailMapped } from '../../api/xReference/getALLEmailMaped';

const commonStyles = {
  margin: 0,
  padding: 0,
};

const XrefPage = ({ row, ...props }) => {

  const [tableData, setTableData] = useState({
    loading: true,
    error: true,
    data: [],
  });

  const [showCrtModl, setCrtModl] = useState(false);
  const [showEdtModl, setEdtModl] = useState(false);
  const [deletModl, setDeletModl] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [clients, setclients] = useState({
    load: false,
    data: [],
  });
  const [users, setUsers] = useState({
    load: false,
    data: [],
  });
  
  const [notifyClient, setnotifyClient] = useState({
    Client: '',
    PrimaryEmail: '',
    SecondaryEmail: '',
    TertiaryMail: '',
  });

  
//get the data from API
  useEffect(() => {
    getData();
  }, [showCrtModl,showEdtModl]);

  
  const getData = async _ => {
    try {
      const res = await getallEmailMapped();
      setTableData({ data: res.data, loading: false, error: false });
    } catch (e) {
      console.log(e);
    }
  };
// get the mails of  all the users
  useEffect(() => {
    (async _ => {
      const res = await fetchAllUsers(1, 1000);

      const data = res?.data;
      const mappedData = data.map((k, i) => {
        return { name: k.email, id: i };
      });
      setUsers(state => ({ ...state, data: mappedData, load: false }));

     
    })();
  }, []);

 
// get client data
  useEffect(() => {
    if (notifyClient.Client.length > 0) {
      try {
        console.log(users.data);

        setnotifyClient(state => ({
          ...state,
          PrimaryEmail: [],
          TertiaryMail: [],
          SecondaryEmail: [],
        }));


      } catch (e) {
        toastError(e.message);
      }
    }

    return () => {
    };
  }, [JSON.stringify(notifyClient.Client)]);

  
// edit
  const editActionsHandle = row => {
    setSelectedRow(row);
    setEdtModl(true);
  };

  // delete
  const deleteActionsHandle = row => {
    setSelectedRow(row);
    setDeletModl(true);
  };

  // table header options
  const tableConfig = notifyEmailconfig(editActionsHandle, deleteActionsHandle);

  
// ui
  return (
    <>
      <main className="userPageCont">
        <section className="userPageHead">
          <h4 className="userHeading">Notification Mapper</h4>
          <MainButton buttIcon={createNewIcon} onClick={() => setCrtModl(true)}>
            Create New
          </MainButton>

          {/** Modal */}
          <CreateNotifyModal show={showCrtModl}
          CloseReport ={
            showCrtModl}
            data  = {tableData.data}
          
          handleClose={() => setCrtModl(false)} />
          <EditNotifyMapperModal
            show={showEdtModl}
            handleClose={() => {setEdtModl(false)


            }  }
            row={selectedRow}
            CloseReport ={
              showEdtModl}
          />
        </section>

        <section className="userPageTable">
          <BootStrapTable
            enableSearch={{ placeHolder: 'Search By Client or email' }}
            tableColumns={tableConfig}
            tableData={tableData}
          />
        </section>
      </main>
    </>
  );
};

export default XrefPage;
