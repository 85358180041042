const fullscreen = id => {
  let elem = document.getElementById(id);

  try {
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch(err => {
        console.log(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
      return true;
    } else {
      document.exitFullscreen();
      return false;
    }
  } catch (error) {
    console.log(
      `Error attempting to enable full-screen mode: ${error.message} (${error.name})`
    );
  }
};

export default fullscreen;
