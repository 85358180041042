import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import 'powerbi-report-authoring';
import './styles.css';

import { _formatDate } from '../../helper/_date';

import { DailyAlert, Loader } from '../../components';

// helper
import fullScreen from '../../helper/fullscreen';
import { _findRoles } from '../../helper/_findRoles';

import { reportToken } from '../../api/powerBi/reportToken';
import { getLastUpdated } from '../../api/powerBi/getLastUpdated';
import { refeshDataset } from '../../api/powerBi/refeshDataset';
import { reportById } from '../../api/reports/reportById';

import { toastSuccess, toastInfo, toastError } from '../../utils/toast';

const PowerBiReports = () => {
  const [searchParams] = useSearchParams(),
    rprtId = Number(searchParams.get('reportId')),
    rprtName = searchParams.get('name');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lastRefresh, setlastRefresh] = useState('');
  const [ticker, setTicker] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [embedReport, setEmbedReport] = useState(false);
  const [isMaximize, setMaximize] = useState(false);
  const [hasClientRole] = useState(_findRoles('client'));

  const [reportConfig, setReportConfig] = useState({
    type: 'report',
    id: '',
    embedUrl: '',
    accessToken: '',
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: true,
        },
      },
      bars: {
        actionBar: {
          visible: true,
        },
      },
    },
  });

  const fetchLastUpdated = async _ => {
    try {
      const { data } = (await getLastUpdated(rprtId)) || {};
      setlastRefresh(data?.lastUpdated);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    (async _ => {
      try {
        setIsLoading(true);

        //  not set yet
        if (!!!rprtId) return;

        if (rprtId !== -1 && !!rprtId) {
          const {
            data: { reportID, token, embedURL },
          } = (await reportToken(rprtId)) || {};

          const {
            data: { statusNotification = '' },
          } = await reportById(rprtId);

          await fetchLastUpdated();

          setTicker(statusNotification);

          setReportConfig(state => ({
            ...state,
            id: reportID,
            embedUrl: embedURL,
            accessToken: token,
          }));

          setIsLoading(false);
          setErrorMessage('');
        } else {
          setIsLoading(false);
          setErrorMessage('No reports assigned');
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(
          'Error in getting the report please contact portal administrator.'
        );

        // when report crash
        setReportConfig(state => ({
          ...state,
          id: '',
          embedUrl: '',
          accessToken: '',
        }));
        console.log(error);
      }
    })();

    // clean up
    return () =>
      setReportConfig(state => ({
        ...state,
        id: '',
        embedUrl: '',
        accessToken: '',
      }));
  }, [rprtId]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered');
      },
    ],
    [
      'error',
      function (event) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  const onFullScreen = containerId => {
    fullScreen(containerId);
    setMaximize(isMax => !isMax);
  };

  const reloadEmbedReport = () => embedReport.reload();

  const handleRefreshData = () => {
    try {
      (async _ => {
        setIsButtonDisabled(true);
        const { data, error } = (await refeshDataset(rprtId)) || {};

        if (data && data === 'Success') {
          setIsButtonDisabled(false);
          reloadEmbedReport();

          // get date after dataSet refreshed
          await fetchLastUpdated();
          toastSuccess('Data set refreshed');
        } else if (error) {
          setIsButtonDisabled(false);
          toastInfo(
            'Dataset refresh is already initiated, and its in progress'
          );
        }
      })();
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleRefreshReport = () => {
    window.location.reload();
  };

  const handlePrintReport = () => {
    (async _ => {
      // Trigger the print dialog for your browser.
      try {
        await embedReport.print();
      } catch (errors) {
        console.log(errors);
      }
    })();
  };

  return (
    <main
      className={`reportCont ${isMaximize ? 'onFullScreen' : ''}`}
      id="reportContainer"
    >
      {isLoading || reportConfig.id.length === 0 ? (
        <div className="powerBi_Loader_Cont">
          {isLoading ? <Loader /> : <p>{errorMessage}</p>}
        </div>
      ) : (
        <>
          {ticker && <DailyAlert txt={ticker} />}
          <section className="reportHead">
            <div className="dates">
              <p>{`Last Daily Synced: ${_formatDate(
                lastRefresh,
                'MM/DD/YYYY, h:mm a'
              )}`}</p>

              <p>{`Last Dashboard Refreshed: ${_formatDate(
                lastRefresh,
                'MM/DD/YYYY, h:mm a'
              )}`}</p>
            </div>

            <h3>{rprtName}</h3>

            <div className="reportHeadIcons">
              {!hasClientRole &&
              (  <button
                  className="child"
                  onClick={handleRefreshData}
                  disabled={isButtonDisabled}
                  style={isButtonDisabled ? { color: '#bfbfbf' } : {}}
                >
                  Reload Dataset
                </button>  )        
              }

              <button className="child" onClick={handleRefreshReport}>
                Refresh Report
              </button>

              <button
                className="child"
                onClick={() => onFullScreen('reportContainer')}
              >
                {isMaximize ? 'Minimize' : 'Maximize'}
              </button>
              <button className="child" onClick={handlePrintReport}>
                Print Report
              </button>
            </div>
          </section>

          <section className="powerBiCont" id="powerBiCont">
            <hr />
            <PowerBIEmbed
              getEmbeddedComponent={embeddedReport =>
                setEmbedReport(embeddedReport)
              }
              embedConfig={reportConfig}
              eventHandlers={eventHandlersMap}
              cssClassName={`report-style-class ${isMaximize && 'report-style-FullHeight'
                }`}
            />
          </section>
        </>
      )}
    </main>
  );
};

export default PowerBiReports;
