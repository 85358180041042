import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

// api
import { getProcessDetails } from '../../api/udm/getProcessDetails';

// actions
import { udmActions } from '../../store/actions';

import { BootStrapTable, MainButton, TabsForm } from '../../components';
import udmTableConfig from './udmTableConfig';

// helpers
import { _sortDesc } from '../../helper/_sort';

export default function UdmPage() {
  const dispach = useDispatch();

  // use States
  const [processDetails, setProcessDetails] = useState({
    loading: false,
    data: [],
    error: false,
  });
  const [currentTab, setCurrentTab] = useState('');

  // fetch Data moves
  useEffect(() => {
    dispach(udmActions.getDataMoves());
  }, []);

  const tableConfig = udmTableConfig();
  const sizePerPageList = [
    {
      text: '10',
      value: 10,
    },
    {
      text: '20',
      value: 20,
    },
    {
      text: '30',
      value: 30,
    },
  ];

  const handleTabChange = tab => {
    (async _ => {
      try {
        setProcessDetails(s => ({ ...s, loading: true }));
        const res = await getProcessDetails(tab);
        setProcessDetails(s => ({
          ...s,
          loading: false,
          // data: _sortDesc(res?.data, tab !== 'history' ? 'datamoveid' : 'datamoverecordid'),
          data:res?.data

        }));
      } catch (e) {
        setProcessDetails(s => ({ ...s, loading: false, error: e?.message }));
      }
    })();
    setCurrentTab(tab);
  };

  const handleRefresh = () => handleTabChange(currentTab);

  return (
    <main className="userPageCont">
      <section className="userPageHead">
        <h4 className="userHeading">UDM Manual Run</h4>
        <MainButton>Customer Support</MainButton>
      </section>

      <section className="userPageTable p-3">
        <TabsForm handleTabChange={handleTabChange} />
        <div className="d-flex justify-content-end pe-3 pt-1">
          <MainButton onClick={handleRefresh} disabled={processDetails.loading}>
            Refresh Process Status
          </MainButton>
        </div>
        <BootStrapTable
          enableSearch={{ placeHolder: 'Search By dataMove Id and Name' }}
          tableColumns={tableConfig}
          tableData={processDetails}
          sizePerPageList={sizePerPageList}
          keyField="datamoverecordid"
        />
      </section>
    </main>
  );
}
