import './styles.css';
import { useState } from 'react';

import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

export default function FormInput({ label, img, passwordF, ...props }) {
  const [viewPassword, setviewPassword] = useState(false);

  const handleViewPassword = () => {
    setviewPassword(state => !state);
  };

  return (
    <section className="inpElementCont">
      {label && <label>{label}</label>}
      <div className="field">
        {img && <img alt="inpLogo" src={img} />}
        {passwordF ? (
          <>
            <input type={viewPassword ? 'text' : 'password'} {...props} />
            {viewPassword ? (
              <BsFillEyeFill onClick={handleViewPassword} />
            ) : (
              <BsFillEyeSlashFill onClick={handleViewPassword} />
            )}
          </>
        ) : (
          <input {...props} />
        )}
      </div>
    </section>
  );
}
