import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getItemCategorizationData } from '../../api/xReference/getItemCategorization';
import { getReportByLookup } from '../../api/xReference/getReportByLookup';
import { getLevel1ByReportType } from '../../api/xReference/getLevel1ByReportType';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { MainButton } from '../../components';
import { XrefMailContent } from '../../extra/emailTemplates/xrefNewInfoNotify';
import { toastError, toastInfo, toastSuccess } from '../../utils/toast';
import {
  updateDefaultModel,
  updateDefaultShortform,
  updateDefaultSOT,
} from '../../api/xReference/updateLevelsData';
import Box from '@mui/material/Box';
import { darken, lighten } from '@mui/material/styles';
import orderBy from 'lodash/orderBy';

import './xrefStyles.css';

//color theme for the not mapped data
const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const ItemTableDataGrid = () => {
  const [rows, setRow] = React.useState([]);
  const [repList, setRepList] = React.useState([]);
  const [repType, setRepType] = React.useState([]);
  const [repTypeBS, setRepTypeBS] = React.useState([]);
  const [repTypeCF, setRepTypeCF] = React.useState([]);
  const [value, setValue] = React.useState('model');
  const [cell, setCell] = React.useState('');
  const [loading, setLOading] = React.useState(true);
  const [selectedRow, setSelectedRow] = React.useState('');
  
  const [searchParams, setSearchParams] = useSearchParams();
  
  const clientParams = searchParams.get('clientName');
  const LookupParams = searchParams.get('accountType');
  const client = clientParams.trim();
  const str = String(LookupParams);
  const lookUp = str.replace(/\s/g, '');
  const accountValues = searchParams.get('masterAccountValue');
  const [accountValue, setAccountValue] = React.useState(accountValues);
  const [filt, setFilt] = React.useState([]);

  const [mytime, setMytime] = React.useState(0);

  React.useEffect(() => {
    getData();
    getReportList();
    filterData();
  }, [value]);

  React.useEffect(() => {
    // levelOneData();
    levelOneDataPnl();
    levelOneDataBS();
    levelOneDataCF();
  }, [value]);

  const levelOneDataPnl = async _ => {
    try {
      const res = await getLevel1ByReportType(client, lookUp, value, 'PNL');
      setRepType(res.data);
    } catch (e) {
      toastError(e);
    }
  };
  const levelOneDataBS = async _ => {
    try {
      const res = await getLevel1ByReportType(client, lookUp, value, 'BS');
      setRepTypeBS(res.data);
    } catch (e) {
      toastError(e);
    }
  };
  const levelOneDataCF = async _ => {
    try {
      const res = await getLevel1ByReportType(client, lookUp, value, 'CF');
      setRepTypeCF(res.data);
    } catch (e) {
      toastError(e);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', sortable: false, headerClassName: 'super-app-theme--header' },
    {
      field: 'masterAccount',
      headerName: 'Master Account',
      flex: 4,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'reportName',
      headerName: 'Report Name',
      flex: 4,
      headerClassName: 'super-app-theme--header',
    },

    {
      type: 'string',
      headerClassName: 'super-app-theme--header',
      field: 'mapping',
      headerName: 'Select Mapping',
      flex: 4,
      editable: false,
      sortable: false,
      filterable: false,
      renderHeader: params => (
        <>
          <label htmlFor="map"> Select Mapping : </label>
          <br />

          <select id="map" className="ReportList" onChange={handleChange}>
            {repList.map((i, key) => {
              return (
                <option value={i.name} key={key}>
                  {' '}
                  {i.reporttypeName.toUpperCase()}
                </option>
              );
            })}
          </select>
        </>
      ),
      renderCell: params => {
        if (params.row.reportName == 'Profit & Loss' && repType.length > 0) {
          return repList.length && value != '' ? (
            <>
              <select
                className="Level1"
                onChange={e => {
                  const changedvalue = e.target.value;
                  const rowDetails = params;
                  handleChangeLevel1(changedvalue, rowDetails);
                }}
                id={params.id}
                key={params.id}
              >
                <option value="" className="downselect">
                  {params.row[value] ? params.row[value] : 'Select Mapping'}
                </option>

                {repType.map((i, key) => {
                  if (i.level1 === params.row[value]) {
                    return null;
                  } else {
                    return (
                      <option value={i.level1} key={key} className="downselect">
                        {i.level1}
                      </option>
                    );
                  }
                })}
              </select>
            </>
          ) : null;
        } else if (params.row.reportName == 'Balance Sheet' && repTypeBS.length > 0) {
          return repList.length && value != '' ? (
            <>
              <select
                className="Level1"
                defaultValue={params.row[value] ? params.row[value] : 'Select Mapping'}
                onChange={e => {
                  const changedvalue = e.target.value;
                  const rowDetails = params;
                  handleChangeLevel1(changedvalue, rowDetails);
                }}
                id={params.id}
                key={params.id}
              >
                <option value="">{params.row[value] ? params.row[value] : 'Select Mapping'}</option>

                {repTypeBS.map((i, key) => {
                  if (i.level1 === params.row[value]) {
                    return null;
                  } else {
                    return (
                      <option value={i.level1} key={key} className="downselect">
                        {i.level1}
                      </option>
                    );
                  }
                })}
              </select>
            </>
          ) : null;
        }

        if (params.row.reportName == 'Cash Flow' && repTypeCF.length > 0) {
          return repList.length && value != '' ? (
            <>
              <select
                className="Level1"
                defaultValue={params.row[value] ? params.row[value] : 'Select Mapping'}
                // placeholder={params.row[value]}
                onChange={e => {
                  const changedvalue = e.target.value;
                  const rowDetails = params;
                  handleChangeLevel1(changedvalue, rowDetails);
                }}
                id={params.id}
                key={params.id}
              >
                <option value="">{params.row[value] ? params.row[value] : 'Select Mapping'}</option>

                {repTypeCF.map((i, key) => {
                  if (i.level1 === params.row[value]) {
                    return null;
                  } else {
                    return (
                      <option value={i.level1} key={key} className="downselect">
                        {i.level1}
                      </option>
                    );
                  }
                })}
              </select>
            </>
          ) : null;
        } else {
          return repList.length && value != '' ? (
            <>
              <select
                className="Level1"
                defaultValue={params.row[value] ? params.row[value] : 'Select Mapping'}
                // placeholder={params.row[value]}
                onChange={e => {
                  const changedvalue = e.target.value;
                  const rowDetails = params;
                  handleChangeLevel1(changedvalue, rowDetails);
                }}
                id={params.id}
                key={params.id}
              >
                <option value="" >
                  {params.row[value] ? params.row[value] : 'Select Mapping'}
                </option>
                <option value="" disabled>
                  no data avilable to map
                </option>
              </select>
            </>
          ) : null;
        }
      },
    },
  ];
  const handleChange = event => {
    setRow([]);
    setRepType([]);
    getData();
    const selected = event.target.value;
    const modified = selected.charAt(0).toLowerCase() + selected.slice(1);
    setValue(modified);
    setRepType([]);
  };

  const handleChangeLevel1 = (e, info) => {
    setCell(e);

    setSelectedRow(info.id);
  };

  const getData = async _ => {
    try {
      setLOading(true);
      const res = await getItemCategorizationData(client, lookUp);

      let sortedData = orderBy(res.data, value, 'desc');
      setLOading(false);

      setRow(sortedData);
    } catch (e) {
      toastError(e);
    }
  };

  const getReportList = async _ => {
    try {
      getData();
      const res = await getReportByLookup(lookUp);
      setRepList(res.data);
    } catch (e) {
      toastError(e);
    }
  };
  const refresh = () => {
    getData();
  };
  const handleRowClick = id => {};

  const updateSelection = async () => {
    const id = selectedRow;

    if (cell != '') {
      if (value === 'model') {
        const res = await updateDefaultModel(id, cell);

        if (res.status == 200) {
          toastSuccess('updated successfully');
          setCell('');
        } else toastError(res.error);
      } else if (value === 'shortForm') {
        const res = await updateDefaultShortform(id, cell);

        const mailTemplate = XrefMailContent(id, cell);
        if (res.status == 200) {
          toastSuccess('updated successfully');
          setCell('');
        } else toastError(res.error);
      } else if (value === 'sot') {
        const res = await updateDefaultSOT(id, cell);

        if (res.status == 200) {
          toastSuccess('updated successfully');
          setCell('');
        } else toastError(res.error);
      }
    } else {
      toastInfo('Please Select  Valid input');
      setCell('');
    }

    getData();
  };

  const filterData = () => {
    if (accountValue == 'undefined') {
      setFilt([]);
    } else {
      setFilt([
        {
          columnField: 'masterAccount',
          operatorValue: 'contains',
          value: accountValue,
        },
      ]);
    }
  };

  return (
    <>
      <div>
        <MainButton
          style={{ float: 'right', backgroundColor: '#3cbc94' }}
          onClick={updateSelection}
        >
          Save
        </MainButton>
        {/* Button to get not null */}
        {/* <MainButton style={{ float: 'left', backgroundColor:'##87a83' }} onClick={refresh}>
          Not Mapped
        </MainButton>
       */}
      </div>
      <br />
      <br />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: 'auto',
          '& .super-app-theme--header': {
            backgroundColor: 'black',
            color: 'white',
          },

          '& .super-app-theme--null': {
            bgcolor: theme => getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
              bgcolor: theme =>
                getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
            },
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
      >
        <br />
        {accountValue == 'undefined' ? (
          <DataGrid
            disableColumnSelector
            disableDensitySelector
            hideFooterSelectedRowCount
            rows={rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[50]}
            disableMultipleSelection={true}
            loading={loading}
            disableColumnMenu
            autoHeight
            getRowClassName={params => `super-app-theme--${params.row[value]}`}
            onRowClick={params => {
              const id = params;
              handleRowClick(id);
            }}
            search={true}
            width="auto"
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,

                quickFilterProps: { debounceMs: 250 },
              },
            }}
          />
        ) : (
          <DataGrid
            disableDensitySelector
            disableColumnSelector
            hideFooterSelectedRowCount
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            disableMultipleSelection={true}
            loading={loading}
            disableColumnMenu
            autoHeight
            getRowClassName={params => `super-app-theme--${params.row[value]}`}
            onRowClick={params => {
              const id = params;
              handleRowClick(id);
            }}
            //  onFilterModelChange={onFilterChange}
            search={true}
            width="auto"
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,

                quickFilterProps: { debounceMs: 250 },
              },
            }}
            initialState={{
              ...rows.initialState,

              filter: {
                filterModel: {
                  items: [
                    {
                      columnField: 'id',
                      operatorValue: 'equals',
                      value: accountValue,
                    },
                  ],
                },
              },
            }}
          />
        )}{' '}
      </Box>
    </>
  );
};

export default ItemTableDataGrid;
