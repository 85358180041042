// assets
import deleteIcon from '../../assets/img/delete_gray.png';
import editIcon from '../../assets/img/edit_gray.png';

// find in roles
import { _findRoles } from '../../helper/_findRoles';

const notifyEmailconfig = (editActionsHandle, deleteActionsHandle) => {
  const isSuperAdmin = _findRoles('admin') || _findRoles('super admin');

  // congifure table option here
  const tableColumns = [
    {
      dataField: 'action',
      text: 'Action',
      searchable: false,
      csvExport: false,
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span className="TbleActnIcns">
            <img src={editIcon} alt="eIcon" onClick={() => editActionsHandle(row)} />
          </span>
        );
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'email1',
      text: 'Primary Email',
      sort: true,
    },
    {
      dataField: 'email2',
      text: 'Secondary Email',
      sort: true,
    },
    {
      dataField: 'email3',
      text: 'Tertinary Email',
      sort: true,
    },
  ];

  return tableColumns;
};

export default notifyEmailconfig;
