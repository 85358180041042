import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReactJson from 'react-json-view';

import { OptionBox, ModalInputF, DatePicker } from '../../components';
import DropDown from '../modalComp/dropDown';
import TabBottomSection from './TabBottomSection';

import { _thisWeek, _thisMonth, _yearToDate, _formatDate } from '../../helper/_date';

// api
import { getConnectorDesktop } from '../../api/udm/getConnectorDesktop';
import { getClientsByConnectorDesktop } from '../../api/udm/getClientsByConnectorDesktop';
import { initiateDesktop } from '../../api/udm/initiateDesktop';

// utils
import { toastError, toastInfo } from '../../utils/toast';
import { _getDataMoveId } from '../../helper/_getDataMoveId';
import { makePayload } from '../../utils/udm_payload';

const Desktop = () => {
  const dataMoves = useSelector(state => state.dataMoves);

  // useStates
  const [Connertors, setConnertors] = useState({
    load: false,
    data: [],
  });
  const [Clients, setClients] = useState({
    load: false,
    data: [],
  });
  const [DesktopData, setDesktopData] = useState({
    connector: [],
    clientByConnector: [],
  });

  const defaultDesktop = {
    history_sync: '',
    notYetRun: '',
    datePeriod: '',
    payload: {},
  };
  const [desktopState, setDesktopState] = useState(defaultDesktop);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateRangeLabel, setDateRangeLabel] = useState('');
  const [dataMoveObj, setDataMoveObj] = useState(-1);
  const [displayMsg, setDisplayMsg] = useState('');
  const [startInitiate, setInitiate] = useState(false);

  const msgList = [];
  const payloadDateFormat = `yyyy-MM-DD[T]HH:mm:ss`;

  useEffect(() => {
    const getDateRange = range => {
      switch (range) {
        case 'This week':
          return _thisWeek(payloadDateFormat);
        case 'This month':
          return _thisMonth(payloadDateFormat);
        case 'Year to date':
          return _yearToDate(payloadDateFormat);
        default:
          return [];
      }
    };

    const dateRange = getDateRange(desktopState.datePeriod);
    if (dateRange.length === 2) setDateRangeLabel(dateRange);
    else setDateRangeLabel('');
  }, [desktopState.datePeriod, payloadDateFormat]);

  // load clients according to connector
  useEffect(() => {
    if (DesktopData.connector.length > 0) {
      const conn_Name = DesktopData.connector[0]?.value;
      (async _ => {
        try {
          setClients({ data: [], load: true });

          //  reset select clients
          setDesktopData(state => ({ ...state, clientByConnector: [] }));

          const res = await getClientsByConnectorDesktop(conn_Name);
          setClients({ data: res?.data?.clientName, load: false });
        } catch (e) {
          setClients(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  }, [DesktopData.connector]);

  // handle dataMove Id
  useEffect(() => {
    if (DesktopData.connector.length > 0 && DesktopData.clientByConnector.length > 0) {
      const seletd_conntor = DesktopData.connector[0]?.value,
        seletd_client = DesktopData.clientByConnector[0]?.value;

      if (dataMoves?.data && dataMoves?.data.length > 0) {
        const rowObj = _getDataMoveId(
          dataMoves?.data,
          'systemname',
          seletd_conntor,
          'companyshortname',
          seletd_client
        );

        rowObj !== -1 && setDataMoveObj(rowObj);
      }
    }
  }, [DesktopData.clientByConnector]);

  // history / sync effect
  useEffect(() => {
    const value = desktopState.history_sync;
    const updatedValue =
      value === 'History'
        ? { notYetRun: 1 }
        : value === 'Sync'
        ? { notYetRun: 0, datePeriod: '' }
        : {};
    setDesktopState(s => ({ ...s, ...updatedValue }));

    // set date range
    if (value === 'Sync') setDateRange([null, null]);
  }, [desktopState.history_sync]);

  const getSelectedDate = () => {
    const { datePeriod } = desktopState;
    return datePeriod !== 'Custom range'
      ? dateRangeLabel === ''
        ? null
        : dateRangeLabel
      : dateRange.join('') === ''
      ? null
      : !!dateRange[1]
      ? [_formatDate(dateRange[0], payloadDateFormat), _formatDate(dateRange[1], payloadDateFormat)]
      : null;
  };

  // handle payload
  useEffect(() => {
    const selectedDate = getSelectedDate();
    if (dataMoveObj !== -1) {
      if (desktopState.history_sync === 'Sync') {
        setDesktopState(s => ({
          ...s,
          payload: makePayload({
            ...dataMoveObj,
            notyetrun: desktopState.notYetRun,
            historyorsynch: desktopState.history_sync,
          }),
        }));
      } else if (desktopState.history_sync === 'History' && selectedDate) {
        setDesktopState(s => ({
          ...s,
          payload: makePayload(
            {
              ...dataMoveObj,
              notyetrun: desktopState.notYetRun,
              historyorsynch: desktopState.history_sync,
            },
            ...selectedDate
          ),
        }));
      } else {
        setDesktopState(s => ({
          ...s,
          payload: {},
        }));
      }
    }
  }, [dataMoveObj, dateRangeLabel, dateRange, desktopState.history_sync]);

  useEffect(() => {
    let msgTimeout;

    if (displayMsg) {
      // msgList.push({ msg: displayMsg, status: true });

      // msgList.map(async ({ msg, status }, i) => {
      //   if (!!status && !!msg) {
      //     msgList[i] = { ...msgList[i], status: false };
      //     await new Promise((resolve, reject) => {
      //       msgTimeout = setTimeout(() => {
      //         if (i > -1) {
      //           msgList.splice(i, 1);
      //         }
      //         resolve(setDisplayMsg(''));
      //       }, 3000);
      //     });
      //   }
      // });

      msgTimeout = setTimeout(() => setDisplayMsg(''), 10000);
    }

    return () => clearTimeout(msgTimeout);
  }, [displayMsg]);

  const handleStateChange = e => {
    const { name, value } = e.target;
    setDesktopState(state => ({ ...state, [name]: value }));
  };

  const handleDropDownState = (list, { name }) => {
    setDesktopData(state => ({ ...state, [name]: [list] }));
  };

  const getHistoryConnectors = _ => {
    if (Connertors.data.length === 0) {
      (async _ => {
        try {
          setConnertors(s => ({ ...s, load: true }));
          const res = await getConnectorDesktop();
          setConnertors({ data: res?.data?.connectors, load: false });
        } catch (e) {
          setConnertors(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  };

  const handleDesktopStart = () => {
    if (Object.keys(desktopState.payload).length > 0) {
      (async _ => {
        try {
          setInitiate(true);
          setDisplayMsg('Checking Process Status');
          const start_date = _formatDate(desktopState.payload.daterangestart, 'YYYY-MM-DD')
          const end_date= _formatDate(desktopState.payload.daterangeend,'YYYY-MM-DD')
          const res = await initiateDesktop( DesktopData.connector[0]?.value,desktopState.history_sync,start_date,end_date);
          if (res?.status === 204) toastInfo('No content return from server');
          setDisplayMsg(res?.data || 'Desktop has been started');
          setInitiate(false);
        } catch (e) {
          setDisplayMsg(`Failed, ${e?.message}`);

          setInitiate(false);
        }
      })();
    } else {
      toastError('Cannot initiate process with empty payload');
    }
  };

  const handleCancel = () => {
    setDisplayMsg('');
    setDesktopData({
      connector: [],
      clientByConnector: [],
    });
    setDateRange([null, null]);
    setDesktopState(defaultDesktop);
  };

  const commonStyles = {
    margin: 0,
    padding: 0,
  };

  const displayDateLabel = range => {
    if (Array.isArray(range)) {

      return `${_formatDate(range[0], 'DD-MM-YYYY')} - 
    ${_formatDate(range[1], 'DD-MM-YYYY')}`;

    }
    return '';
  };
  

  return (
    <>
   
      <form className="row g-3">
        <div className="col-md-4 col-lg-3">
          <DropDown
            title="Select Connector"
            isSelectAll={false}
            style={commonStyles}
            name="connector"
            value={DesktopData.connector}
            onChange={handleDropDownState}
            onMenuOpen={getHistoryConnectors}
            data={Connertors.data}
            isLoading={Connertors.load}
            maxMenuHeight={150}
            noOptionsMessage={() => 'No connertors'}
          />
        </div>

        <div className="col-md-4 col-lg-3">
          <DropDown
            title="Select Client"
            isSelectAll={false}
            style={commonStyles}
            name="clientByConnector"
            value={DesktopData.clientByConnector}
            onChange={handleDropDownState}
            data={Clients.data}
            isLoading={Clients.load}
            maxMenuHeight={150}
            noOptionsMessage={() =>
              DesktopData.connector.length === 0 ? 'No connector selected' : 'No clients'
            }
          />
        </div>
        <div className="col-md-4 col-lg-3">
          <OptionBox
            style={commonStyles}
            title="History / Sync"
            name="history_sync"
            value={desktopState.history_sync}
            onChange={handleStateChange}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="History">History</option>
            <option value="Sync">Sync</option>
          </OptionBox>
        </div>
        {/** 
        <div className="col-md-4 col-lg-3">
        <ModalInputF
          style={commonStyles}
          type="text"
          title="Not yet run"
          name="notYetRun"
          value={desktopState.notYetRun}
          onChange={handleStateChange}
          disabled
        />
      </div>
      */}

        <div className="col-md-4 col-lg-3 position-relative">
          <span className="dateRange_label">{displayDateLabel(dateRangeLabel)}</span>
          
          <OptionBox
            style={commonStyles}
            title="Select Date Period"
            name="datePeriod"
            value={desktopState.datePeriod}
            onChange={handleStateChange}
            disabled={desktopState.history_sync === 'Sync'}
          >
            <option value="" disabled>
              Choose
            </option>

            <option>This week</option>
            <option>This month</option>
            <option>Year to date</option>
            <option>Custom range</option>
          </OptionBox>
        </div>
        <div className="col-md-4 col-lg-3">
          <DatePicker
            style={commonStyles}
            title="Other Date Period"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={update => {
              setDateRange(update);
            }}
            isClearable={true}
            placeholderText="Please select a date range"
            disabled={desktopState.datePeriod !== 'Custom range'}
          />
        </div>

        <div className="form-group">
          <div className="payload_field">
            <label>Payload</label>
            <div className="json_format_view">
              <ReactJson
                src={desktopState.payload}
                enableClipboard={false}
                displayDataTypes={false}
              />
            </div>
          </div>
        </div>
      </form>
      <TabBottomSection
        btnTxt="Start Desktop"
        p1Txt="Desktop Process"
        p2Txt={displayMsg}
        handleStart={handleDesktopStart}
        isStarted={startInitiate}
        handleClear={handleCancel}
      />
    </>
  );
};

export default Desktop;
