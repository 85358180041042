import axios from '../api';
import { updateEmailMapping } from './endpoints';




export const editEmailMapper =async (Id, mail1, mail2, mail3) => {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.put(updateEmailMapping(Id, mail1, mail2, mail3, userName));
  return res;
};