import React, { useState } from 'react';
import { useLocation, useNavigate,useParams,useSearchParams } from 'react-router-dom';
import XrefTabsForm from '../../components/tabsForm/xRefT/XrefTabForms';
import './xrefStyles.css';
import { BsArrowRight } from 'react-icons/bs';
import { Image } from 'react-bootstrap';

const ModelPage = () => {
  //  const [currentTab, setCurrentTab] = useState('')
  const [nowTab, setNowTab] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  
  const location = useLocation();
  let navigate = useNavigate();
  // const clientName = location.state.viewData.AllClients[0].value + ' ';
  // const LookUpTableName = location.state.viewData.AllLookUpTble[0].value;
  // const reportTypeName = location.state.viewData.AllReportType[0].value;

  
  const params = useParams();
  const clientName =  searchParams.get("clientName");
  const clientName1 = " "+ clientName;
const LookUpTableName = searchParams.get("accountType");
const reportTypeName = searchParams.get("reportType");

  const handleTabChange = tab => {
    setNowTab(tab)
  };

  const getReportName = () => {
    if (reportTypeName !== 'na' && reportTypeName !== nowTab) {
      return reportTypeName.charAt(0).toUpperCase() + reportTypeName.slice(1);
    } else return '';
  };

  return (
    <>
      <main className="userPageCont">
        <section className="userPageHead">
          
          <h4 style={{ textTransform: 'capitalize' }}>
            View Formatter : 
            
             {clientName1}
            <BsArrowRight /> {LookUpTableName} <BsArrowRight /> {getReportName().toUpperCase()} {nowTab == "pnl" ? "P&L":nowTab.toUpperCase()}
          </h4>
        </section>
        <section className="userPageTable p-3">
          <XrefTabsForm handleTabChange={handleTabChange} reportTypeName={reportTypeName} clientName={clientName}
          LookUpTableName={LookUpTableName}
          />
        </section>
      </main>
    </>
  );
};

export default ModelPage;
