import * as type from '../types';

export const signIn = payload => ({
  type: type.SIGNIN_REQUESTED,
  payload: payload,
});

export const fetchAllusers = payload => ({
  type: type.FETCH_USERS_REQUESTED,
  payload: payload,
});
