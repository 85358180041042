import axios from '../api';
import { createEmailMapping } from './endpoints';




export const addNewEmailMapper =async ( ClientsName,  email1,  email2,  email3) => {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.post(createEmailMapping( ClientsName,  email1,  email2,  email3,  userName));
  return res;
};