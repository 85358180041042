import axios from '../api';
import { _initiateSync } from './endpoints';
import { getProcessStatus } from './getProcessStatus';

export const initiateSync = async id => {
  const status = await getProcessStatus(id);
  if (status?.data === 'Today this datamove is not initiated!!') {
    const res = await axios.get(_initiateSync(id));
    return res;
  }
  return status;
};
