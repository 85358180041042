import { useEffect } from 'react';

import './styles.css';
import { Tab, Tabs } from 'react-bootstrap';

// tabs
import SyncContent from './Sync';
import HistoryContent from './History';
import DesktopContent from './Desktop';

export default function TabsForm({ handleTabChange }) {
  useEffect(() => {
    // defaultActiveKey
    handleTabChange('sync');
  }, []);

  return (
    <Tabs
      defaultActiveKey="sync"
      onSelect={eventKey => handleTabChange(eventKey)}
    >
      <Tab eventKey="sync" title="SYNC">
        <SyncContent />
      </Tab>

      <Tab eventKey="history" title="HISTORY">
        <HistoryContent />
      </Tab>

      <Tab eventKey="desktop" title="DESKTOP">
        <DesktopContent />
      </Tab>
    </Tabs>
  );
}
