import './styles.css';

import { forwardRef, useImperativeHandle } from 'react';

import { _renderOptions } from '../../../helper/_renderOptions';
import { _sortAsc } from '../../../helper/_sort';

import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    minHeight: '1.5rem',
    background: '#Ffffff',
    border: '1.5px solid #ccc',
    borderRadius: '0.3rem',
    boxShadow: state.isFocused ? 0 : 0,
    fontSize: '0.9rem',

    '&:hover': {
      borderColor: '#ccc',
    },
  }),

  singleValue: (provided, state) => ({
    ...provided,
    padding: '0rem 0.5rem',
    fontSize: '0.87rem',
    lineHeight: 1,
    color: 'var(--gray4)',
  }),

  input: (provided, state) => ({
    ...provided,
    padding: '0rem 0.5rem',
    fontSize: '0.87rem',
    lineHeight: 1,
    color: 'var(--gray4)',
  }),

  placeholder: (provided, state) => ({
    ...provided,
    padding: '0rem 0.5rem',
    fontSize: '0.87rem',
    lineHeight: 1,
    color: 'var(--gray4)',
  }),

  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: '0.87rem',
  }),

  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: '0.87rem',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.87rem',
    color: state.isSelected ? 'var(--white)' : 'var(--gray4)',
    background: state.isSelected
      ? 'var(--primaryColor)'
      : state.isFocused
      ? 'var(--gray1)'
      : 'transparent',

    '&:active': {
      background: '#f4f4f4',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'var(--gray4)',
    '&:hover': {
      color: 'inherit',
    },
  }),
};

const DropDown = forwardRef((props, ref) => {
  const {
    title,
    req,
    style = {},
    data,
    isSelectAll = true,
    ...dDprops
  } = props;

  const optionsArr =
    data.length > 0 ? _sortAsc(_renderOptions(data), 'label') : [];

  const handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };

  const selectAll = () => dDprops.onChange(optionsArr, dDprops);

  const optionsLen = optionsArr.length;

  useImperativeHandle(ref, () => ({
    select_amliFi_team() {
      selectAll();
    },
  }));

  return (
    <section style={style} className="ModalInpFCont">
      <div>
        <label>{title}</label>
        {req && <span> *</span>}

        {isSelectAll && optionsLen > 0 && optionsLen !== dDprops.value.length && (
          <button
            className="drop_sel_allBtn"
            title="Select All"
            onClick={selectAll}
          >
            +
          </button>
        )}
      </div>

      <ReactSelect
        closeMenuOnSelect={true}
        components={animatedComponents}
        styles={customStyles}
        menuPlacement="auto"
        options={optionsArr}
        // onInputChange={handleInputChange}
        {...dDprops}
      />
    </section>
  );
});

export default DropDown;
