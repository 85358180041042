import axios from '../api';
import { auth } from './endpoints';

export const authenticate = async credentials => {
  const { userName, password } = credentials;

  const body = {
    userName: userName,
    password: password,
  };

  const res = await axios.post(auth, body);
  return res;
};
