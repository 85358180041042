import searchIcon from '../../assets/img/header_search.png';
import filterIcon from '../../assets/img/filter.png';
import exportIcon from '../../assets/img/export.png';
import deleteIcon from '../../assets/img/delete_gray.png';

export const TableSearchBar = props => {
  const searchProps = { ...props.searchProps },
    csvProps = { ...props.csvProps },
    searchPanelProps = props.searchBarP;

  let input;

  return (
    <section className="TbleSrchBr">
      <div className="SrchBrCont">
        <img src={searchIcon} alt="sIcon" />
        <input
          placeholder={searchPanelProps.placeHolder}
          onChange={() => searchProps.onSearch(input.value)}
          ref={n => (input = n)}
          type="text"
        />
      </div>

      <div className="SrchBrRghtButns">
        {/* <SrchBrButn text="Filter" icon={filterIcon} /> */}
        <SrchBrButn
          text="Export"
          icon={exportIcon}
          onClick={() => csvProps.onExport()}
        />
      </div>
    </section>
  );
};

const SrchBrButn = ({ text, icon, ...props }) => (
  <button {...props}>
    {icon && <img src={icon} alt="btnIcon" />}
    {text}
  </button>
);
