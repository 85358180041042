import { useState, useEffect } from 'react';
import createNewIcon from '../../assets/img/create_new.png';

import {
  MainButton,
  BootStrapTable,
  CreateRoleModal,
  DeleteRoleModal,
  EditRoleModal,
} from '../../components';

import { useSelector, useDispatch } from 'react-redux';

import { rolesActions } from '../../store/actions';

import roleTableConfig from './roleTableConfig';

export default function RoleManagementPage() {
  const dispach = useDispatch();
  const rolesData = useSelector(state => state.roles);

  const [showCrtRoleModl, setCrtRoleModl] = useState(false);
  const [showEdtRolModl, setEdtRolModl] = useState(false);
  const [deletRolModl, setDeletRolModl] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // getAllRoles
  useEffect(() => {
    dispach(rolesActions.fetchAllroles({ pageNo: '1', pageSize: '1000' }));
  }, []);

  const editActionsHandle = row => {
    setSelectedRow(row);
    setEdtRolModl(true);
  };

  const deleteActionsHandle = row => {
    setSelectedRow(row);
    setDeletRolModl(true);
  };

  // table header options
  const tableConfig = roleTableConfig(editActionsHandle, deleteActionsHandle);

  return (
    <main className="userPageCont">
      <section className="userPageHead">
        <h4 className="userHeading">Role Management</h4>
        <MainButton
          buttIcon={createNewIcon}
          onClick={() => setCrtRoleModl(true)}
        >
          Create New Role
        </MainButton>

        {/** Modal */}
        <CreateRoleModal
          show={showCrtRoleModl}
          handleClose={() => setCrtRoleModl(false)}
        />
        <EditRoleModal
          show={showEdtRolModl}
          handleClose={() => setEdtRolModl(false)}
          row={selectedRow}
        />
        <DeleteRoleModal
          show={deletRolModl}
          handleClose={() => setDeletRolModl(false)}
          row={selectedRow}
          delMsg={`Are you sure you want to delete the role ${selectedRow?.name}`}
        />
      </section>

      <section className="userPageTable">
        <BootStrapTable
          enableSearch={{ placeHolder: 'Search By Role, Created By, etc' }}
          tableColumns={tableConfig}
          tableData={rolesData}
        />
      </section>
    </main>
  );
}
