import axios from '../api';
import { crteClient } from './endpoints';

export const createClient = async client => {
  const userName = JSON.parse(localStorage.getItem('user')).username;
  const body = { ...client, createdBy: userName };

  const { data } = await axios.post(crteClient, body);
  return data;
};
