import PT from 'prop-types';

import NavDropDown from './NavDropDown';

import { useNavigate } from 'react-router-dom';

import { BsArrowDownShort } from 'react-icons/bs';

const NavItem = ({ text, link = '#', Icon, pathname, toggle }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    !!toggle ? toggle?.setOpen(toggle?.name) : navigate(link);
  };

  return (
    <li className="navItm" onClick={handleClick}>
      {!!Icon && <Icon className="li_icon" />}
      <span className="itm_txt">{text}</span>
      {!!toggle && (
        <>
          <BsArrowDownShort />
          {toggle?.open && (
            <NavDropDown {...toggle?.getRoutes} pathname={pathname} />
          )}
        </>
      )}
    </li>
  );
};

NavItem.propTypes = {
  text: PT.string.isRequired,
  link: PT.string,
  Icon: PT.func.isRequired,
  pathname: PT.string.isRequired,
  toggle: PT.exact({
    name: PT.string.isRequired,
    open: PT.bool.isRequired,
    setOpen: PT.func.isRequired,
    getRoutes: PT.exact({
      routes: PT.array.isRequired,
      isLoading: PT.bool,
      noRouteMsg: PT.string,
      toolTip: PT.bool,
    }).isRequired,
  }),
};

export default NavItem;
