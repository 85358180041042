import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DropDown from '../modalComp/dropDown';
import TabBottomSection from './TabBottomSection';

// api
import { getConnectors } from '../../api/udm/getConnectors';
import { getClinetByConnector } from '../../api/udm/getClientsByConnector';
import { initiateSync } from '../../api/udm/getInitiateSync';

// utils
import { toastError } from '../../utils/toast';
import { _getDataMoveId } from '../../helper/_getDataMoveId';

const commonStyles = {
  margin: 0,
  padding: 0,
};

const Sync = () => {
  const dataMoves = useSelector(state => state.dataMoves);

  // useStates
  const [Connertors, setConnertors] = useState({
    load: false,
    data: [],
  });
  const [Clients, setClients] = useState({
    load: false,
    data: [],
  });
  const [syncData, setSyncData] = useState({
    connector: [],
    clientByConnector: [],
  });
  const [SyncMsg, setSyncMsg] = useState('');
  const [startInitiate, setInitiate] = useState(false);

  // load clients according to connector
  useEffect(() => {
    if (syncData.connector.length > 0) {
      const conn_Name = syncData.connector[0]?.value;
      (async _ => {
        try {
          setClients({ data: [], load: true });

          //  reset select clients
          setSyncData(state => ({ ...state, clientByConnector: [] }));

          const res = await getClinetByConnector(conn_Name);
          setClients({ data: res?.data?.clientName, load: false });
        } catch (e) {
          setClients(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  }, [syncData.connector]);

  useEffect(() => {
    let syncMsgTimeOut;
    if (!!SyncMsg) {
      syncMsgTimeOut = setTimeout(() => setSyncMsg(''), 10000);
    }
    return () => {
      clearTimeout(syncMsgTimeOut);
    };
  }, [SyncMsg]);

  const handleDropDownState = (list, { name }) => {
    setSyncData(state => ({ ...state, [name]: [list] }));
  };

  const getSyncConnectors = _ => {
    if (Connertors.data.length === 0) {
      (async _ => {
        try {
          setConnertors(s => ({ ...s, load: true }));
          const res = await getConnectors();
          setConnertors({ data: res?.data?.connectors, load: false });
        } catch (e) {
          setConnertors(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  };

  const handleSyncStart = () => {
    if (syncData.connector.length > 0 && syncData.clientByConnector.length > 0) {
      const selectedConnector = syncData.connector[0]?.value,
        selectedClient = syncData.clientByConnector[0]?.value;

      if (dataMoves?.data && dataMoves?.data.length > 0) {
        const rowObj = _getDataMoveId(
          dataMoves?.data,
          'systemname',
          selectedConnector,
          'companyshortname',
          selectedClient
        );
        if (rowObj !== -1) {
          (async _ => {
            try {
              setInitiate(true);
              setSyncMsg('Checking Process Status');
              const res = await initiateSync(rowObj?.datamoveid);
              setSyncMsg(res?.data || 'Sync has been started');
              setInitiate(false)
            } catch (e) {
              setSyncMsg(`Sync Failed`);
              toastError(e.message);
              setInitiate(false)
            }
          })();
        } else {
          toastError('Invalid DataMoveId');
        }
      }
    } else {
      toastError('Please select connectors & clients');
    }
  };

  const handleCancel = () => {
    setSyncMsg('');
    setSyncData({
      connector: [],
      clientByConnector: [],
    });
  };

  return (
    <>
      <form className="row g-3">
        <div className="col-md-4 col-lg-3">
          <DropDown
            title="Select Connector"
            isSelectAll={false}
            style={commonStyles}
            name="connector"
            value={syncData.connector}
            onChange={handleDropDownState}
            onMenuOpen={getSyncConnectors}
            data={Connertors.data}
            isLoading={Connertors.load}
            noOptionsMessage={() => 'No connertors'}
            IndicatorSeparator={false}
            maxMenuHeight={150}
          />
        </div>
        <div className="col-md-4 col-lg-3">
          <DropDown
            title="Select Client"
            isSelectAll={false}
            style={commonStyles}
            name="clientByConnector"
            value={syncData.clientByConnector}
            onChange={handleDropDownState}
            data={Clients.data}
            isLoading={Clients.load}
            maxMenuHeight={150}
            noOptionsMessage={() =>
              syncData.connector.length === 0 ? 'No connector selected' : 'No clients'
            }
          />
        </div>
      </form>
      <TabBottomSection
        btnTxt="Start Sync"
        p1Txt="Sync Process"
        p2Txt={SyncMsg}
        handleStart={handleSyncStart}
        isStarted={startInitiate}
        handleClear={handleCancel}
      />
    </>
  );
};

export default Sync;
