import axios from '../api';
import { defaultModel, defaultShortForm, defaultSOT, updateDataLevel1, updateDataLevel2, updateDataLevel3 } from './endpoints';




export const updateLevel1 =async (id,Level1) => {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.put(updateDataLevel1(id,Level1,userName));
  return res;
};
export const updateLevel2 =async (id,Level2) => {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.put(updateDataLevel2(id,Level2,userName));
  return res;
};
export const updateLevel3 =async (id,Level3) => {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.put(updateDataLevel3(id,Level3,userName));
  return res;
};

export const updateDefaultModel = async (Id,model )=> {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.put(defaultModel(Id,model,userName));
  return res;
};
export const updateDefaultShortform = async (Id,shortform )=> {
  const userName = JSON.parse(localStorage.getItem('user')).username;
  const res = await axios.put(defaultShortForm(Id,shortform,userName));
  return res;
};
export const updateDefaultSOT = async (Id,sot )=> {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.put(defaultSOT(Id,sot,userName));
  return res;
};