import './styles.css';

const HelpPage = () => {
  return (
    <main className="info_page_cont">
      <header className="info_head">
        <h1>Help Center</h1>
      </header>

      <div className="info_contnt_cont">
        <p>
          Please send your queries to &nbsp;
          <strong>
            <a href="mailto:Support.portal@amplificap.com">
              Support.portal@amplificap.com
            </a>
          </strong>
          <br />
          Our support person will contact you shortly.
          <br />
          Thank You!
        </p>
      </div>
    </main>
  );
};

export default HelpPage;
