import 'bootstrap/dist/css/bootstrap.css';
import './main.css';

import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Route, Routes, Navigate,useLocation, NavLink } from 'react-router-dom';

// find role
import { _findRoles } from '../helper/_findRoles';

// layouts
import { AuthLayout, MainLayout, InfoLayout } from '../layouts';

// pages
import {
  NoPageFound,
  SignIn,
  ForgetPassword,
  VerifyOtp,
  ResetPassword,
  ChangePassword,
  PowerBiReports,
  UserManagement,
  MyView,
  UdmPage,
  RoleManagement,
  ClientManagement,
  ReportManagement,
  
  HelpPage,
  TermsPage,
  PrivacyPolicyPage,
} from '../pages';


import ViewFormatter from '../pages/xReferencePages/viewFormatter';
import ItemCategorzation from '../pages/xReferencePages/itemCategorzation';
import ModelTable from '../pages/xReferencePages/modelTable';
import XrefTabsForm from '../components/tabsForm/xRefT/XrefTabForms';

import ModelPage from '../pages/xReferencePages/modelPage';
import ItemPage from '../pages/xReferencePages/itemPage';
import { QueryClientProvider,QueryClient } from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import ItemTableDataGrid from '../pages/xReferencePages/itemTableDataGrid';
import { getXrefClients } from '../api/xReference/getXrefClients';
import XrefPage from '../pages/xReferencePages/XrefManagement';
import MasterLog from '../pages/xReferencePages/masterlog';


const queryClient = new QueryClient();

export default function App() {
  const user = useSelector(state => state.loginReducer.data);
const location = useLocation();


  const isLogin = user?.token;

  const [isAdmin, setAdmin] = useState(false);
  const [isXref, setXref] = useState(false);
  const [currentLocation,setcurrentLocation]=useState("/");
  const [pagePath,setPagePath] = useState(currentLocation)
  const [mytime, setMytime] = useState(0);

  useEffect(() => {
    if (!!user) {
      setAdmin(_findRoles('admin') || _findRoles('super admin'));
      setXref(_findRoles('xrefuser'));
    }
  }, [user]);

  useEffect(()=>{
    if(!isLogin)
    {
      if(location.pathname == "/reports" || location.pathname == "/auth/signin" || location.pathname == "/auth/changepassword")
    {  setcurrentLocation("/");  }
      else  
      {setcurrentLocation(location.pathname)}
    }
  

  },[isLogin])

useEffect(()=>{
 if((currentLocation !== "/reports" )||currentLocation !== "/reports" )
 {
  setPagePath(currentLocation)
 }
 else
 setPagePath("/info")
},[user])


  return (
    <>
   
    
      <Routes>
        {/** Routes associated to MainLayout*/}
        <Route
          path="/"
          element={isLogin ? <MainLayout state={ currentLocation} /> : <Navigate replace to="auth" />}
        >
          {/** child routes */}
          {pagePath == "/" ? <Route index element={<Navigate replace to={`reports`} />} />:<Route index element={<Navigate replace to={pagePath} />} /> }
          <Route index element={<Navigate replace to={`reports`} />} />
          <Route path="reports" element={<PowerBiReports />} />
          <Route path="myview" element={<MyView />} />
         


          {/* admin protected routes */}
          <Route
            path="admin/usermanagement"
            element={isAdmin && <UserManagement />}
          />
          <Route
            path="admin/rolemanagement"
            element={isAdmin && <RoleManagement />}
          />
          <Route
            path="admin/clientmanagement"
            element={isAdmin && <ClientManagement />}
          />
          <Route
            path="admin/reportmanagement"
            element={isAdmin && <ReportManagement />}
          />
          <Route path="udm" element={isAdmin && <UdmPage />} />
          <Route path="/ViewFormatter-Selection" element={<ViewFormatter /> }/>
          <Route path="/item-selection" element={<ItemCategorzation />} />
          <Route path="/viewFormatter" element={  <ModelPage />} />
          <Route path="/item-categorization" element={<ItemPage />} />
          <Route path="/item-categorization/notification-mapper" element={<XrefPage />} />
          <Route path="/item-categorization/master-log" element={<MasterLog />} />
         
        
        </Route>

        {/** Routes associated to AuthLayout*/}
        <Route
          path="/auth"
          element={!isLogin ? <AuthLayout state={ currentLocation}/> : <Navigate  to="/" />}
        >
          {/** child routes */}
          <Route index element={<Navigate replace to="signin"  />} />
          <Route path="signin"  element={<SignIn state={ currentLocation}/>} />
          {/* <NavLink to="/signin" state={{ prev: location.pathname }}>
        Login
      </NavLink> */}
          <Route path="forgetpassword" element={<ForgetPassword />} />
          <Route path="verifyotp" element={<VerifyOtp />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="changepassword" element={<ChangePassword />} />
        </Route>

        {/** common routes*/}
        <Route path="/info" element={<InfoLayout />}>
          <Route index element={<Navigate replace to="help" />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="privacy_policy" element={<PrivacyPolicyPage />} />
          <Route path="terms_of_use" element={<TermsPage />} />
          
        
         
        </Route>
        <Route path="/serverError" element={<p>Server Error</p>} />
                                            
        {/** 404 - "*"  means if none of the routes match*/}
        <Route path="*" element={<NoPageFound />} />
 
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </>
  );
}

