import { MainButton } from '../../components';

const TabBottomSection = ({
  btnTxt,
  p1Txt,
  p2Txt,
  handleStart,
  isStarted,
  handleClear,
}) => (
  <main className="tabsBotmCont">
    <section className="d-flex justify-content-end">
      <div className="tabBtnCont">
        <MainButton onClick={handleClear}>Clear</MainButton>
        <MainButton style={{ background: '#3fbc92' }} onClick={handleStart} disabled={isStarted}>
          {btnTxt}
        </MainButton>
      </div>
    </section>

    <div className="tabsHist">
      <p>{p1Txt}</p>
      <p>{p2Txt}</p>
    </div>
    <hr />
  </main>
);

export default TabBottomSection;
