import { MainButton, RsuiteTable } from '../../components';
import './styles.css';

export default function DashboardPage() {
  return (
    <main className="userPageCont">
      <section className="userPageHead">
        <h4 className="userHeading">My View</h4>
        <MainButton onClick={() => alert('My view')}>
          Customer Support
        </MainButton>

        {/** Modal */}
      </section>

      {/** custom table

    - For column toggle pass "enableColumnToggle" as a prop in BootStrapTable 
    */}
      <section className="userPageTable">
        <RsuiteTable />
      </section>
    </main>
  );
}
