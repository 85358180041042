import NoPageFound from './_404';
import SignIn from './signIn';
import ForgetPassword from './forgetPassword';
import VerifyOtp from './verifyOtp';
import ResetPassword from './resetPassword';
import ChangePassword from './changePassword';
import PowerBiReports from './powerbiReports';
import UserManagement from './userManagement';
import MyView from './myView';
import UdmPage from './udm';
import RoleManagement from './roleManagement';
import ClientManagement from './clientManagement';
import ReportManagement from './reportManagement';
import HelpPage from './help';
import TermsPage from './terms';
import PrivacyPolicyPage from './privacyPolicy';
import ItemCategorization from './xReferencePages/itemCategorzation';
import ItemPage from './xReferencePages/itemPage';
import ItemTable from './xReferencePages/itemTable';
import ItemTableDataGrid from './xReferencePages/itemTableDataGrid';
import MasterLog from './xReferencePages/masterlog';
import XrefPage from './xReferencePages/XrefManagement';
import ViewFormatter from './xReferencePages/viewFormatter';
import ModelPage from './xReferencePages/modelPage';
import ModelTable from './xReferencePages/modelTable';

export {
  NoPageFound,
  SignIn,
  ForgetPassword,
  VerifyOtp,
  ResetPassword,
  ChangePassword,
  PowerBiReports,
  UserManagement,
  MyView,
  UdmPage,
  RoleManagement,
  ClientManagement,
  ReportManagement,
  HelpPage,
  TermsPage,
  PrivacyPolicyPage,
  ItemCategorization,
  ItemPage,
  ItemTable,
  ModelPage,
  ModelTable,
  ViewFormatter,
  MasterLog,
  XrefPage,
  ItemTableDataGrid,
};
