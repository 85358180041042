import { useState } from 'react';
import { useSelector } from 'react-redux';

// icons
import ampLogo from '../../../assets/img/amp_login_logo.png';
import searchIcon from '../../../assets/img/header_search.png';
import user from '../../../assets/img/navartar.jpg';

import './styles.css';

import { FaRegBell } from 'react-icons/fa';

export default function AmpHeader() {
  const [search, setSearch] = useState('');
  const userName = useSelector(state => state.loginReducer.data?.firstName);

  const handleDropDown = e => {
    const { value } = e.target;
    if (value.trim() === 'Log out') {
      localStorage.clear();
      window.location.reload();
    }
  };

  const displayUsername = name => {
    return name ? (name.length > 10 ? `${name.slice(0, 6)}...` : name) : '';
  };

  return (
    <main className="amplifyHeader">
      <section className="headerLeftSection">
        <img className="headerLogo" src={ampLogo} alt="Logo" />
      </section>

      <section className="headerRightSection">
        <div className="headerSeachBar">
          {/* <img className="searhBarIcon" src={searchIcon} alt="sIcon" />
          <input
            type="text"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search"
          /> */}
        </div>

        <div className="d-flex justify-content-between align-items-center rightIcons">
          <span className="headerBellIcon">{/* <FaRegBell /> */}</span>
          <span className="d-flex align-items-center userAvatar">
            <img src={user} alt="userAvatar" />
            <label>{displayUsername(userName)}</label>
          </span>
          <select className="headerDropdwon" onChange={handleDropDown}>
            {/* first option as empty */}
            <option style={{ display: 'none' }}></option>
            {/* <option>Settings</option> */}
            <option>&emsp;&emsp;Log out&emsp;&emsp;</option>
          </select>
        </div>
      </section>
    </main>
  );
}
