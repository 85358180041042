import * as type from '../../types';

const initialState = { data: [], loading: false, error: false };

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case type.FETCH_REPORTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.FETCH_REPORTS_SUCCESS:
      return {
        data: action.response,
        loading: false,
        error: false,
      };
    case type.FETCH_REPORTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
