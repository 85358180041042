export const serviceWorkerDev = () => {
  // URL of service worker in public folder
  const url = `${process.env.PUBLIC_URL}/serviceWorker.js`;

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(url)
      .then(function (reg) {
        console.debug('service worker registered.');
      })
      .catch(function (err) {
        console.debug("service worker didn't registered. This happened:", err);
      });
  }
};
