import './styles.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { userActions } from '../../../../store/actions';

// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';
import { OptionBox } from '../../../common/optionBox';

// email template
import { changePasswordEmail } from '../../../../extra/emailTemplates/changePassword';

import { userRegister } from '../../../../api/users/register';
import { SendEmail } from '../../../../api/users/sendEmail';

// validate
import { validateFields, validateLength } from '../../../../helper/validate';

// toast
import { toastSuccess, toastError } from '../../../../utils/toast';

const CreateUserModal = props => {
  const dispach = useDispatch();

  // states
  const [isUserCreated, setUserCreated] = useState(false);
  const [user, setUser] = useState({
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    userStatus: '',
    mobileNumber: '',
  });

  const handleStateChange = e => {
    const { name, value } = e.target;

    if (validateLength(user[name], value, 60)) {
      setUser(state => ({ ...state, [name]: value }));
    }
  };

  const emptyFields = () => {
    setUser({
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      userStatus: '',
      mobileNumber: '',
    });
  };

  const handleCreateUser = () => {
    const validate = validateFields(user);

    if (!validate) {
      (async _ => {
        try {
          const { temporaryPassword } = await userRegister(user);

          // fetch users
          dispach(userActions.fetchAllusers({ pageNo: '1', pageSize: '1000' }));

          // change password url
          const mailUrl = `${process.env.REACT_APP_FRONT_END}/auth/changepassword?UserName=${user.userName}&tempPass=${temporaryPassword}`;

          const mailTemplate = changePasswordEmail(
            user.firstName,
            user.email,
            mailUrl,
            temporaryPassword
          );

          setTimeout(async () => {
            const mailResp = await SendEmail(
              user.email,
              mailTemplate,
              'Welcome to AmpliFi Customer Portal'
            );

            if (mailResp === 'OTP Sent successfully!!') {
              toastSuccess(`Email sent successfully`);
            }

            // empty fields
            emptyFields();
          }, 2000);

          // show success message
          setUserCreated(true);
        } catch (e) {
          if (e.message.indexOf('400') !== -1) {
            toastError(`Username or email already exists`);
          } else {
            toastError(`${e.message}`);
          }
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const handleCloseUser = () => {
    const { handleClose } = props;

    setTimeout(() => setUserCreated(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!isUserCreated ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Clear" className="grayButn" onClick={emptyFields} />
          <ModalButt
            text="Create"
            className="greenButn"
            onClick={handleCreateUser}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseUser}
        />
      )}
    </>
  );

  const UserCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Created</p>
      <span>You have successfully created the new user.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      fullHeight={!isUserCreated}
      headerTxt="Create New User"
      FooterContnt={FooterComp}
    >
      {/** user create form */}
      {!isUserCreated ? (
        <form>
          <ModalInpF
            type="text"
            req
            title="Username(email)"
            name="userName"
            value={user.userName}
            onChange={handleStateChange}
          />
          <ModalInpF
            type="text"
            req
            title="First Name"
            name="firstName"
            value={user.firstName}
            onChange={handleStateChange}
          />
          <ModalInpF
            type="text"
            req
            title="Last Name"
            name="lastName"
            value={user.lastName}
            onChange={handleStateChange}
          />
          <ModalInpF
            type="email"
            req
            title="Email"
            name="email"
            value={user.email}
            onChange={handleStateChange}
          />

          <OptionBox
            title="Status"
            req
            name="userStatus"
            value={user.userStatus}
            onChange={handleStateChange}
          >
            <option></option>
            <option>Active</option>
            <option>Inactive</option>
          </OptionBox>

          <ModalInpF
            field="NumberFormatField"
            req
            title="Mobile"
            allowNegative={false}
            format="+1 (###)-###-####"
            name="mobileNumber"
            value={user.mobileNumber}
            onChange={handleStateChange}
          />
        </form>
      ) : (
        <UserCreatedComp />
      )}
    </RightModal>
  );
};

export default CreateUserModal;
