import FormatField from 'react-number-format';
import './styles.css';

const ModalInputF = ({ title, req, hasError, field, style = {}, ...props }) => {
  const getDesiredField = fieldType => {
    switch (fieldType) {
      case 'NumberFormatField':
        return <FormatField displayType="input" type="tel" {...props} />;
      case 'TextArea':
        return <textarea {...props} />;
      default:
        return <input {...props} />;
    }
  };

  return (
    <div style={style} className="ModalInpFCont">
      <label>
        {title}
        {req && <span> *</span>}
      </label>
      {getDesiredField(field)}
      {hasError && <span className="mdlInpErr">{hasError}</span>}
    </div>
  );
};

export default ModalInputF;
