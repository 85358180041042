import { call, put } from 'redux-saga/effects';
import { fetchAllUsers } from '../../../api/users/getAllUsers';
import * as type from '../../types';

export default function* usersSaga(action) {
  const { pageNo, pageSize } = action.payload || {};
  try {
    const res = yield call(_ => fetchAllUsers(pageNo, pageSize));
    yield put({ type: type.FETCH_USERS_SUCCESS, response: res.data });
  } catch (e) {
    yield put({ type: type.FETCH_USERS_FAILED, error: e.response });
  }
}
