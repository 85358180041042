import './styles.css';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { userActions } from '../../../../store/actions';

// modal
import CenterModal from '../../base/centerModal';

import { deleteUser } from '../../../../api/users/deleteUser';

// assets
import deleteIcon from '../../../../assets/img/delete_popup.png';
import successIcon from '../../../../assets/img/success.png';

import { toastError } from '../../../../utils/toast';

// components
import ModalButt from '../../../modalComp/modalButtons';

const DeleteUserModal = ({
  row,
  delMsg = 'You want to delete the selected user from the list',
  ...props
}) => {
  const { handleClose } = props;

  const dispach = useDispatch();

  // states
  const [isUserDeleted, setUserDeleted] = useState(false);

  const handleDeleteUser = async () => {
    (async _ => {
      try {
        await deleteUser(row.id);

        setUserDeleted(true);

        // fetch users after delete
        dispach(userActions.fetchAllusers({ pageNo: '1', pageSize: '1000' }));
      } catch (e) {
        toastError(e.message);
      }
    })();
  };

  const handleCloseConfrm = () => {
    setTimeout(() => setUserDeleted(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!isUserDeleted ? (
        <div className="d-flex justify-content-between">
          <ModalButt
            text="Close"
            className="grayButn"
            onClick={() => handleClose()}
          />
          <ModalButt
            text="Delete"
            className="redButn"
            onClick={handleDeleteUser}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseConfrm}
        />
      )}
    </>
  );

  const DeleteContent = ({ message, icon }) => (
    <section className="UserDeleteComp">
      <img src={icon} alt="sIcon" />
      <p>Delete</p>
      <span>{message}</span>
    </section>
  );

  return (
    <CenterModal {...props} backdrop="static" FooterContnt={FooterComp}>
      {/** user delete confirmation */}
      {!isUserDeleted ? (
        <DeleteContent message={delMsg} icon={deleteIcon} />
      ) : (
        <DeleteContent
          message="You have successfully deleted the user"
          icon={successIcon}
        />
      )}
    </CenterModal>
  );
};

export default DeleteUserModal;
