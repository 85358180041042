import axios from '../api';
import { forgetPass } from './endpoints';

export const forgetPassword = async emailID => {
  const body = {};
  
  try {
    const response = await axios.post(forgetPass(emailID), body);
    return response;
  } catch (error) {
    return { error: true, message: error.message };
  }
};
