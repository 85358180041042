export const makePayload = (dataMoveObj, stDate = null, endDate = null) => {
  const {
    datamoveid,
    notyetrun,
    companyid,
    companyshortname,
    createSchedulerJob,
    datamovename,
    historyorsynch,
    sourceid,
    systemname,
  } = dataMoveObj || {};

  const {
    loaderservicefolder,
    loggroup,
    masterDataMoverAPIURL,
    tgtconnectionstring,
  } = getDetails() || {};

  const isFishBowl = systemname === 'Fishbowl';

  return isFishBowl
    ? {
        datamovedateid: 60,
        datamoveid,
        historyloadid: 12,
        daterangestart: stDate,
        daterangeend: endDate,
        notyetrun,
        moddate: '2020-05-27T00:00:00',
        moduserid: 1,
        historyload: {
          historyloadid: 12,
          datamoveid,
          chunksizemonths: 3,
          daterangestart: stDate,
          daterangeend: endDate,
          moddate: null,
          moduserid: 1,
          date_initiated: null,
          date_completed: null,
          datamove: {
            datamoveid,
            datamovename,
            sourceid,
            companyid,
            companyshortname,
            clientsystemid: 8,
            systemname,
            createSchedulerJob,
            historyorsynch,
            synchfequencydays: 1,
            extractorservicename: 'Epacca.MultiSource.Rest.Extractor',
            extractorservicefolder:
              'http://localhost:6014/fbextractorapi/pull/',
            loaderservicename: 'Epacca.Fishbowl.Rest.Loader',
            loaderservicefolder: 'http://192.168.17.11:6015/fbdataloaderapi/',
            datafiletype: null,
            connectiontype: 'AmazonS3',
            landingzonename: 'sensiple-amplifi-pacha/fishbowl',
            tgtconnectionstring:
              'server=ar1ga1fr57qi57f-prod.civo5vg6ij0z.us-east-1.rds.amazonaws.com;userid= ikedevuser;password=BX2QSSxZzSfb8rS8^;database=amplifi_pacha_fb_mirror;',
            srcconnectionstring: null,
            loggroup: 'Amplifi.Pacha.Fishbowl.ExtractorServices',
            synchstartseconds: '0',
            synchstartminutes: '5',
            synchstarthours: '0,18',
            synchdayofmonth: '*',
            synchmonth: '*',
            synchdayofweek: '?',
            synchyear: '*',
            moddate: '2020-01-10T00:00:00',
            moduserid: 2,
            source: {
              sourceid: 94,
              sourcelongname: 'Pacha Soap Fishbowl',
              sourceShortName: 'PachaFB',
              sourceType: 'Database',
              sourceMedium: 'Internet URI',
              sourceFormat: 'xml',
              sourceUripath: '208.97.53.163',
              sourceContext: null,
              sourceSizeKbytes: null,
              sourceTopic: null,
              language: 'English',
              sensitivity: 'Proprietary',
              reliabilityScore: '8',
              cloudprofile: 'awsprofile',
              cloudregion: 'us-east-1',
              cloudaccesskey: 'AKIATSUENW2ADNRMUQIC',
              cloudsecret: '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',
              accessId: 'amplifi',
              accessPw: 'jFT626WkXPH/s^4',
              apikey: null,
              sharedSecret: null,
              masterDataMoverAPIURL:
                'http://192.168.17.11:6011/masterdatamoverapi/datamover/',
              modifiedBy: 1,
              modifiedDate: '2020-01-27T23:51:53',
              createdBy: 1,
              createdDate: '2020-01-27T23:51:53',
              datamoves: [],
            },
            datamovereports: [],
          },
          datamovedates: [],
        },
      }
    : {
        datamovedateid: 63,
        datamoveid,
        historyloadid: 15,
        daterangestart: stDate,
        daterangeend: endDate,
        notyetrun,
        moddate: '2020-06-11T00:00:00',
        moduserid: 1,
        historyload: {
          historyloadid: 15,
          datamoveid,
          chunksizemonths: 1,
          daterangestart: stDate,
          daterangeend: endDate,
          moddate: null,
          moduserid: 1,
          date_initiated: null,
          date_completed: null,
          datamove: {
            datamoveid,
            datamovename,
            sourceid,
            companyid,
            companyshortname,
            clientsystemid: 6,
            systemname,
            createSchedulerJob,
            historyorsynch,
            synchfequencydays: 1,
            extractorservicename: 'Epacca.MultiSource.Rest.Extractor',
            extractorservicefolder:
              'http://localhost:6016/fbextractorapi/pull/',
            loaderservicename: 'Epacca.MultiSourcel.Rest.Loader',
            loaderservicefolder,
            datafiletype: null,
            connectiontype: 'AmazonS3',
            landingzonename: 'sensiple-amplifi-evanhealy/locate',
            tgtconnectionstring,
            srcconnectionstring: null,
            loggroup,
            synchstartseconds: '0',
            synchstartminutes: '15',
            synchstarthours: '0',
            synchdayofmonth: '*',
            synchmonth: '*',
            synchdayofweek: '?',
            synchyear: '*',
            moddate: '2021-01-14T00:00:00',
            moduserid: 2,
            source: {
              sourceid,
              sourcelongname: 'EvanHealy Locate',
              sourceShortName: 'EvanHealyLocate',
              sourceType: 'Database',
              sourceMedium: 'Internet URI',
              sourceFormat: 'JSON',
              sourceUripath: 'evanhealy.locateinv.com',
              sourceContext: null,
              sourceSizeKbytes: null,
              sourceTopic: null,
              language: 'English',
              sensitivity: 'Proprietary',
              reliabilityScore: '8',
              cloudprofile: 'awsprofile',
              cloudregion: 'us-east-1',
              cloudaccesskey: 'AKIATSUENW2ADNRMUQIC',
              cloudsecret: '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',
              accessId: 'support@amplificap.com',
              accessPw: 'Kob%#53S',
              apikey: null,
              sharedSecret: null,
              masterDataMoverAPIURL,
              modifiedBy: 1,
              modifiedDate: '2020-04-02T16:00:45',
              createdBy: 1,
              createdDate: '2020-04-02T16:00:45',
              datamoves: [],
            },
            datamovereports: [],
          },
          datamovedates: [],
        },
      };
};

const getDetails = () => {
  const env = process.env.REACT_APP_ENV;
  if (env) {
    if (env === 'dev' || env === 'devServer') {
      return {
        // loaderservicefolder: 'http://10.31.2.139:6017/fbdataloaderapi/',
        // tgtconnectionstring:
        //   'server= ar4j6sr6gplhae-devdb.civo5vg6ij0z.us-east-1.rds.amazonaws.com;userid= ikedevuser;password=BX2QSSxZzSfb8rS8^;database=amplifi_evanhealy_loc_model',
        // masterDataMoverAPIURL:
        //   'http://10.31.2.139:6011/masterdatamoverapi/datamover/',
        // loggroup: 'Amplifi.EvanHealy.Locate.ExtractorServices.Dev',
        loaderservicefolder: ' http://192.168.17.11:6017/fbdataloaderapi/',
        tgtconnectionstring:
          'server= ar1ga1fr57qi57f-prod.civo5vg6ij0z.us-east-1.rds.amazonaws.com;userid= ikedevuser;password=BX2QSSxZzSfb8rS8^;database=amplifi_evanhealy_loc_model',
        masterDataMoverAPIURL:
          'http:// 192.168.17.11:6011/masterdatamoverapi/datamover/',
        loggroup: 'Amplifi.EvanHealy.Locate.ExtractorServices',
      };
    } else if (env === 'stage') {
      return {
        loaderservicefolder: ' http://10.31.2.208:6017/fbdataloaderapi/',
        tgtconnectionstring:
          'server= ar2urzqfazjfip-stagingdb.civo5vg6ij0z.us-east-1.rds.amazonaws.com;userid= ikedevuser;password=BX2QSSxZzSfb8rS8^;database=amplifi_evanhealy_loc_model',
        masterDataMoverAPIURL:
          'http://10.31.2.139:6011/masterdatamoverapi/datamover/',
        loggroup: 'Amplifi.EvanHealy.Locate.ExtractorServices.Stage',
      };
    } else if (env === 'prod') {
      return {
        loaderservicefolder: ' http://192.168.17.11:6017/fbdataloaderapi/',
        tgtconnectionstring:
          'server= ar1ga1fr57qi57f-prod.civo5vg6ij0z.us-east-1.rds.amazonaws.com;userid= ikedevuser;password=BX2QSSxZzSfb8rS8^;database=amplifi_evanhealy_loc_model',
        masterDataMoverAPIURL:
          'http:// 192.168.17.11:6011/masterdatamoverapi/datamover/',
        loggroup: 'Amplifi.EvanHealy.Locate.ExtractorServices',
      };
    }
  }
  return null;
};
