import { combineReducers } from 'redux';

// user
import loginReducer from './users/loginReducer';
import users from './users/allUsers';

// roles
import roles from './roles/allRoles';

// clients
import clients from './clients/allClients';

// reports
import reports from './reports/allReports';

// udm
import dataMoves from './udm/getDataMoves';

const rootReducer = combineReducers({
  loginReducer,
  users,
  roles,
  clients,
  reports,
  dataMoves,
});

export default rootReducer;
