import axios from '../api';
import { register } from './endpoints';

export const userRegister = async user => {
  const userStatus = user.userStatus === 'Active' ? true : false;

  const body = { ...user, userStatus };

  const { data } = await axios.post(register, body);
  return data;
};
