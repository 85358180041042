import ampLogo from '../../assets/img/amp_login_logo.png';
import { Outlet, useNavigate } from 'react-router';

import './styles.css';

export default function AuthLayout() {

  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <main className="background_holder">
      <section className="page_holder">
        <header className="d-flex justify-content-between">
          <img className="logo" alt="logo" src={ampLogo} />
          <div className="welcome_msg">
            <p>Welcome to AmpliFi</p>
          </div>
        </header>
        {/** child component */}
        <Outlet />
        <footer className="row gy-1">
          <div className="col-12 col-sm-6 text-center text-sm-start footer_element">
            <p>@{currentYear} AmpliFi Capital | All rights reserved</p>
          </div>
          <div className="col-12 col-sm-6 text-center text-sm-end footer_element">
            <p>
              <span onClick={() => navigate('/info/privacy_policy')}>
                Privacy Policy
              </span>{' '}
              |{' '}
              <span onClick={() => navigate('/info/terms_of_use')}>
                Terms of use
              </span>
            </p>
          </div>
        </footer>
      </section>
    </main>
  );
}
