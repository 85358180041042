import { call, put } from 'redux-saga/effects';
import { getAllRoles } from '../../../api/roles/getAllRoles';
import * as type from '../../types';

export default function* rolesSaga(action) {
  const { pageNo, pageSize } = action.payload || {};
  try {
    const res = yield call(_ => getAllRoles(pageNo, pageSize));
    yield put({ type: type.FETCH_ROLES_SUCCESS, response: res.data });
  } catch (e) {
    yield put({ type: type.FETCH_ROLES_FAILED, error: e.response });
  }
}
