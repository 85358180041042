import { call, put } from 'redux-saga/effects';
import { fetchAllReports } from '../../../api/reports/getAllReports';
import * as type from '../../types';

export default function* reportsSaga(action) {
  const { pageNo, pageSize } = action.payload || {};
  try {
    const res = yield call(_ => fetchAllReports(pageNo, pageSize));
    yield put({ type: type.FETCH_REPORTS_SUCCESS, response: res.data });
  } catch (e) {
    yield put({ type: type.FETCH_REPORTS_FAILED, error: e.response });
  }
}
