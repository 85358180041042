import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from '../../store/actions';

import createNewIcon from '../../assets/img/create_new.png';

import {
  MainButton,
  BootStrapTable,
  CreateUserModal,
  EditUserModal,
  DeleteUserModal,
  ConfigureUserModal,
} from '../../components';

// table config
import userTableConfig from './userTableConfig';

import './styles.css';

export default function UserPage() {
  const dispach = useDispatch();
  const usersData = useSelector(state => state.users);

  // use States
  const [showCrtUseModl, setCrtUseModl] = useState(false);
  const [showEdtUsrModl, setEdtUsrModl] = useState(false);
  const [deletUsrModl, setDeletUsrModl] = useState(false);
  const [configUsrModl, seConfigUsrModl] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // fetchAllUsers
  useEffect(() => {
    dispach(userActions.fetchAllusers({ pageNo: '1', pageSize: '1000' }));
  }, []);

  const editActionsHandle = row => {
    setSelectedRow(row);
    setEdtUsrModl(true);
  };

  const deleteActionsHandle = row => {
    setSelectedRow(row);
    setDeletUsrModl(true);
  };

  const configureActionsHandle = row => {
    setSelectedRow(row);
    seConfigUsrModl(true);
  };

  // table header options
  const tableConfig = userTableConfig(
    editActionsHandle,
    deleteActionsHandle,
    configureActionsHandle
  );

  // on select All rows
  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      return rows.map(r => r.id);
    }
  };

  // select behaviour
  const selectRow = {
    mode: 'checkbox',
    onSelectAll: handleOnSelectAll,
    // clickToSelect: true,
  };

  return (
    <main className="userPageCont">
      <section className="userPageHead">
        <h4 className="userHeading">User Management</h4>
        <MainButton
          buttIcon={createNewIcon}
          onClick={() => setCrtUseModl(true)}
        >
          Create New User
        </MainButton>

        {/* modals */}
        <CreateUserModal
          show={showCrtUseModl}
          handleClose={() => setCrtUseModl(false)}
        />
        <EditUserModal
          show={showEdtUsrModl}
          handleClose={() => setEdtUsrModl(false)}
          row={selectedRow}
        />
        <DeleteUserModal
          show={deletUsrModl}
          handleClose={() => setDeletUsrModl(false)}
          row={selectedRow}
          delMsg={`Are you sure you want to delete the user ${selectedRow?.userName}`}
        />
        <ConfigureUserModal
          show={configUsrModl}
          handleClose={() => seConfigUsrModl(false)}
          row={selectedRow}
        />
      </section>

      <section className="userPageTable">
        <BootStrapTable
          enableColumnToggle
          enableSearch={{ placeHolder: 'Search By Name, Email, etc' }}
          selectRow={selectRow}
          tableColumns={tableConfig}
          tableData={usersData}
        />
      </section>
    </main>
  );
}
