export const axiosRequestInterceptor = {
  onFulfilled: config => {
    const user = JSON.parse(localStorage.getItem('user'));

    //   add token to header
    const modifyHeaders = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user?.token}`,
      },
    };
    config = { ...config, ...modifyHeaders };

    return config;
  },
  onRejected: error => {
    return Promise.reject(error);
  },
};

export const axiosResponseInterceptor = {
  onFulfilled: res => {
    const isServerDown =
      res?.request?.responseURL.indexOf('index_no_internet.html') !== -1;

    if (isServerDown) {
      window.location.replace('/serverError');
      return Promise.reject('server error');
    }
    if (res.status === 200) return res;
  },
  onRejected: error => {
    if (error?.response && error.response.status === 401) {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && Object.keys(user).length > 0) {
        localStorage.clear();
        window.location.reload();
      }
    }

    return Promise.reject(error);
  },
};
