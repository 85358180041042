import axios from '../api';
import { refreshData } from './endpoints';

export const refeshDataset = async reportId => {
  const body = {};
  try {
    const res = await axios.post(refreshData(reportId), body);
    return res;
  } catch (error) {
    return { error: true, message: 'something went wrong please try later' }
  }
};
