import './styles.css';

const RadioButt = ({ label, ...props }) => {
  return (
    <div className='radioCont'>
      <label>{label}</label>
      <input {...props} />
      <div className='radioElement' />
    </div>
  );
};

export default RadioButt;
