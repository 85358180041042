import DP from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ title, req, hasError, style = {}, ...props }) => {
  return (
    <div style={style} className="ModalInpFCont">
      <label>
        {title}
        {req && <span> *</span>}
      </label>
      <DP {...props} />
      {hasError && <span className="mdlInpErr">{hasError}</span>}
    </div>
  );
};

export default DatePicker;
