import './styles.css';

import { Loader } from '../../../../components';

export default function AuthButton({ children, loading, ...props }) {
  return (
    <button type="button" className="authButn" {...props}>
      {loading ? (
        <div>
          <div className="btn_loader_cont">
            <Loader size="17" color="#fff" />
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
}
