// get requests
export const fetchAllClients = (pgNo, pgSize) =>
  `/Clients/GetAllClients?PageNumber=${pgNo}&PageSize=${pgSize}`;
export const searchAllClients = (searchTxt, pgNo, pgSize) =>
  `/Clients/SearchAll?searchText=${searchTxt}&PageNumber=${pgNo}&PageSize=${pgSize}`;
export const clientById = id => `/Clients/${id}`;

// post requests
export const crteClient = `/Clients/CreateClient`;

// put requests
export const updateClient = id => `/Clients/${id}`;

// delelte requests
export const delClient = id => `/Clients/${id}`;
