const PrivacyPolicyPage = () => {
    return (
        <main className="info_page_cont">
        <header className="info_head">
          <h1>Privacy Policy</h1>
          <p>Last Updated: January 5, 2022</p>
        </header>
  
        <div className="info_contnt_cont">
          <p>
            We are strongly committed to letting you know how we will collect and
            use your information collected online. This policy applies to data and
            information collected through the www.amplificap.com website and our
            services and affiliated websites and forums (including any versions
            optimized for viewing on a wireless or tablet device); all email
            newsletters published or distributed by AMPLIFI; all apps published by
            AMPLIFI; and all other interactive features, services, and
            communications provided by AMPLIFI (“Sites”), however accessed and/or
            used, that are operated by us, made available by us, or produced and
            maintained by AmpliFi Capital Corp (collectively “AMPLIFI” or “we”,
            “us”, or “our”). We have established this privacy policy (“Privacy
            Policy”) to let you know what kinds of personal information we may
            gather during your visit to these Sites and your use of our services
            (“Services”), why we gather your information, what we use your
            personal information for, when we might disclose your personal
            information, and how you can manage your personal information.
            <br />
            <br />
            Please be advised that the practices described in this Privacy Policy
            apply only to information gathered online at our Sites. It does not
            apply to information that you may submit to us offline, or to Sites
            maintained by other companies or organizations to which we may link or
            who may link to us.
            <br />
            <br />
            By visiting our Sites, you are accepting the practices described in
            our Privacy Policy. If you do not agree to the terms of this Privacy
            Policy, please do not use the Sites. We reserve the right to modify or
            amend the terms of our Privacy Policy from time to time without
            notice. Your continued use of our Sites following the posting of
            changes to these terms will mean you accept those changes. If we
            intend to apply the modifications or amendments to this Privacy Policy
            retroactively or to personal information already in our possession, we
            will provide you with notice of the modifications or amendments.
            <br />
            <br />
            If you have any questions about this Privacy Policy or don’t see your
            concerns addressed here, you should contact us by email at
            <strong> support@amplificap.com</strong>.
            <br />
            <br />
          </p>
  
          <h3>What Information About Me Is Collected and Stored?</h3>
          <p>
            <br />
            We collect two basic types of information from you in conjunction with
            your use of the Sites, personal information and non-personal
            information. Personal information is information that you may supply
            to us, as described more fully below, i.e., when you subscribe,
            complete a survey, register for a Site or any part thereof, upload
            content, participate in a community, or provide your e-mail address.
            Personal information is any information that can individually identify
            you and includes, among other things, your name, e-mail address,
            telephone number, postal address, credit card, billing and contact
            information. Non-personal information includes information that does
            not personally identify you, but it may include tracking and usage
            information about your company, location, demographics, use of the
            Sites and the Internet.
            <br />
            <br />
          </p>
          <h3>Personal Information</h3>
          <p>
            <br />
            As a general matter, you can browse the Sites without submitting your
            personal information to us. However, there are a number of
            circumstances in which you may supply us with your customer
            information. The following lists the most common ways in which we may
            collect your customer information.
          </p>
  
          <ul>
            <br />
            <li>Registration for an account on the Sites</li>
            <li>Profile information that you provide for your user profile</li>
            <li>
              Sign up to receive e-mail newsletters or e-mail alerts from us
            </li>
            <li>Request for customer service or other assistance</li>
            <li>
              Service-related communications, e.g. account verification; technical
              notification
            </li>
            <li>Participation in surveys, panels, or market research</li>
            <li>
              Participation in communities, chat rooms and comment threads, other
              fora, and other interactive services
            </li>
            <li>
              Submission of content or other data and information on any part of
              the Sites that permit it, including photos or comments
            </li>
            <li>
              Any other place on the Sites where you knowingly volunteer personal,
              company or customer information
            </li>
            <br />
          </ul>
  
          <h3>Non-Personal Information</h3>
          <p>
            <br />
            In addition, when you interact with the Sites, we may collect certain
            information that does not identify you or your customers individually
            and our servers may automatically keep an activity log of your use of
            our Sites (“Non-Personal Information”). Generally, we collect and
            store the following categories of Non-Personal Information:
          </p>
  
          <ul>
            <br />
            <li>
              Demographic data such as age, gender, and five digit zip code as
              part of collecting personal information
            </li>
            <li>
              Device information about your computer, mobile device, or other
              device that you use to access the Sites. This information may
              include IP address, geolocation information, unique device
              identifiers, browser type, browser language, and other transactional
              information.
            </li>
            <li>
              Analytics and usage information about your use of the Sites. This
              information includes a reading history of the pages you view. We use
              this information to provide you a more customized experience on the
              Sites.
            </li>
            <li>
              Additional “traffic data” and log files such as time of access, date
              of access, software crash reports, session identification number,
              access times, and referring website addresses.
            </li>
            <li>Your search terms and search results. </li>
            <li>Other information regarding your use of the Sites:</li>
            <ul>
              <li>
                emographic data such as age, gender, and five digit zip code as
                part of collecting personal information
              </li>
              <li>
                Device information about your computer, mobile device, or other
                device that you use to access the Sites. This information may
                include IP address, geolocation information, unique device
                identifiers, browser type, browser language, and other
                transactional information.
              </li>
              <li>
                Analytics and usage information about your use of the Sites. This
                information includes a reading history of the pages you view. We
                use this information to provide you a more customized experience
                on the Sites.
              </li>
              <li>
                Additional “traffic data” and log files such as time of access,
                date of access, software crash reports, session identification
                number, access times, and referring website addresses.
              </li>
              <li>Your search terms and search results.</li>
              <li>Other information regarding your use of the Sites.</li>
            </ul>
            <br />
          </ul>
          <h3>Collection of Your Source IP Address/Location Information</h3>
          <p>
            <br />
            We will collect and store location information about you on the Sites
            and associated with your account (if you create an account) that you
            volunteer on the Sites or enable through the Sites or your device. We
            will not collect any location information that you do not volunteer or
            enable. We will delete any location information that you request.
            <br />
            <br />
            We also collect and store your device’s source IP address which may
            disclose the location of your device at the time you access the Sites.
            Advertisements and certain content may be directed to you as a result
            of this data.
            <br />
            <br />
            In addition, the Sites will deliver content based on your current
            location if you choose to enable that feature. If you enable that
            feature, your current location will be stored locally on your device
            which will be used by the Sites to serve you certain content tied to
            your location. If you elect to have your location-based searches saved
            to your history, we will store such information on our servers.
            <br />
            <br />
          </p>
          <h3>Collection of Metadata</h3>
          <p>
            <br />
            Metadata is usually technical data that is associated with
            user-provided content, including without limitation text, graphics,
            photographs, images, moving images, sound, and illustrations
            (“Content”). For example, Metadata can describe how, when and by whom
            a piece of user Content was collected and how that content is
            formatted. You can add or may have Metadata added to your user Content
            by including a hashtag (for example, to mark keywords when you post a
            photo), geotag (for example, to mark your location to a photo),
            comments or other data. This makes your user Content more searchable
            by others and more interactive. If you geotag your photo or tag your
            photo using other's APIs then, your latitude and longitude will be
            stored with the photo and searchable (for example, through a location
            or map feature).
            <br />
            <br />
          </p>
          <h3>
            Collection of Personal and Non-Personal Information From Other Sources
          </h3>
          <p>
            <br />
            We also may collect and store information about you that we receive
            from other sources (including by us from our offline interactions with
            you) to, among other things, enable us to update and correct the
            information contained in our database and to better customize your
            experience on the Sites.
            <br />
            <br />
          </p>
          <h3>Use of Cookies, Flash Cookies, Web Storage, and Web Beacons</h3>
          <p>
            <br />
            Like many websites, we use “cookies”, which are small text files that
            are stored on your computer or equipment when you visit certain online
            pages that record your preferences and actions. We use cookies to
            track use of our Sites and online services. We may also use cookies to
            monitor traffic, improve the Sites and make them easier and/or
            relevant for your use. You have the ability to accept or decline
            cookies. Most web browsers automatically accept cookies, but, if you
            prefer, you can usually modify your browser setting to decline
            cookies. If you delete your cookies or if you set your web browser to
            decline cookies, some features of the Sites may not work or may not
            work as designed. For more information on cookies and how to disable
            them, you can consult the information provided by the Interactive
            Advertising Bureau at www.allaboutcookies.org.
            <br />
            <br />
            We may also use web beacons or flash cookies. A web beacon or flash
            cookie (also known as “action tags”, “tracer tags”, or “single-pixel
            gifs”) is an invisible graphic on a web page that is programmed to
            collect non-personal information about your use of a given website. We
            do not share or provide Personal Information we may collect through
            such web beacons, such as names, e-mail addresses and phone numbers
            without your express permission.
            <br />
            <br />
            We may also use “Web Storage” to store data about your use of our
            Sites. Web Storage is a feature in some browsers that permits websites
            to store data within your browser rather within a cookie. This should
            make this data more secure and make your browser perform better. We do
            not share or provide Personal Information we may store in Web Storage,
            such as names, e-mail addresses and phone numbers without your express
            permission.
            <br />
            <br />
            We work with third-party advertising companies who may utilize flash
            or enhanced cookies or web beacons to better provide advertisements
            about goods and services that may be of interest to you. These
            partners do not have access to or use your name, address, e-mail
            address, telephone number or other personal information from us. They
            may, however, anonymously track your Internet usage across other
            websites in their networks beyond these Sites. To learn more about the
            use of this information or to choose not to have this information used
            by our third-party advertising partners by opting out please visit the
            Network Advertising Initiative by clicking www.networkadvertising.org.
            <br />
            <br />
          </p>
          <h3>Is Personal Information Collected From Children?</h3>
          <p>
            <br />
            Neither the Site nor the Services are intended for or directed to
            children under the age of 13. Unless otherwise disclosed during
            collection and with parent or guardian consent (for instance, in some
            of the educational areas on our Sites), AMPLIFI does not knowingly
            collect personally identifiable information from children under 13
            years of age. If you are under 13 years of age you should not provide
            personally identifiable information to AMPLIFI without providing us
            with consent from your parents. If AMPLIFI discovers that a child
            under the age of 13 has provided AMPLIFI with personally identifiable
            information and we do not have parental consent, AMPLIFI will
            immediately delete that child’s information from the Sites.
            <br />
            <br />
          </p>
          <h3>How Do We Use Your Information?</h3>
          <p>
            <br />
            We use the information we learn from you to help us personalize and
            continually improve your experience on the Sites. We may use your
            Personal and Non-Personal Information in the following ways:
            <br />
          </p>
  
          <h5>
            <br />
            General Uses
          </h5>
          <ul>
            <br />
            <li>
              Provide you with the content, products and services you request
            </li>
            <li>Upload your content to our Sites as you request</li>
            <li>
              Communicate with you about your account, if created, or transactions
              with us and send you information about features and enhancements on
              or to our Sites
            </li>
            <li>Communicate with you about changes to our policies</li>
            <li>Communicate with you about your comment to a community</li>
            <li>
              Personalize content and experiences on our Sites, including
              providing you recommendations and feedback based on your preferences
            </li>
            <li>
              Send you newsletters, offers and promotions for our products and
              services, third party products and services, or special events by
              e-mail, text, or other another medium
            </li>
            <li>
              Provide you with advertising including advertising based on your
              activity on our Sites or activity on third party sites and
              applications
            </li>
            <li>Optimize or improve our products, services and operations</li>
            <li>Automatically update the Sites on your device</li>
            <li>
              Detect, investigate, and prevent activities that may violate our
              policies or be illegal
            </li>
            <li>
              Perform statistical, demographic, and marketing analyses of users of
              the Sites and their purchasing patterns
            </li>
            <br />
          </ul>
  
          <h3>Combination of Your Personal Information</h3>
          <p>
            <br />
            We use the information from one portion of the Sites on other portions
            of Sites in our network of Sites or in reports and analysis, all of
            which are owned and operated by AMPLIFI, and we may combine
            information gathered from multiple portions of the Sites into a single
            customer record or analysis or report. We also use and/or combine
            information that we collect off-line or we collect or receive from
            third party sources to enhance, expand, and check the accuracy of your
            customer records.
            <br />
            <br />
          </p>
          <h3>Use of Your Location Information</h3>
          <h5>
            <br />
            Specifically, we use your location information to:
          </h5>
          <ul>
            <br />
            <li>Provide you with the products and services you request</li>
            <li>
              Personalize content and experiences on our Sites, including
              providing you recommendations based on your preferences
            </li>
            <li>
              Personalize your newsletters, offers and promotions for our products
              and services, third party products and services, or special events
            </li>
            <li>
              Personalize the advertising you receive, including advertising based
              on your activity on our Sites or activity on third party sites and
              applications
            </li>
            <li>Optimize or improve our products, services and operations</li>
            <li>
              Detect, investigate, and prevent activities that may violate our
              policies or be illegal
            </li>
            <li>
              Perform statistical, demographic, and marketing analyses of users of
              the Sites and their purchasing patterns
            </li>
            <br />
          </ul>
  
          <h3>Who Do We Provide Your Information To?</h3>
          <p>
            <br />
            Except as disclosed in this Privacy Policy, we do not disclose your
            Personal Information collected online to any companies not part of
            AMPLIFI or its subsidiaries or related entities. As disclosed below,
            we may provide your information to our partners, for which we may
            receive a fee in some circumstances, though in no event will we sell
            or rent your Personal Information as part of a customer list or
            similar transaction other than as described in this Privacy Policy.
            <br />
            <br />
          </p>
          <h3>Third-Party Agents</h3>
          <p>
            <br />
            We have third party agents, subsidiaries, affiliates and partners that
            perform functions on our behalf, such as hosting, billing, push
            notifications, storage, bandwidth, content management tools,
            analytics, customer service, fraud protection, etc. These entities
            have access to the Personal Information needed to perform their
            functions and are contractually obligated to maintain the
            confidentiality and security of that Personal Information. They are
            restricted from using, selling, distributing or altering this data in
            any way other than to provide the requested services to the Sites.
            <br />
            <br />
          </p>
  
          <h3>Business Partners and Third Parties</h3>
          <p>
            We may share your Personal Information with our business partners,
            publishers or co-marketers from time to time. We may also share your
            Personal Information with third parties that provide products and
            services that we think you may be interested in to enable those third
            parties to contact you about their goods and services, for which we
            may receive a fee. Although our treatment of your Personal Information
            is governed by this Privacy Policy, the business partner, publisher,
            or third party&rsquo;s treatment of your Personal Information will be
            governed by the business partner, publisher, or third party&rsquo;s
            privacy policy. You may opt-out of having your Personal Information
            shared with business partners, publishers and third parties at any
            time by following the opt-out process described below.
          </p>
          <p>&nbsp;</p>
          <h3>Emergency Situations</h3>
          <p>
            We may also use or disclose Personal Information if required to do so
            by law or in the good-faith belief that such action is necessary to
            (a) conform to applicable law or comply with legal process served on
            us or the Sites; (b) protect and defend our rights or property, the
            Sites or our users; and (c) act under emergency circumstances to
            protect the personal safety of us, our affiliates, agents, or the
            users of the Sites or the public. This includes exchanging information
            with other companies and organizations for verification and fraud
            protection.
          </p>
          <p>&nbsp;</p>
          <h3>Use of Non-Personal Information</h3>
          <p>
            We may disclose or share Non-Personal Information (or other
            information, other than Personal Information) in any other manner that
            we deem appropriate or necessary. Among other things, we will disclose
            Non-Personal Information to third parties to help us determine how
            people use parts of the Sites and who our users are so we can improve
            our Sites. We will also disclose Non-Personal Information to our
            partners and other third parties about how our users collectively use
            the Sites.
          </p>
          <p>&nbsp;</p>
          <h3>E-mail and Text Communications</h3>
          <p>
            E-mail communications and text messages sent from us or through us are
            designed to make your experience on the Sites more efficient. By
            participating in the Services, you specifically agree to accept and
            consent to receiving e-mail communications and text messages initiated
            from us or through us, which include, without limitation: message
            notification e-mails, e-mails or text messages informing you about the
            Service and e-mails informing you of promotions we run and new and
            existing features we provide. Standard text messaging charges applied
            by your cell phone carrier will apply to text messages we send. If you
            change your mobile phone service provider, the notification service
            may be deactivated for your phone number and you may need to re-enroll
            in the notification service. We reserve the right to cancel the
            notification service at any time. If you do not wish to receive any of
            our e-mail communications or text messages, please do not use the
            Services.
          </p>
          <p>&nbsp;</p>
          <h3>What Steps Are Taken To Keep Personal Information Secure?</h3>
          <p>
            We are concerned about ensuring the security of your Personal
            Information. We exercise great care in providing secure transmission
            of your information from your device to our servers. Personal
            Information collected by our Sites is stored in secure operating
            environments that are not available to the public. Our security
            procedures mean that we may occasionally request proof of identity
            before we disclose your Personal Information to you. Please
            understand, however, that while we try our best to safeguard your
            Personal Information once we receive it, no transmission of data over
            the Internet or any other public network can be guaranteed to be 100%
            secure.
          </p>
          <p>&nbsp;</p>
          <h3>How Can We Transfer Your Personal Information?</h3>
          <p>
            Your information collected through the Service may be stored and
            processed in the United States or any other country in which AMPLIFI
            or its service providers maintain facilities. AMPLIFI or its service
            providers may transfer information that we collect about you,
            including personal information across borders and from your country or
            jurisdiction to other countries or jurisdictions around the world. If
            you are located outside the United States or other regions with laws
            governing data collection and use that may differ from United States
            law, please note that we may transfer information, including personal
            information, to a country and jurisdiction that does not have the same
            data protection laws as your jurisdiction. By registering for and
            using the Service you consent to the transfer of information to the US
            or to any other country in which AMPLIFI, or its service providers
            maintain facilities and the use and disclosure of information about
            you as described in this Privacy Policy.
          </p>
          <p>&nbsp;</p>
          <h3>How Long Do We Keep Your Information?</h3>
          <p>
            We will hold Your Personal Information on our systems for as long as
            is necessary for the relevant activity or the AMPLIFI data retention
            schedule, whichever is longer. If you created an account, following
            termination or deactivation of your account AMPLIFI or its service
            providers may retain information (including your profile information)
            and user Content for a commercially reasonable time for backup,
            archival, and/or audit purposes. If you have any questions about
            termination or deactivation of your account, please contact us
            directly at privacy@seniorfinanceadvisor.com.
          </p>
          <p>&nbsp;</p>
          <h3>What Happens When I Link To or From Another Website?</h3>
          <p>
            These Sites may contain links to other websites operated by affiliates
            of AMPLIFI or third parties. Please be advised that the practices
            described in this Privacy Policy for AMPLIFI do not apply to
            information gathered through these other websites. We are not
            responsible for the actions and privacy policies of third parties and
            other websites.
          </p>
          <p>&nbsp;</p>
          <h3>
            Uploaded User Content, Public Areas, Chat Rooms, Reviews, and
            Community Areas
          </h3>
          <p>
            Please remember that any information you share in public areas, such
            as photos on the service or message boards or feedback sections,
            becomes public and anyone may take and use that information. Once you
            have shared such content or made it public, that user Content may be
            shared by others. If you remove information that you posted to the
            Sites, copies may remain viewable in cached or archived pages of the
            Sites, or if other users or third parties have copied or saved that
            information. Please be careful about what you disclose and do not post
            any Personal Information that you expect to keep private. Please
            consult the applicable guidelines, if any, for use of our chat rooms
            and other community areas for more information.
          </p>
          <p>&nbsp;</p>
          <h3>Governing Law</h3>
          <p>
            These Sites are published in the United States and other countries. We
            attempt to protect the Personal Information of all users of our Sites
            and we attempt to comply with local data protection and consumer
            rights laws to the extent they may apply to the AMPLIFI services, but
            our Sites are located and targeted to United States citizens and our
            policies are directed at compliance with those laws. If you are
            uncertain whether this privacy policy conflicts with the applicable
            local privacy laws where you are located, you should not submit your
            Personal Information to AMPLIFI.
          </p>
          <p>&nbsp;</p>
          <h3>Assignment</h3>
          <p>
            We may change our ownership or corporate organization while providing
            the Sites and services. We may also sell certain assets associated
            with the Sites. As a result, please be aware that in such event we may
            transfer some or all of your information to a company acquiring all or
            part of our assets or to another company with which we have merged.
            Under such circumstances we would, to the extent possible, require the
            acquiring party to follow the practices described in this Privacy
            Policy, as it may be amended from time to time. Nevertheless, we
            cannot promise that an acquiring company or the merged company will
            have the same privacy practices or treat your information the same as
            described in this Privacy Policy.
          </p>
          <p>&nbsp;</p>
          <h3>Changes to This Policy</h3>
          <p>
            As our Sites continue to develop, we may add new services and features
            to our Sites. In the event that these additions affect our Privacy
            Policy, this document will be updated appropriately. We will post
            those changes prominently so that you will always know what
            information we gather, how we might use that information and whether
            we will disclose it to anyone. We do, however, recommend that you read
            this Privacy Policy each time you use our Sites in case you missed our
            notice of changes to the Privacy Policy. We will not, however,
            materially change our policies and practices to make them less
            protective of Personal Information we have previously collected from
            you without your express consent.
          </p>
          <p>&nbsp;</p>
          <h3>How Do I Opt-Out or Correct Information About Me</h3>
          <p>
            You may always opt-out of receiving future e-mail messages, text
            messages, and newsletters from AMPLIFI. We provide you with the
            opportunity to opt-out of receiving communications from us by sending
            us a message at support@amplificap.com.
          </p>
          <p>&nbsp;</p>
          <h3>Your California Privacy Rights</h3>
          <p>AMPLIFI does not respond to NO NOT TRACK Signals</p>
          <p>&nbsp;</p>
  
          <h3>Contact Information</h3>
          <p>AmpliFi Capital Corp.</p>
          <p>
            5432 N. 160<sup>th</sup> Avenue Circle
          </p>
          <p>Omaha, NE 68102</p>
        </div>
      </main>
    )
}

export default PrivacyPolicyPage
