import * as type from '../../types';

const initialState = {
  data: JSON.parse(localStorage.getItem('user')),
  loading: false,
  error: false,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case type.SIGNIN_REQUESTED:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case type.SIGNIN_SUCCESS:
      return {   
        data: action.response,
        loading: false,
        error: false,
      };
    case type.SIGNIN_FAILED:
      return {
        data: {},
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
