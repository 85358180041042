import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { NavLink, useLocation, useSearchParams } from 'react-router-dom';

// icons
import dashboardIcon from '../../../assets/img/dashboard.png';
import reportIcon from '../../../assets/img/report.png';
import udmIcon from '../../../assets/img/udm.png';
import userIcon from '../../../assets/img/user.png';
import helpIcon from '../../../assets/img/help.png';

import { fetchAllReports } from '../../../api/reports/getAllReports';
import { fetchAllReportsById } from '../../../api/reports/getAllReportsById';

import { Collapse, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import './styles.css';

export default function SideBar() {
  const location = useLocation(),
    [searchParams, setSearchParams] = useSearchParams(),
    rprtId = searchParams.get('reportId');

  const user = useSelector(state => state.loginReducer.data);
  const hasRoles = user?.userRole.length > 0,
    roles = hasRoles && user?.userRole[0].roleName;

  // console.log(roles);

  const defToogle = {
    report: false,
    admin: false,
  };

  const [active, setActive] = useState('');
  const [toggle, setToggle] = useState(defToogle);

  const [reports, setReports] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async _ => {
      try {
        if (hasRoles && roles.includes('Admin')) {
          setIsAdmin(true);
          const resp = await fetchAllReports(1, 40);
          setReports(resp.data);
        } else {
          const resp = await fetchAllReportsById(user?.userId);
          setReports(resp.data);
        }
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, []);

  const pathName = location.pathname.slice(1);

  useEffect(
    _ => {
      // to first report
      if (!rprtId && reports.length > 0 && pathName === 'reports') {
        const { name, id } = reports[0] || {};
        setSearchParams({ reportId: id, name: name });
      }
    },
    [reports]
  );

  useEffect(() => {
    setActive(pathName);
    setToggle(defToogle);
  }, [rprtId, pathName]);

  useEffect(() => {
    if (toggle.admin || toggle.report) setActive('');
    else setActive(pathName);
  }, [toggle]);

  const handleToggle = key => {
    const other = key === 'admin' ? 'report' : 'admin';

    setToggle(s => ({ [key]: !s[key], [other]: false }));
  };

  // const adminTooltip = props => (
  //   <Tooltip className="adminTooltip" id="admin-tooltip" {...props}>
  //     <section className="adminTooltipCont">
  //       <div className="adminTooltipHead">
  //         <span>Administration</span>
  //       </div>

  //       <hr />

  //       <div className="adminTooltipLinks">
  //         <NavLink
  //           className={a => (a.isActive ? 'activeSubItem' : '')}
  //           to="/admin/usermanagement"
  //         >
  //           User
  //         </NavLink>
  //         <NavLink
  //           className={a => (a.isActive ? 'activeSubItem' : '')}
  //           to="/admin/clientmanagement"
  //         >
  //           Client
  //         </NavLink>
  //         <NavLink
  //           className={a => (a.isActive ? 'activeSubItem' : '')}
  //           to="/admin/reportmanagement"
  //         >
  //           Report
  //         </NavLink>
  //         <NavLink
  //           className={a => (a.isActive ? 'activeSubItem' : '')}
  //           to="/admin/rolemanagement"
  //         >
  //           Role
  //         </NavLink>
  //         {/* <NavLink
  //           className={a => (a.isActive ? 'activeSubItem' : '')}
  //           to="/udmManagement"
  //         >
  //           UDM
  //         </NavLink>
  //         <NavLink className={a => (a.isActive ? 'activeSubItem' : '')} to="/dad">
  //           DAD
  //         </NavLink> */}
  //       </div>
  //     </section>
  //   </Tooltip>
  // );

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Help</Popover.Header>
      <Popover.Body>
        Please send your queries to{' '}
        <strong><a href='mailto:Support.portal@amplificap.com'>Support.portal@amplificap.com</a> </strong>
        our support person will contact you shortly.
        <br />
        Thanks!
      </Popover.Body>
    </Popover>
  );

  const adminLinks = [
    {
      txt: 'User',
      to: '/admin/usermanagement',
    },
    {
      txt: 'Role',
      to: '/admin/rolemanagement',
    },
    {
      txt: 'Client',
      to: '/admin/clientmanagement',
    },
    {
      txt: 'Report',
      to: '/admin/reportmanagement',
    },
  ];

  return (
    <main className="customSidebar">
      <section className="sidebarCont">
        <nav className="navCont">
          {/* <span className={`navItem ${active === 'myview' && 'navItemActive'}`}>
            <img src={dashboardIcon} alt="dIcon" />
          </span> */}

          {/** <OverlayTrigger
            placement={'right'}
            overlay={<Tooltip id={`tooltip-top`}>reports.</Tooltip>}
          > */}

          <span
            className={`navItem ${(active === 'reports' || toggle.report) && 'navItemActive'
              }`}
            onClick={() => handleToggle('report')}
          >
            <img src={reportIcon} alt="rIcon" />
          </span>
          {/** </OverlayTrigger> */}

          {/* <span
            className={`navItem ${active === 'udm' && 'navItemActive'}`}
            onClick={() => navigateTo('/udm')}
          >
            <img src={udmIcon} alt="uIcon" />
          </span> */}

          {isAdmin && (
            // <OverlayTrigger
            //   placement="right-start"
            //   delay={{ show: 250, hide: 2000 }}
            //   overlay={adminTooltip}
            // >
            <span
              className={`navItem ${(active.includes('admin') || toggle.admin) && 'navItemActive'
                }`}
              onClick={() => handleToggle('admin')}
            >
              <img src={userIcon} alt="uIcon" />
            </span>
            // </OverlayTrigger>
          )}
        </nav>

        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <div className="helpIconCont">
            <span className="helpIcon">
              <img src={helpIcon} alt="hIcon" />
            </span>
          </div>
        </OverlayTrigger>
      </section>

      {/** collapse right area */}
      <Collapse
        in={toggle.report || toggle.admin}
        dimension="width"
        unmountOnExit={true}
      >
        <section className="rightCollaspe">
          <div className="subItemCont">
            {toggle.report ? (
              reports.length === 0 ? (
                <p className="no_reports">no reports to display</p>
              ) : (
                reports.map(({ name, id }, index) => (
                  <NavLink
                    key={index}
                    className={() =>
                      `navsubItem ${id === Number(rprtId) && 'activeSubItem'}`
                    }
                    to={`/reports?reportId=${id}&name=${name}`}
                  >
                    {name}
                  </NavLink>
                ))
              )
            ) : (
              toggle.admin &&
              adminLinks.map(({ txt, to }) => (
                <NavLink
                  key={txt}
                  className={a => `navsubItem ${a.isActive && 'activeSubItem'}`}
                  to={to}
                >
                  {txt}
                </NavLink>
              ))
            )}
          </div>
        </section>
      </Collapse>
    </main>
  );
}
