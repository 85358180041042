export const adminRoutes = [
  {
    label: 'Client',
    link: '/admin/clientmanagement',
  },
  {
    label: 'Report',
    link: '/admin/reportmanagement',
  },
  {
    label: 'Role',
    link: 'admin/rolemanagement',
  },
  {
    label: 'User',
    link: '/admin/usermanagement',
  },
];
