import "./styles.css";

import { Table } from "react-bootstrap";

const reportData = [
  {
    id: "56556",
    label: "Executive Report Pacha",
    hasChild: true,
    children: [
      {
        id: "43535",
        label: "Fishbowl",
        hasChild: true,
        children: [
          {
            id: "5634",
            label: "YTD Revenue",
            hasChild: false,
            others: {
              frequency: "Daily",
              src_of_truth: "tick",
              power_bi_refesh: "tick",
              udm_data_lake: "tick",
              comments: "N/A",
            },
          },
          {
            id: "65223",
            label: "Current Inventory QTY & Value",
            hasChild: false,
            others: {
              frequency: "Daily",
              src_of_truth: "tick",
              power_bi_refesh: "tick",
              udm_data_lake: "tick",
              comments: "N/A",
            },
          },
          {
            id: "45642",
            label: "Current Open Order Value",
            hasChild: false,
            others: {
              frequency: "Weekly",
              src_of_truth: "yellow_tick",
              power_bi_refesh: "yellow_tick",
              udm_data_lake: "yellow_tick",
              comments:
                "FishBowl changes required. John H. has to confirm data lake.",
            },
          },
        ],
      },
      {
        id: "65423",
        label: "QBDT",
        hasChild: true,
        children: [
          {
            id: "66345",
            label: "Current Accounts Recievable Total Amount",
            hasChild: false,
            others: {
              frequency: "Daily",
              src_of_truth: "tick",
              power_bi_refesh: "tick",
              udm_data_lake: "tick",
              comments: "N/A",
            },
          },
          {
            id: "89823",
            label: "Current Account Payable Total Amount",
            hasChild: false,
            others: {
              frequency: "Weekly",
              src_of_truth: "tick",
              power_bi_refesh: "tick",
              udm_data_lake: "tick",
              comments: "N/A",
            },
          },
          {
            id: "234567",
            label: "Balance Sheet Check Page",
            hasChild: false,
            others: {
              frequency: "Daily",
              src_of_truth: "tick",
              power_bi_refesh: "tick",
              udm_data_lake: "tick",
              comments: "N/A",
            },
          },
          {
            id: "567523",
            label: "P&L Check Page",
            hasChild: false,
            others: {
              frequency: "Daily",
              src_of_truth: "tick",
              power_bi_refesh: "tick",
              udm_data_lake: "tick",
              comments: "N/A",
            },
          },
        ],
      },
    ],
  },
  {
    id: "1367",
    label: "Orders",
    hasChild: false,
    others: {
      vendor: "Shopify",
      kpi_check: "N/A",
      frequency: "Daily",
      src_of_truth: "tick",
      power_bi_refesh: "tick",
      udm_data_lake: "tick",
      comments: "N/A",
    },
  },
];

let i = 0,
  count = 0;

const calcRowSpan = (arr = reportData) => {
  const obj = arr[i];

  console.log("current obj: ", obj);

  if (obj?.hasChild) {
    count = count + 1;
    console.log("in IF, count: ", count, "   hasChild: ", obj?.hasChild);
  } else {
    i = i + 1;
    console.log("in ELSE, i: ", i);
  }

  if (i < arr.length) {
    // pass child
    return calcRowSpan(obj.children);
  } else return count;
};

// console.log("result: ", calcRowSpan());

const RsuiteTable = () => {
  return (
    <main className="spanTbleCont">
      <Table borderless>
        <thead>
          <tr>
            <th rowSpan="2">Report</th>
            <th rowSpan="2">Vendor</th>
            <th rowSpan="2">KPI Check</th>
            <th rowSpan="2">Frequency</th>
            <th colSpan="3">Report Status </th>
            <th rowSpan="2">Comments</th>
          </tr>
          <tr>
            <th>Source of Truth</th>
            <th>Power BI Refresh</th>
            <th>UDM Data Lake</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="7">Executive Report Pacha</td>
            <td rowSpan="3">Fishbowl</td>
            <td>YTD Revenue</td>
            <td>Daily</td>
            <td>tick</td>
            <td>tick</td>
            <td>tick</td>
            <td></td>
          </tr>
          <tr>
            <td>Current Inventory QTY & Value</td>
            <td>Daily</td>
            <td>tick</td>
            <td>tick</td>
            <td>tick</td>
            <td></td>
          </tr>
          <tr>
            <td>Current Open Order Value</td>
            <td>Daily</td>
            <td>tick</td>
            <td>tick</td>
            <td>tick</td>
            <td>
              FishBowl changes required. John H. has to confirm data lake.
            </td>
          </tr>
          <tr>
            <td rowSpan="4">QBDT</td>
            <td>Current Accounts Recievable Total Amount</td>
            <td>Daily</td>
            <td>tick</td>
            <td>tick</td>
            <td>tick</td>
            <td></td>
          </tr>
          <tr>
            <td>Current Account Payable Total Amoun</td>
            <td>Weekly</td>
            <td>tick</td>
            <td>tick</td>
            <td>tick</td>
            <td></td>
          </tr>
          <tr>
            <td>Balance Sheet Check Page</td>
            <td>Daily</td>
            <td>tick</td>
            <td>tick</td>
            <td>tick</td>
            <td></td>
          </tr>
          <tr>
            <td>P & L Check Page</td>
            <td>Daily</td>
            <td>tick</td>
            <td>tick</td>
            <td>tick</td>
            <td></td>
          </tr>
          <tr>
            <td>Orders</td>
            <td>Shopify</td>
            <td>N/A</td>
            <td>Daily</td>
            <td>tick</td>
            <td>No Pwer bi report</td>
            <td>cross</td>
            <td>History load running</td>
          </tr>
        </tbody>
      </Table>
    </main>
  );
};

export default RsuiteTable;
