import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { rolesActions } from '../../../../store/actions';

// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';

import { createRole } from '../../../../api/roles/createRole';

// validate
import validator from 'validator';

// toast
import { toastError } from '../../../../utils/toast';

const CreateRoleModal = props => {
  const dispach = useDispatch();

  // states
  const [isRoleCreated, setRoleCreated] = useState(false);
  const [role, setRole] = useState({
    name: '',
  });

  const handleStateChange = e => {
    const { name, value } = e.target;
    setRole(state => ({ ...state, [name]: value }));
  };

  const emptyFields = () => {
    setRole({
      name: '',
    });
  };

  const validateFields = ({ name }) => {
    if (validator.isEmpty(name)) return 'Please enter roll Name';

    // validated
    return false;
  };

  const handleCreateRole = () => {
    const validate = validateFields(role);
    if (!validate) {
      (async _ => {
        try {
          const res = await createRole(role);

          // fetch all roles
          dispach(
            rolesActions.fetchAllroles({ pageNo: '1', pageSize: '1000' })
          );

          // empty fields
          emptyFields();
          // show success message
          setRoleCreated(true);
        } catch (e) {
          toastError(`${e.message}`);
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const handleCloseUser = () => {
    const { handleClose } = props;

    setTimeout(() => setRoleCreated(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!isRoleCreated ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Clear" className="grayButn" onClick={emptyFields} />
          <ModalButt
            text="Create"
            className="greenButn"
            onClick={handleCreateRole}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseUser}
        />
      )}
    </>
  );

  const UserCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Created</p>
      <span>Role created successfully.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      headerTxt="Create New Role"
      FooterContnt={FooterComp}
    >
      {!isRoleCreated ? (
        <form>
          <ModalInpF
            title="Role Name"
            req
            name="name"
            value={role.name}
            onChange={handleStateChange}
          />
        </form>
      ) : (
        <UserCreatedComp />
      )}
    </RightModal>
  );
};

export default CreateRoleModal;
