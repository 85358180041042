import "./styles.css";

const ModalButton = ({ text, width, ...props }) => {
  return (
    <div className="mdlButtCont">
      <button style={width ? { width } : { width: "7.7rem" }} {...props}>
        {text}
      </button>
    </div>
  );
};

export default ModalButton;
