// get requests
export const getUserConfig = (PageNumber, PageSize) =>
  `/UserConfiguration/GetUsersConfigurations?PageNumber=${PageNumber}&PageSize=${PageSize}`;
export const searchAllUserConfig = (searchTxt, PageNumber, PageSize) =>
  `/UserConfiguration/SearchAll?searchText=${searchTxt}&PageNumber=${PageNumber}&PageSize=${PageSize}`;
export const getUserConfigById = userId =>
  `/UserConfiguration/GetUsersConfigurationsById?userId=${userId}`;
export const getRolesById = id =>
  `/UserConfiguration/GetUsersbyRole?roleId=${id}`;

//  post requests
export const crteUserConfig = `/UserConfiguration/CreateUserConfiguration`;

// put requests
export const updteUserConfig = id => `/UserConfiguration/${id}`;

// delete requests
export const deleteUserConfig = id => `/UserConfiguration/${id}`;
