import { useState } from 'react';
import passwordIcon from '../../assets/img/password.png';
import { FormInput, AuthButton, FormContainer } from '../../components';
import { Link } from 'react-router-dom';

export default function ResetPasswordPage() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <FormContainer
      heading="Reset Password?"
      descp={`Enter your new password below, we're just being extra safe`}
    >
      <form>
        <FormInput
          label="New Password"
          img={passwordIcon}
          type="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          placeholder="Type your new password"
        />
        <FormInput
          label="Confirm Password"
          img={passwordIcon}
          type="password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          placeholder="Type your confirm password"
        />
        <Link to="/auth">
          <AuthButton>RESET PASSWORD</AuthButton>
        </Link>
      </form>
    </FormContainer>
  );
}
