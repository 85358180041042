import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  rolesActions,
  clientActions,
  reportActions,
} from '../../../../store/actions';

// api
import { createUserConfig } from '../../../../api/userConfig/createUserConfig';
import { getConfigById } from '../../../../api/userConfig/getConfigById';
import { updateUserConfig } from '../../../../api/userConfig/updateUserConfig';

// modal
import RightModal from '../../base/rightModal';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';
import DropDown from '../../../modalComp/dropDown';

// toast
import { toastSuccess, toastError } from '../../../../utils/toast';

import { _renderOptions } from '../../../../helper/_renderOptions';
import { _clientReports } from '../../../../helper/_clientReports';
import { _findInAOO } from '../../../../helper/_findInAOO';
import { _normStr } from '../../../../helper/_normStr';

const ConfigureUserModal = ({ row, ...props }) => {
  const { id } = row;

  const defaultFields = {
    usersId: 0,
    roles: [],
    clients: [],
    reports: [],
  };

  // useStates
  const [userConfig, setUserConfig] = useState(defaultFields);
  const [alreadyConfig, setAlreadyConfig] = useState(defaultFields);
  const [client_Reports, setClient_Reports] = useState([]);
  const [hasAmpTeam, setAmpTeam] = useState(false);
  const [ampT_clnts, setAmpT_clnts] = useState(false);

  const clients_DD_ref = useRef(null),
    reports_DD_ref = useRef(null);

  const handleDropDownState = (list, { name }) => {
    setUserConfig(state => ({ ...state, [name]: list }));
  };

  useEffect(() => {
    (async _ => {
      try {
        if (props.show) {
          const { data } = (await getConfigById(id)) || {};

          setAlreadyConfig(data);

          if (data.length > 0) {
            const firstElement = data[0];

            const roles = _renderOptions(
                firstElement?.roles,
                'rolename',
                'roleid'
              ),
              clients = _renderOptions(
                firstElement?.clients,
                'rolename',
                'clientId'
              ),
              reports = _renderOptions(
                firstElement?.reports,
                'rpeortName',
                'reportId'
              );

            setUserConfig({ usersId: id, roles, clients, reports });
          } else {
            setUserConfig(state => ({ ...state, usersId: id }));
          }

          // reset ampliFi team role
          if (hasAmpTeam) {
            setAmpT_clnts(false);
            setAmpTeam(false);
          }
        } else {
          setUserConfig(defaultFields);
        }
      } catch (e) {
        toastError(e.message);
      }
    })();
  }, [props.show]);

  const handleCancelUserConfig = () => {
    const { handleClose } = props;
    handleClose();
  };

  const validateFields = ({ roles, client, reports }) => {
    if (roles?.length === 0 || client?.length === 0 || reports?.length === 0) {
      return 'One of the fields is empty';
    }

    // validated
    return false;
  };

  const handleConfigUser = () => {
    const validate = validateFields(userConfig);
    if (!validate) {
      (async _ => {
        try {
          if (alreadyConfig.length === 0) {
            await createUserConfig(userConfig);
            toastSuccess('User Configuration saved');
          } else {
            await updateUserConfig(userConfig);
            toastSuccess('User Configuration updated');
          }

          // close modal
          handleCancelUserConfig();
        } catch (e) {
          toastError(`${e.message}`);
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const FooterComp = () => (
    <div className="d-flex justify-content-between">
      <ModalButt
        text="Cancel"
        className="grayButn"
        onClick={handleCancelUserConfig}
      />
      <ModalButt text="Save" className="greenButn" onClick={handleConfigUser} />
    </div>
  );

  const dispach = useDispatch();
  const { roles, clients, reports } = useSelector(state => state);

  // fetch all dropdown data
  useEffect(() => {
    if (props.show) {
      if (reports.data.length === 0) {
        dispach(
          reportActions.fetchAllReports({ pageNo: '1', pageSize: '1000' })
        );
      }
      if (clients.data.length === 0) {
        dispach(
          clientActions.fetchAllclients({ pageNo: '1', pageSize: '1000' })
        );
      }
    }
  }, [props.show]);

  const getRoles = _ => {
    if (roles.data.length === 0) {
      dispach(rolesActions.fetchAllroles({ pageNo: '1', pageSize: '1000' }));
    }
  };

  // client specific reports
  useEffect(() => {
    if (userConfig.clients.length > 0) {
      const client_reports = _clientReports(userConfig.clients, reports.data);
      setClient_Reports(client_reports);

      // selected values
      if (userConfig.reports.length > 0) {
        const maintain_reports = [];
        userConfig.reports.forEach(i => {
          for (const j of client_reports) {
            if (_normStr(i.label) === _normStr(j.name)) {
              maintain_reports.push(i);
              break;
            }
          }
        });
        handleDropDownState(maintain_reports, { name: 'reports' });
      }
    } else {
      setClient_Reports([]);
      handleDropDownState([], { name: 'reports' });
    }
  }, [userConfig.clients]);

  // check ampliFi team role & select all clients
  useEffect(() => {
    if (userConfig.roles.length > 0) {
      const isFound = _findInAOO(userConfig.roles, 'value', 'amplifi team');

      if (isFound && !hasAmpTeam) {
        clients_DD_ref.current.select_amliFi_team();
        setAmpT_clnts(true);
      } else if (!isFound && hasAmpTeam) {
        setAmpT_clnts(false);
        setAmpTeam(false);
      }
    } else {
      setAmpT_clnts(false);
      setAmpTeam(false);
    }
  }, [userConfig.roles]);

  // select reports for ampliFi team member
  useEffect(() => {
    if (ampT_clnts && client_Reports.length > 0 && !hasAmpTeam) {
      reports_DD_ref.current.select_amliFi_team();
      setAmpTeam(true);
    }
  }, [client_Reports, ampT_clnts]);

  return (
    <RightModal
      {...props}
      backdrop="static"
      fullHeight
      headerTxt="User Configuration"
      FooterContnt={FooterComp}
    >
      <form>
        <ModalInpF
          type="text"
          title="Username"
          name="userName"
          value={row.userName}
          disabled
        />

        <DropDown
          title="Role"
          req
          name="roles"
          value={userConfig.roles}
          onChange={handleDropDownState}
          onMenuOpen={getRoles}
          data={roles.data}
          isLoading={roles.loading}
          isMulti
          maxMenuHeight={120}
        />

        <DropDown
          ref={clients_DD_ref}
          title="Client"
          req
          name="clients"
          value={userConfig.clients}
          onChange={handleDropDownState}
          data={clients.data}
          isLoading={clients.loading}
          isMulti
          maxMenuHeight={120}
        />

        <DropDown
          ref={reports_DD_ref}
          title="Report"
          req
          name="reports"
          value={userConfig.reports}
          onChange={handleDropDownState}
          data={client_Reports}
          isLoading={reports.loading}
          isMulti
          maxMenuHeight={120}
          noOptionsMessage={() =>
            userConfig.clients.length === 0
              ? 'please select client first'
              : 'no reports'
          }
        />
      </form>
    </RightModal>
  );
};

export default ConfigureUserModal;
