import validator from 'validator';

const validatePhoneNumber = number => {
  const phoneNumberPattern = /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/;
  return phoneNumberPattern.test(number.slice(3));
};

export const validateFields = ({
  userName,
  firstName,
  lastName,
  email,
  userStatus,
  mobileNumber,
}) => {
  if (!validator.isEmail(userName)) {
    return 'Please enter valid username';
  } else if (validator.isEmpty(firstName)) {
    return 'Please enter first name';
  } else if (validator.isEmpty(lastName)) {
    return 'Please enter last name';
  } else if (!validator.isEmail(email)) {
    return 'Please enter valid email address';
  } else if (validator.isEmpty(userStatus)) {
    return 'Please select user status';
  } else if (validator.isEmpty(mobileNumber)) {
    return 'Please enter mobile number';
  } else if (!validatePhoneNumber(mobileNumber)) {
    return 'Please enter valid mobile number';
  }
  // validated
  return false;
};

export const validateLength = (field, value, maxLength) => {
  const fLength = field.length,
    vLength = value.length;

  return vLength === 1
    ? fLength + 1 <= maxLength
      ? true
      : false
    : vLength <= maxLength
    ? true
    : false;
};
