import { useState } from 'react';
import userIcon from '../../assets/img/profile.png';
import { FormInput, AuthButton, FormContainer } from '../../components';

// api
import { forgetPassword } from '../../api/users/forgetPassword';
import { SendEmail } from '../../api/users/sendEmail';

// toast
import { toastError, toastSuccess } from '../../utils/toast';

import { _onKeyPress } from '../../helper/_onKeyPress';

// email template
import { forgetPasswordEmail } from '../../extra/emailTemplates/forgetPassword';

import { useNavigate } from 'react-router-dom';

export default function ForgetPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSend = () => {
    (async _ => {
      try {
        setLoading(true);

        const res = await forgetPassword(email);
        if (res?.error) {
          toastError('Something went wrong with email');
          setLoading(false);
          return;
        }
        const { username, temporaryPassword, userOTP } = res.data || {};

        // change password url
        const mailUrl = `${process.env.REACT_APP_FRONT_END}/auth/changepassword?UserName=${username}&tempPass=${temporaryPassword}`;

        const mailTemplate = forgetPasswordEmail(
          username,
          mailUrl,
          temporaryPassword
        );

        await SendEmail(email, mailTemplate, 'Password Reset');

        toastSuccess('Please check your email for password reset instructions');
        setLoading(false);
      } catch (e) {
        toastError(e.message);
        setLoading(false);
      }
    })();
  };

  return (
    <FormContainer
      showBackToLogin
      heading="Forgot Your Password?"
      descp="Enter your email below to recieve password reset instructions"
    >
      <form>
        <FormInput
          label="Enter Your Email"
          img={userIcon}
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          onKeyDown={e => _onKeyPress(e, handleSend)}
          placeholder="Type your email"
          disabled={isLoading}
        />
        <AuthButton
          onClick={handleSend}
          disabled={isLoading}
          loading={isLoading}
        >
          Send
        </AuthButton>
      </form>
    </FormContainer>
  );
}
