export const _setCookie = (
  cookieName,
  cookieValue,
  cookieExpire,
  cookiePath
) => {
  document.cookie = `${cookieName}=${cookieValue}; expires=${cookieExpire}; path=${cookiePath}`;
};

export const _getCookie = cookieName => {
  const name = `${cookieName}=`;
  // const cDecoded = decodeURIComponent(document.cookie);
  const cookie = document.cookie;
  const cArr = cookie.split('; ');
  let res = null;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};
