import "./styles.css";

export default function MainButton({ buttIcon, ...props }) {
  return (
    <button type="button" className="mainButn" {...props}>
      {buttIcon && <img src={buttIcon} alt="buttIcon" />}
      {props.children}
    </button>
  );
}
