import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DropDown from '../modalComp/dropDown';
import TabBottomSection from './TabBottomSection';

import { OptionBox, /** ModalInputF, */ DatePicker } from '../../components';

import { _thisWeek, _thisMonth, _yearToDate, _formatDate } from '../../helper/_date';

// api
import { getConnectors } from '../../api/udm/getConnectors';
import { getClinetByConnector } from '../../api/udm/getClientsByConnector';
import { initiateHistory } from '../../api/udm/getInitiateHistory';

// utils
import { toastError } from '../../utils/toast';
import { _getDataMoveId } from '../../helper/_getDataMoveId';

const History = () => {
  const dataMoves = useSelector(state => state.dataMoves);

  // useStates
  const [Connertors, setConnertors] = useState({
    load: false,
    data: [],
  });
  const [Clients, setClients] = useState({
    load: false,
    data: [],
  });
  const [HistoryData, setHistoryData] = useState({
    connector: [],
    clientByConnector: [],
  });
  const [historyMsg, setHistoryMsg] = useState('');

  const [historyState, setHistoryState] = useState({
    chunkSize: '1',
    datePeriod: '',
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateRangeLabel, setDateRangeLabel] = useState('');
  const [startInitiate, setInitiate] = useState(false);

  const historyDateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    const getDateRange = range => {
      switch (range) {
        case 'This week':
          return _thisWeek(historyDateFormat);
        case 'This month':
          return _thisMonth(historyDateFormat);
        case 'Year to date':
          return _yearToDate(historyDateFormat);
        default:
          return [];
      }
    };

    const dateRange = getDateRange(historyState.datePeriod);
    if (dateRange.length === 2) setDateRangeLabel(dateRange);
    else setDateRangeLabel('');
  }, [historyState.datePeriod]);

  // load clients according to connector
  useEffect(() => {
    if (HistoryData.connector.length > 0) {
      const conn_Name = HistoryData.connector[0]?.value;
      (async _ => {
        try {
          setClients({ data: [], load: true });

          //  reset select clients
          setHistoryData(state => ({ ...state, clientByConnector: [] }));

          const res = await getClinetByConnector(conn_Name);
          setClients({ data: res?.data?.clientName, load: false });
        } catch (e) {
          setClients(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  }, [HistoryData.connector]);

  useEffect(() => {
    let hisytoryMsgTimeout;
    if (!!historyMsg) {
      hisytoryMsgTimeout = setTimeout(() => setHistoryMsg(''), 10000);
    }
    return () => {
      clearTimeout(hisytoryMsgTimeout);
    };
  }, [historyMsg]);

  const handleStateChange = e => {
    const { name, value } = e.target;
    setHistoryState(state => ({ ...state, [name]: value }));
  };

  const handleDropDownState = (list, { name }) => {
    setHistoryData(state => ({ ...state, [name]: [list] }));
  };

  const getHistoryConnectors = _ => {
    if (Connertors.data.length === 0) {
      (async _ => {
        try {
          setConnertors(s => ({ ...s, load: true }));
          const res = await getConnectors();
          setConnertors({ data: res?.data?.connectors, load: false });
        } catch (e) {
          setConnertors(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  };

  const getSelectedDate = () => {
    const { datePeriod } = historyState;
    return datePeriod !== 'Custom range'
      ? dateRangeLabel === ''
        ? null
        : dateRangeLabel
      : dateRange.join('') === ''
      ? null
      : [
          _formatDate(dateRange[0], historyDateFormat),
          _formatDate(dateRange[1], historyDateFormat),
        ];
  };

  const handleHistoryStart = () => {
    const selectedDate = getSelectedDate();
    if (
      HistoryData.connector.length > 0 &&
      HistoryData.clientByConnector.length > 0 &&
      selectedDate
    ) {
      const selectedConnector = HistoryData.connector[0]?.value,
        selectedClient = HistoryData.clientByConnector[0]?.value;

      if (dataMoves?.data && dataMoves?.data.length > 0) {
        const rowObj = _getDataMoveId(
          dataMoves?.data,
          'systemname',
          selectedConnector,
          'companyshortname',
          selectedClient
        );
        if (rowObj !== -1) {
          (async _ => {
            try {
              setInitiate(true);
              setHistoryMsg('Checking Process Status');
              const res = await initiateHistory(rowObj?.datamoveid, selectedDate);
              setHistoryMsg(res?.data || 'History has been started');
              setInitiate(false);
            } catch (e) {
              setHistoryMsg(`History Failed`);
              toastError(e.message);
              setInitiate(false);
            }
          })();
        } else {
          toastError('Invalid DataMoveId');
        }
      }
    } else {
      toastError('Please select connectors, clients & dateRange');
    }
  };

  const handleCancel = () => {
    setHistoryMsg('');
    setHistoryData({
      connector: [],
      clientByConnector: [],
    });
    setDateRange([null, null]);
    setHistoryState({
      chunkSize: '1',
      datePeriod: '',
    });
  };

  const commonStyles = {
    margin: 0,
    padding: 0,
  };

  return (
    <>
      <form className="row g-3">
        <div className="col-md-4 col-lg-3">
          <DropDown
            title="Select Connector"
            isSelectAll={false}
            style={commonStyles}
            name="connector"
            value={HistoryData.connector}
            onChange={handleDropDownState}
            onMenuOpen={getHistoryConnectors}
            data={Connertors.data}
            isLoading={Connertors.load}
            maxMenuHeight={150}
            noOptionsMessage={() => 'No connertors'}
          />
        </div>

        <div className="col-md-4 col-lg-3">
          <DropDown
            title="Select Client"
            isSelectAll={false}
            style={commonStyles}
            name="clientByConnector"
            value={HistoryData.clientByConnector}
            onChange={handleDropDownState}
            data={Clients.data}
            isLoading={Clients.load}
            maxMenuHeight={150}
            noOptionsMessage={() =>
              HistoryData.connector.length === 0 ? 'No connector selected' : 'No clients'
            }
          />
        </div>
        {/** 
         <div className="col-md-4 col-lg-3">
          <ModalInputF
            style={commonStyles}
            type="text"
            title="Chunk Size"
            name="chunkSize"
            value={historyState.chunkSize}
            onChange={handleStateChange}
            disabled
          />
        </div>
      */}

        <div className="col-md-4 col-lg-3 position-relative">
          <span className="dateRange_label">
            {Array.isArray(dateRangeLabel) && dateRangeLabel.join(' - ')}
          </span>
          <OptionBox
            style={commonStyles}
            title="Select Date Period"
            name="datePeriod"
            value={historyState.datePeriod}
            onChange={handleStateChange}
          >
            <option value="" disabled>
              Choose
            </option>
            <option>This week</option>
            <option>This month</option>
            <option>Year to date</option>
            <option>Custom range</option>
          </OptionBox>
        </div>
        <div className="col-md-4 col-lg-3">
          <DatePicker
            style={commonStyles}
            title="Other Date Period"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={update => setDateRange(update)}
            isClearable={true}
            placeholderText="Please select a date range"
            disabled={historyState.datePeriod !== 'Custom range'}
          />
        </div>
      </form>
      <TabBottomSection
        btnTxt="Start History"
        p1Txt="History Process"
        p2Txt={historyMsg}
        handleStart={handleHistoryStart}
        isStarted={startInitiate}
        handleClear={handleCancel}
      />
    </>
  );
};

export default History;
