import axios from '../api';
import { updateRle } from './endpoints';

export const EditRole = async (role, id) => {
  const userName = JSON.parse(localStorage.getItem('user')).username;

  const body = { ...role, modifiedBy: userName };

  const { data } = await axios.put(updateRle(id), body);
  return data;
};
