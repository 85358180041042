// get requests
export const fetchAllRoles = (pageNumber, pageSize) =>
  `/Roles/GetAllRoles?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const fetchbyId = id => `/Roles/GetById?id=${id}`;
export const searchAll = (searchTxt, pageNumber, pageSize) =>
  `Roles/SearchAll?searchText=${searchTxt}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

// post request
export const createRle = `/Roles/CreateRole`;

// put request
export const updateRle = id => `/Roles/${id}`;

// delete request
export const delRole = id => `/Roles/${id}`;
