import React, { useEffect, useState } from 'react';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';

import editIcon from '../../assets/img/edit_gray.png';
import save from '../../assets/img/save_small.png';
import deleteIcon from '../../assets/img/delete_gray.png';

export const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
  const editing = rowData.status === 'EDIT' && rowData[dataKey];
  const [editImg, setEditImg] = useState(editIcon);
  const [checkState,setCheckState] = useState(false)
  const status = rowData.status;

  useEffect(() => {
   if(checkState!== false){
    clickEvent()
    console.log(editing);
   }
  
    return () => {
      
    }
  }, [checkState])
  
  const clickEvent = () => {
    onClick && onClick(rowData.id);
    editing ? setEditImg(editIcon) : setEditImg(save)
    //editImg === editIcon ? setEditImg(save) : setEditImg(editIcon);
    console.log({rowData},{status}, {onclick})

    // if(editImg === editIcon){
    //   setEditImg(save)

    // }

  };

  
  return (
   
    <Cell {...props} className="link-group">
     { 
     editing ? <img src={save} alt="save" onClick={clickEvent}/> : <img src={editIcon} alt="edit" onClick={clickEvent}
       />}
      {/* <img src={deleteIcon} alt="eIcon" onClick={clickEvent} /> */}
    </Cell>
  );
};
