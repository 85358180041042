import { Loader } from '../../../components';

import { NavLink, useSearchParams } from 'react-router-dom';

import NavToolTip from './NavToolTip';

const NavDropDown = ({ routes, isLoading, noRouteMsg, toolTip, pathname }) => {
  const [searchParams] = useSearchParams(),
    reportId = Number(searchParams.get('reportId'));

  const decideActive = (isActive, id) => {
    const d =
      pathname === 'reports' ? (id === reportId ? true : false) : isActive;

    //  classNames
    return `sub_itm_txt ${d ? 'drpDn_active_itm' : ''}`;
  };

  const DropDownItem = ({ id = -100, txt, link, toolTipTxt }) => {
    return (
      <span className="drpDn_Item">
        {toolTip ? (
          <NavToolTip txt={toolTipTxt}>
            <NavLink to={link} className={a => decideActive(a.isActive, id)}>
              {txt}
            </NavLink>
          </NavToolTip>
        ) : (
          <NavLink to={link} className={a => decideActive(a.isActive, id)}>
            {txt}
          </NavLink>
        )}
      </span>
    );
  };

  return (
    <section className="navBar_DropDown">
      <div className="drpDn_subItm_Cont">
        {isLoading ? (
          <Loader size="18" />
        ) : routes.length > 0 ? (
          routes.map((i, index) => (
            <DropDownItem
              key={index}
              id={i?.id}
              txt={i?.label}
              link={i?.link}
              toolTipTxt={i?.toolTipTxt}
            />
          ))
        ) : (
          <p className="no_dd_data">{noRouteMsg}</p>
        )}
      </div>
    </section>
  );
};

export default NavDropDown;
