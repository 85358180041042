// get requests
export const getAllReports = (pageNum, pageSize) =>
  `/Reports/GetAllReports?PageNumber=${pageNum}&PageSize=${pageSize}`;

export const getReportById = id => `/Reports/GetById?id=${id}`;

export const getAllReportsById = userId =>
  `/UserConfiguration/GetUserReportsDetail?userId=${userId}`;

// post requests
export const crtReport = `/Reports/CreateReport`;

// put reuests
export const updateReport = id => `/Reports/${id}`;

// delete requests
export const deltReport = id => `/Reports/${id}`;
