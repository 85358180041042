// assets
import deleteIcon from '../../assets/img/delete_gray.png';
import editIcon from '../../assets/img/edit_gray.png';

// find in roles
import { _findRoles } from '../../helper/_findRoles';

const config = (editActionsHandle, deleteActionsHandle) => {
  const isSuperAdmin = _findRoles('admin') || _findRoles('super admin');

  // congifure table option here
  const tableColumns = [
    {
      dataField: 'action',
      text: 'Action',
      // align: "center",
      // headerAlign: (column, colIndex) => "center",
      searchable: false,
      csvExport: false,
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span className="TbleActnIcns">
            <img
              src={editIcon}
              alt="eIcon"
              onClick={() => editActionsHandle(row)}
            />
            {isSuperAdmin && (
              <img
                src={deleteIcon}
                alt="dIcon"
                onClick={() => deleteActionsHandle(row)}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'name',
      text: 'Report Name',
      sort: true,
    },
    {
      dataField: 'page',
      text: 'Workspace Name',
      sort: true,
    },
    // {
    //   dataField: 'embedURL',
    //   text: 'Report Embed URL',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, extraData) => {
    //     if (cell) return cell;
    //     return 'N/A';
    //   },
    // },
    {
      dataField: 'statusNotification',
      text: 'Notification',
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => {
        if (cell) return cell;
        return 'N/A';
      },
    },
    {
      dataField: 'embedWorkspaceID',
      text: 'Embed Workspace ID',
      sort: true,
    },
    {
      dataField: 'embedID',
      text: 'Embed Report ID',
      sort: true,
    },
  ];

  return tableColumns;
};

export default config;
