import { call, put } from 'redux-saga/effects';
import { getDataMoves } from '../../../api/udm/getDataMoves';
import * as type from '../../types';

export default function* dataMovesSaga(action) {
  try {
    const res = yield call(_ => getDataMoves());
    yield put({ type: type.FETCH_DATAMOVES_SUCCESS, response: res.data });
  } catch (e) {
    yield put({ type: type.FETCH_DATAMOVES_FAILED, error: e.response });
  }
}
