// siginIn
export const SIGNIN_REQUESTED = 'SIGNIN_REQUESTED';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';

// fetch all users
export const FETCH_USERS_REQUESTED = 'FETCH_USERS_REQUESTED';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

// fetch all roles
export const FETCH_ROLES_REQUESTED = 'FETCH_ROLES_REQUESTED';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

// fetch all clients
export const FETCH_CLIENTS_REQUESTED = 'FETCH_CLIENTS_REQUESTED';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILED = 'FETCH_CLIENTS_FAILED';

// fetch all reports
export const FETCH_REPORTS_REQUESTED = 'FETCH_REPORTS_REQUESTED';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILED = 'FETCH_REPORTS_FAILED';

// get DataMoves
export const FETCH_DATAMOVES_REQUESTED = 'FETCH_DATAMOVES_REQUESTED';
export const FETCH_DATAMOVES_SUCCESS = 'FETCH_DATAMOVES_SUCCESS';
export const FETCH_DATAMOVES_FAILED = 'FETCH_DATAMOVES_FAILED';
