import axios from '../api';
import { CreateLevel1, CreateLevel2, CreateLevel3 } from './endpoints';




export const newLevel1 =async (ClientsName,account,reportType,tab,Parent,Level1)=> {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.post(CreateLevel1(ClientsName,account,reportType,tab,Parent,Level1,userName));
  return res;
};
export const newLevel2 =async (ClientsName,account,reportType,tab,Parent,Level2) => {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.post(CreateLevel2(ClientsName,account,reportType,tab,Parent,Level2,userName));
  return res;
};
export const newLevel3 =async(ClientsName,account,reportType,tab,Parent,Level3) => {
const userName = JSON.parse(localStorage.getItem('user')).username;

  const res = await axios.post(CreateLevel3(ClientsName,account,reportType,tab,Parent,Level3,userName));
  return res;
};
