import React from 'react'
import ModelPage from '../../../pages/xReferencePages/modelPage'
import ModelTable from '../../../pages/xReferencePages/modelTable'

const CFContent = ({ handleTabChange,reportTypeName,clientName,LookUpTableName }) => {
  return (
    <>
    <ModelTable tabs="cf" 
    reportTypeName ={reportTypeName}
    clientName={clientName}
    LookUpTableName={LookUpTableName} />
    </>
  )
}

export default CFContent

