import axios from '../api';
import { updateUserById } from './endpoints';

export const updateUser = async (user, userId) => {
  const userStatus = user.userStatus === 'Active' ? true : false;

  const userName = JSON.parse(localStorage.getItem('user')).username;

  const body = { ...user, userStatus,modifiedBy: userName };

  const response = await axios.put(updateUserById(userId), body);
  return response;
};
