import axios from '../api';
import { XrefEmailTrigger } from '../xReference/endpoints';
import { sendEmail } from './endpoints';

const userEmail = "thamini.s@sensiple.com";

export const SendXrefEmail = async ( mailtemplate, subject) => {
  const mailScehma = {
    from: 'no-reply-customerportal@amplificap.com',
    to: userEmail,
    subject: subject,
    htmlBody: mailtemplate,
    textBody: '45345 OTP',
    smtP_Address: 'smtp.office365.com',
    port: 587,
    userName: 'no-reply-customerportal@amplificap.com',
    password: 'Muj45788',
    emailStatus: '',
  };

  const body = mailScehma;

  const { data } = await axios.post(XrefEmailTrigger, body);
  return data;
console.warn({body})
};
