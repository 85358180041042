import React from 'react'
import ModelTable from '../../../pages/xReferencePages/modelTable'

const BSContent = ({ handleTabChange,reportTypeName,clientName,LookUpTableName }) => {
  return (
    <div>
      <ModelTable tabs="bs"
      reportTypeName ={reportTypeName}
      clientName={clientName}
      LookUpTableName={LookUpTableName} />
    </div>
  )
}

export default BSContent