//import "rsuite-table/dist/css/rsuite-table.css";
import Table from 'react-bootstrap/Table';
//import './TableStyles.css'
import './xrefStyles.css';

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { MainButton } from '../../components';
import DropDown from '../../components/modalComp/dropDown';
import { getReportType } from '../../api/xReference/getReportType';
import first from 'lodash/first';
import { fetchReportType } from '../../api/xReference/endpoints';
import { axiosRequestInterceptor } from '../../api/Interceptors';
import { getItemCategorizationData } from '../../api/xReference/getItemCategorization';
import { getLevel1ByReportType } from '../../api/xReference/getLevel1ByReportType';
import { getReportByLookup } from '../../api/xReference/getReportByLookup';
import { toastError, toastSuccess } from '../../utils/toast';
import axios from 'axios';
import { updateDefaultModel, updateDefaultShortform, updateDefaultSOT } from '../../api/xReference/updateLevelsData';
import { XrefMailContent } from '../../extra/emailTemplates/xrefNewInfoNotify';
import { SendXrefEmail } from '../../api/users/xRefMail';
import { useParams } from 'react-router-dom';




const colorStyles = {
  option: styles => {
    return {
      ...styles,
      backgroundColor: 'white',
      color: 'black',
    };
  },
  width:"100px"

};



const ItemTable = ({clientName,LookUpTableName}) => {
  const data1 = [
    {
      id: 1,
      MasterAccount: '40050 Sales Income - All Others Wholesale',
      Report: 'P&L',
      // Model: ['Gross Revenue', 'Promotions and Discounts', 'Material Cost', 'Payroll'],
      // ShortFormLv1: 'Gross Revenue',
      Default: 'SOT',
      Model: 'Model',
      Shortform: 'shortform',
      SOT:'ShortForm',
      Momo:'sot'

      
    },
    {
      id: 2,
      MasterAccount: '40100 Sales Income - E-Commerce & Retail',
      Report: 'P&L',
      Model: ['Payroll', 'Direct Labour'],
      ShortFormLv1: 'Gross Revenue',
      Default: 'Model',
      DefaultModel: 'SOT',
      Model: 'SOT',
      Shortform: 'SOT',
      SOT:'ShortForm',
      Momo:'Model'


    },
    {
      id: 3,
      MasterAccount: '40200 Sales Income - Lotus Light',
      Report: 'P&L',
      Model: ['Promotions and Discounts', 'Material Cost'],
      ShortFormLv1: 'Gross Revenue',
      Default: 'SOT',
      DefaultModel: 'SOT',
      Model: 'Model',
      Shortform: 'SOT',
      SOT:'ShortForm',
      Momo:'SOT'


    },
    {
      id: 4,
      MasterAccount: '41000 Sales Discounts',
      Report: 'P&L',
      Model: ['Payroll', 'hi'],
      ShortFormLv1: 'Promotions and Discounts',
      Default: 'Model',
      DefaultModel: 'SOT',
      Model: 'SOT',
      Shortform: 'SOT',
      SOT:'ShortForm',
      Momo:'Model'

    },
    {
      id: 5,
      MasterAccount: '41000 Sales Discounts',
      Report: 'Promotions and Discounts',
      Model: ['Gross Revenue', 'Payroll'],
      ShortFormLv1: 'Promotions and Discount',
      Default: 'Model',
      DefaultModel: 'SOT',
      Momo:'Model',
      Model: 'SOT',
      Shortform: 'Model',
      SOT:'ShortForm',

    },
  ];
  
  // const options = [
  //   { value: 'model', label: 'Model' },
  //   { value: 'sot', label: 'SOT' },
  //   { value: 'shortform', label: 'Shortform' },
  // ];
const params = useParams();
const clientParams = params.clientName;
const LookupParams = params.accountType;
const reportParams = params.reportType;
const reportparamsdemo = "Shortform";
  const client = clientParams;
  const str= String(LookupParams);
  const lookUp = str.replace(/\s/g, '');
  // const client = clientName;
  // const str= String(LookUpTableName);
  // const lookUp = str.replace(/\s/g, '');

  const [data, setData] = useState([]);
  const [value, setValue] = useState(reportparamsdemo);
  const [itemdata,setItemData] = useState(data);
  const [cell, setCell] = useState('');
  const [getInfo, setGetInfo] = useState([]);
  const [repList, setRepList] = useState([]);
  const [repType, setRepType] = useState([]);
  const [selOpt, setSelOpt] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  // const [defOption,setDefOption] = useState({
  //   value:'Modell',
  //   label:'Model'
  // })
  const momo ='Momo';
  const value11= value.toLowerCase();

  useEffect(()=>{
    getData();
    getReportList();
  },[])

  useEffect(() => {
     
    levelOneData();
  }, [value]);

  const getData = async _=>{
    try
    {const res = await getItemCategorizationData(client,lookUp)
      console.log({res})
      setData(res?.data)
    }
    catch(e){
      console.log(e)
    }
  }

  const getReportList =async _=> {
    try{
      
    const res = await  getReportByLookup(lookUp);
    setRepList(res.data);


  //  const res2= axios.get(fetchReportType)
    
    // console.log(res.data);
    // console.log(res2);
    // console.log('replist', repList[0].name);
    
    // setDefOption(repList)
    // console.log(defOption)
    // getDefaultValue();
    // const testdata= [...data,res.data]
    // console.log({testdata})
  }
  catch(e){
    console.log(e)
  }

  };

  const levelOneData = async _=>{
  try { const res = await getLevel1ByReportType(client,lookUp,value);
    // console.log(res.data);
    setRepType(res.data)
    console.log(res);}
    catch(e){
      console.log(e)
    }
  }
  const handleSelect = e => {
    console.log(e);
    setValue(e);
    console.log('data', data.power);
  };
  const handleSelectValue = e => {
    console.log(e);
    setCell(e);
    console.log('data', e);
  };
  const display = e => {
    return e;
  };

  const handleSelectChange =  selectedVal => {
    console.log(selectedVal);
  
    setValue(selectedVal.value);
    setRepType([])
    
    
   
    
  };

  const handleOptionChange = selectedVal => {
    setSelOpt(selectedVal.value);
    console.log({ selOpt });
  };

  const options = repList.map(item => {
    return {
      label: item.reporttypeName,
      value: item.reporttypeName,
    };
  });

  const getDefaultValue = () => {
    const getrModel =  repList[0]
    const first = "Model";
    console.log(getrModel, 'first');
    // const name = first.name
    return {
      label: first,
      value: first,
    };
  };
  // const getDefaultValue= ()=>{
  //   const index = repList.findIndex(object => {
  //     return object.value === "Model";
  //   });
  //   console.warn(index)
  //   return options[index]
  // }
  const handleRowClick = e => {
    console.log(e);
    setSelectedRow(e);
  };

  const handleChange = e => {
    setCell(e.target.value);
    console.log(value, setCell);
  };

  // const defaultValue = reportList[0]
  // console.log(defaultValue.name)

  const updateSelection = async() => {
    // alert(selectedRow.id, ' ', value, ' ', selOpt);
    const id = selectedRow.id;
    console.warn({ selectedRow, value, selOpt });
    if (value === "Model")
    {
      console.log({value})
      const res = await updateDefaultModel(id,cell)
    
      const mailTemplate = XrefMailContent( id, cell);
      SendXrefEmail( mailTemplate, 'updated');
    if (res.status == 200){
      toastSuccess("updated successfully")
      toastSuccess("mail sent successfully")
    }
    else
    toastError(res.error)
  }

    else if (value === "Shortform")
    {
      const res = await updateDefaultShortform(id,cell)
    
      const mailTemplate = XrefMailContent( id, cell);
      SendXrefEmail( mailTemplate, 'updated');
    if (res.status == 200){
      toastSuccess("updated successfully")
      toastSuccess("mail sent successfully")
      
    }
    else
    toastError(res.error)}
    else if (value === "Sot")
    {
      const res = await updateDefaultSOT(id,cell)
    
      const mailTemplate = XrefMailContent( id, cell);
      SendXrefEmail( mailTemplate, 'updated');
    if (res.status == 200){
      toastSuccess("updated successfully")
      toastSuccess("mail sent successfully")

    }
    else
    toastError(res.error)}
  };

  // const upkey ={
  //   return 
  // }

  return (
    <>
     
      {/* {console.log('replist', repList[0].name)} */}
      <MainButton style={{ float: 'right', backgroundColor: 'green' }} onClick={updateSelection}>
        
        Save
      </MainButton>
      <br />.
      <Table size="bordered-sm" style={{ color: 'black', border: '1px' }} className="itemTable">
        {console.log({ cell })}
        <thead className="dark" style={{ backgroundColor: 'black', color: 'white' }}>
          <tr>
            <th width="100px">id</th>
            <th width="450px">Master Account</th>
            <th width="200px" >Report</th>
            <th >
              select mapping
              <br />
              
              <Select
                value={options.name}
                options={options}
                // defaultValue={getDefaultValue}
                placeholder={reportparamsdemo}
                onChange={handleSelectChange}
                styles={colorStyles}
                // className="selectList"
                menuPlacement="auto"
      menuPosition="fixed"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((items, i) => (
            <tr
              key={i}
              onClick={() => {
                handleRowClick(items);
              }}
            >
              <td style={{ padding: '20px' }} key={items.id}>
                {items.id}
              </td>
              <td key={items.account}>{items.account}</td>
              <td key={items.reportName}>{items.reportName}</td>
             
              {
              repType.length && value !='' ? 
           
              <td >
              <select  
              className='selectList'
              defaultValue={items[value11]}
              onChange={handleChange}
              id={items.id}
              key={i}
              >
                     {repType.map((i,key) => {
                       return <option value={i.level1}  key={key} >  {i.level1}</option>;
                     })}
                </select>
                 </td> 
                 
              : null
                }

            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ItemTable;
