import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { rolesActions } from '../../../../store/actions';

// modal
import CenterModal from '../../base/centerModal';

import { DeleteRole } from '../../../../api/roles/deleteRole';

// assets
import deleteIcon from '../../../../assets/img/delete_popup.png';
import successIcon from '../../../../assets/img/success.png';
import notAllowedIcon from '../../../../assets/img/not_allowed.png';

import { toastError } from '../../../../utils/toast';

// components
import ModalButt from '../../../modalComp/modalButtons';

// api
import { rolesById } from '../../../../api/userConfig/rolesById';

const DeleteRoleModal = ({
  row,
  delMsg = 'You want to delete the selected role',
  ...props
}) => {
  const { handleClose } = props;

  const dispach = useDispatch();

  // states
  const [delCntnt, setDelCntnt] = useState({
    isAllowed: false,
    msg: '',
    icon: '',
  });

  const handleDeleteUser = async () => {
    (async _ => {
      try {
        const { data } = (await rolesById(row?.id)) || {};

        if (data.length === 0) {
          await DeleteRole(row.id);

          setDelCntnt({
            isAllowed: true,
            msg: 'Role Deleted SuccessFully',
            icon: successIcon,
          });

          // fetch all roles
          dispach(
            rolesActions.fetchAllroles({ pageNo: '1', pageSize: '1000' })
          );
        } else {
          setDelCntnt({
            isAllowed: true,
            msg: 'This role is already allocated',
            icon: notAllowedIcon,
          });
        }
      } catch (e) {
        toastError(e.message);
      }
    })();
  };

  const handleCloseConfrm = () => {
    setTimeout(() => setDelCntnt(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!delCntnt.isAllowed ? (
        <div className="d-flex justify-content-between">
          <ModalButt
            text="Close"
            className="grayButn"
            onClick={() => handleClose()}
          />
          <ModalButt
            text="Delete"
            className="redButn"
            onClick={handleDeleteUser}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseConfrm}
        />
      )}
    </>
  );

  const DeleteContent = ({ message, icon }) => (
    <section className="UserDeleteComp">
      <img src={icon} alt="sIcon" />
      <p>Delete</p>
      <span>{message}</span>
    </section>
  );

  return (
    <CenterModal {...props} backdrop="static" FooterContnt={FooterComp}>
      {/** user delete confirmation */}
      {!delCntnt.isAllowed ? (
        <DeleteContent message={delMsg} icon={deleteIcon} />
      ) : (
        <DeleteContent message={delCntnt.msg} icon={delCntnt.icon} />
      )}
    </CenterModal>
  );
};

export default DeleteRoleModal;
