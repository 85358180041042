import axios from '../api';
import { sendEmail } from './endpoints';

export const SendEmail = async (userEmail, mailtemplate, subject) => {
  const mailScehma = {
    from: 'no-reply-customerportal@amplificap.com',
    to: userEmail,
    subject: subject,
    htmlBody: mailtemplate,
    textBody: '45345 OTP',
    smtP_Address: 'smtp.office365.com',
    port: 587,
    userName: 'no-reply-customerportal@amplificap.com',
    password: 'Muj45788',
    emailStatus: '',
  };

  const body = mailScehma;

  const { data } = await axios.post(sendEmail, body);
  return data;
};
