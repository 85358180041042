import { useState } from 'react';
import otpIcon from '../../assets/img/otp.png';
import {
  AuthButton,
  RadioButt,
  FormInput,
  FormContainer,
} from '../../components';
import { Link } from 'react-router-dom';
import './styles.css';

export default function VerifyOtp() {
  const [OTP, setOTP] = useState('');
  const [validity, setValidity] = useState({
    _30days: false,
    _60days: false,
    _90days: false,
  });

  const handleValidyChange = e => {
    const { name } = e.target;
    setValidity({ ...checkClicked(name) });
  };

  const checkClicked = inp => {
    for (const key in validity) {
      if (key === inp) validity[key] = true;
      else validity[key] = false;
    }
    return validity;
  };

  return (
    <FormContainer
      heading="Reset Password?"
      descp="Enter OTP that recieved from your mail and verify to sign in"
    >
      <form>
        <FormInput
          label="Enter OTP"
          img={otpIcon}
          value={OTP}
          onChange={e => setOTP(e.target.value)}
          type="number"
          placeholder="Type your OTP"
        />

        <section className="validity">
          <label>OTP Validity Period</label>
          <div className="d-flex justify-content-between my-2 my-md-3 otpContWidth">
            <RadioButt
              label="30 days"
              type="radio"
              name="_30days"
              checked={validity._30days}
              onChange={handleValidyChange}
            />
            <RadioButt
              label="60 days"
              type="radio"
              name="_60days"
              checked={validity._60days}
              onChange={handleValidyChange}
            />
            <RadioButt
              label="90 days"
              type="radio"
              name="_90days"
              checked={validity._90days}
              onChange={handleValidyChange}
            />
          </div>
        </section>
        <Link to="/auth/resetpassword">
          <AuthButton>VERIFY OTP</AuthButton>
        </Link>
      </form>
    </FormContainer>
  );
}
