import { useState, useEffect } from 'react';
import { userActions } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import userIcon from '../../assets/img/profile.png';
import passwordIcon from '../../assets/img/password.png';
import {
  AuthButton,
  FormInput,
  CheckBox,
  FormContainer,
} from '../../components';
import { useNavigate } from 'react-router-dom';
import './styles.css';

import { toastError } from '../../utils/toast';
import { _onKeyPress } from '../../helper/_onKeyPress';
import { _setCookie, _getCookie } from '../../helper/_cookie';

export default function SignInPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(state => state.loginReducer);

  // useStats
  const [credential, setcredential] = useState({
    userName: '',
    password: '',
  });
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const isRemembered = JSON.parse(_getCookie('lastUser'));
    if (!!isRemembered) {
      setcredential(isRemembered);
    }
  }, []);

  useEffect(() => {
    if (error) {
      toastError(error);
    }
  }, [data, error]);

  const handleSignIn = () => {
    if (checked) {
      _setCookie(
        'lastUser',
        JSON.stringify(credential),
        '',
        window.location.href
      );
    }

    dispatch(userActions.signIn(credential));
  };

  const handleState = e => {
    const { name, value } = e.target;
    setcredential(state => ({ ...state, [name]: value }));
  };

  return (
    <FormContainer
      // subHeading="Sign In to"
      heading="Sign in to AmpliFi"
      // descp="one portal for all cutomer needs"
    >
      <form>
        <FormInput
          label="Username"
          img={userIcon}
          type="email"
          name="userName"
          value={credential.userName}
          onChange={handleState}
          placeholder="Type your name"
        />
        <FormInput
          label="Your Password"
          passwordF
          img={passwordIcon}
          name="password"
          value={credential.password}
          onChange={handleState}
          onKeyDown={e => _onKeyPress(e, handleSignIn)}
          disabled={loading}
          placeholder="Type your password"
        />
        <div className="d-flex justify-content-between my-2">
          <CheckBox
            label="Remember me"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <span
            onClick={() => navigate('/auth/forgetpassword')}
            className="forgetLable"
          >
            Forgot Password?
          </span>
        </div>
        <AuthButton onClick={handleSignIn} disabled={loading} loading={loading}>
          SIGN IN
        </AuthButton>
      </form>
    </FormContainer>
  );
}
