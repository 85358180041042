import { useState } from 'react';
import passwordIcon from '../../assets/img/password.png';
import { FormInput, AuthButton, FormContainer } from '../../components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ChangePassword } from '../../api/users/otpChangePassword';

import validator from 'validator';

import { toastError, toastSuccess } from '../../utils/toast';

import { _onKeyPress } from '../../helper/_onKeyPress';

export default function ChangePasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userName = searchParams.get('UserName'),
    tempPassword = searchParams.get('tempPass');

  const [password, setPassword] = useState({
    oldPassword: tempPassword,
    newPassword: '',
    confirmPassword: '',
  });
  const [isLoading, setLoading] = useState(false);

  const handlePasswordState = e => {
    const { name, value } = e.target;
    setPassword(state => ({ ...state, [name]: value }));
  };

  const validate = () => {
    if (validator.isEmpty(password.oldPassword)) {
      toastError('Please enter old password');
      return;
    } else if (validator.isEmpty(password.newPassword)) {
      toastError('Please enter new password');
      return;
    } else if (validator.isEmpty(password.confirmPassword)) {
      toastError('Please enter confirm password');
      return;
    } else if (password.confirmPassword !== password.newPassword) {
      toastError('Mismatch b/w new and confirm password');
      return;
    } else {
      handeChangePassword();
    }
  };

  const handeChangePassword = () => {
    try {
      setLoading(true);
      if (password.newPassword === password.confirmPassword) {
        (async _ => {
          const newUser = {
            username: userName,
            temporaryPassword: password.oldPassword,
          };
          const res = await ChangePassword(newUser, password.newPassword);
          if (res.error) {
            toastError('something went wrong with temporary password!');
          } else {
            toastSuccess(
              'Congratulations your password has been successfully changed!'
            );

            setLoading(false);

            setTimeout(() => {
              navigate('/auth/signin');
            }, 2000);
          }
        })();
      }
    } catch (error) {
      toastError('error.message');
      setLoading(false);
    }
  };

  return (
    <FormContainer
      heading="Change Password"
      descp={`Enter a new password that is atleast six character in length`}
    >
      <form>
        <FormInput
          label="Temporary Password"
          passwordF
          img={passwordIcon}
          name="oldPassword"
          value={password.oldPassword}
          onChange={handlePasswordState}
          placeholder="Type your current password"
        />
        <FormInput
          label="New Password"
          passwordF
          img={passwordIcon}
          name="newPassword"
          value={password.newPassword}
          onChange={handlePasswordState}
          placeholder="Type your new password"
        />
        <FormInput
          label="Confirm Password"
          passwordF
          img={passwordIcon}
          name="confirmPassword"
          value={password.confirmPassword}
          onChange={handlePasswordState}
          onKeyDown={e => _onKeyPress(e, validate)}
          placeholder="Type your confirm password"
          disabled={isLoading}
        />

        <AuthButton onClick={validate} disabled={isLoading} loading={isLoading}>
          CHANGE PASSWORD
        </AuthButton>
      </form>
    </FormContainer>
  );
}
