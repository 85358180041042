import { handleCellValue } from '../../utils/handleCellValue';
import { _formatDate } from '../../helper/_date';

const udmTableConfig = () => {
  // congifure table option here
  const tableColumns = [
    {
      dataField: 'datamoveid',
      text: 'Data Move Id',
      sort: true,
    },
    {
      dataField: 'datamovename',
      text: 'Data Move Name',
      sort: true,
      formatter: cell => handleCellValue(cell),
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      sort: true,
      formatter: cell => (cell ? _formatDate(cell, 'DD/MM/YYYY') : 'N/A'),
      searchable: false,
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      sort: true,
      formatter: cell => (cell ? _formatDate(cell, 'DD/MM/YYYY') : 'N/A'),
      searchable: false,
    },
    {
      dataField: 'process',
      text: 'Process',
      sort: true,
      formatter: cell => handleCellValue(cell),
      searchable: false,
    },
    {
      dataField: 'processStart',
      text: 'Process Start',
      sort: true,
      formatter: cell => (cell ? _formatDate(cell, 'DD/MM/YYYY') : 'N/A'),
      searchable: false,
    },
    {
      dataField: 'processEnd',
      text: 'Process End',
      sort: true,
      formatter: cell => (cell ? _formatDate(cell, 'DD/MM/YYYY') : 'N/A'),
      searchable: false,
    },
    {
      dataField: 'reasonforfail',
      text: 'Failure Reason',
      sort: true,
      formatter: cell => handleCellValue(cell),
      searchable: false,
    },
    {
      dataField: 'historyorsynch',
      text: 'history or Sync',
      sort: true,
      formatter: cell => handleCellValue(cell),
      searchable: false,
    },
  ];

  return tableColumns;
};

export default udmTableConfig;
