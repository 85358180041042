import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';
import DropDown from '../../../modalComp/dropDown';

// find in roles
import { _findRoles } from '../../../../helper/_findRoles';

// toast

import { toastError } from '../../../../utils/toast';

import { _sortAsc } from '../../../../helper/_sort';
import { fetchAllUsers } from '../../../../api/users/getAllUsers';
import { editEmailMapper } from '../../../../api/xReference/editEmailMapper';

const commonStyles = {
  margin: 0,
  padding: 0,
};

const EditNotifyMapperModal = ({ row, CloseReport, ...props }) => {
  const { id, name, ...fields } = row;
  const dispach = useDispatch();

  const [users, setUsers] = useState({
    load: false,
    data: [],
  });
  var mail1 = row.email1;
  var mail2 = row.email2;
  var mail3 = row.email3;

  const isSuperAdmin = _findRoles('super admin');

  const defaultFields = {
    name: '',
    mail1: '',
    mail2: '',
    mail3: '',
  };

  const [isReportEdited, setReportEdited] = useState(false);
  const [report, setReport] = useState(defaultFields);
  const [notifyClient, setnotifyClient] = useState({
    // Client: '',
    name: [],
    PrimaryEmail: [],
    SecondaryEmail: [],
    TertiaryMail: [],
  });

  // close the edit modal when close button is clicked
  useEffect(() => {
    const close = CloseReport;
    if (close == false) {
      handleCloseReport();
    }
  }, [CloseReport]);

  //get the mail of all users

  useEffect(() => {
    (async _ => {
      const res = await fetchAllUsers(1, 1000);

      const data = res?.data;
      const mappedData = data.map((k, i) => {
        return { name: k.email, id: i };
      });
      setUsers(state => ({ ...state, data: mappedData, load: false }));
    })();
  }, []);

  const handleDropDownState = (list, { name }) => {
    setnotifyClient(state => ({ ...state, [name]: [list] }));
    // setnotifyClient(state => ({ ...state, [name]: [list] }));
  };

  const emptyFields = () => {
    setReport(defaultFields);
    setnotifyClient({ name: [], PrimaryEmail: [], SecondaryEmail: [], TertiaryMail: [] });
  };
//update email
  const handleEditReport = () => {
    (async _ => {
      try {
        var email1 = notifyClient.PrimaryEmail[0]?.value ? notifyClient.PrimaryEmail[0].value : '';
        var email2 = notifyClient.SecondaryEmail[0]?.value
          ? notifyClient.SecondaryEmail[0].value
          : '';
        var email3 = notifyClient.TertiaryMail[0]?.value ? notifyClient.TertiaryMail[0].value : '';

        if (
          notifyClient.PrimaryEmail != '' ||
          notifyClient.SecondaryEmail != '' ||
          notifyClient.TertiaryMail != ''
        ) {
          if (email1 !== '' && email2 !== '' && email3 != '') {
            if (email1 !== email2 && email2 !== email3) {
              const res = await editEmailMapper(id, email1, email2, email3);

              setReportEdited(true);
              emptyFields();
            } else {
              toastError('emails must be unique');
            }
          } else if (email1 == '' && email2 !== '' && email3 != '') {
            if (email2 !== email3 && email2 !== mail1 && email3 !== mail1) {
              try {
                const res = await editEmailMapper(id, email1, email2, email3);
                setReportEdited(true);
                emptyFields();
              } catch (e) {
                toastError(e);
              }
            } else {
              toastError('emails must be unique');
            }
          } else if (email1 !== '' && email2 == '' && email3 != '') {
            if (email1 !== email3 && email1 !== mail2 && email3 !== mail2) {
              try {
                const res = await editEmailMapper(id, email1, email2, email3);
                setReportEdited(true);
                emptyFields();
              } catch (e) {
                toastError(e);
              }
            } else {
              toastError('emails must be unique');
            }
          } else if (email1 == '' && email2 == '' && email3 != '') {
            if (email3 !== mail2 && email3 !== mail1) {
              const res = await editEmailMapper(id, email1, email2, email3);

              setReportEdited(true);
              emptyFields();
            } else {
              toastError('emails must be unique');
            }
          } else if (email1 == '' && email2 !== '' && email3 == '') {
            if (email2 !== mail1 && email2 !== mail3) {
              const res = await editEmailMapper(id, email1, email2, email3);
              setReportEdited(true);
              emptyFields();
            } else {
              toastError('emails must be unique');
            }
          } else if (email1 !== '' && email2 == '' && email3 == '') {
            if (email1 !== mail2 && email1 !== mail3) {
              const res = await editEmailMapper(id, email1, email2, email3);
              setReportEdited(true);
              emptyFields();
            } else {
              toastError('emails must be unique');
            }
          } else if (email1 !== '' && email2 !== '' && email3 == '') {
            if (email1 !== email2 && email1 !== mail3 && email2 !== mail3) {
              const res = await editEmailMapper(id, email1, email2, email3);
              setReportEdited(true);
              emptyFields();
            } else {
              toastError('emails must be unique');
            }
          }
        }
      } catch (e) {
        toastError(`${e.message}`);
      }
    })();
  };
  //Clicking on close button
  const handleCloseReport = () => {
    const { handleClose } = props;

    setTimeout(() => setReportEdited(false), 200);
    handleClose();
  };
//UI  
  const FooterComp = () => (
    <>
      {!isReportEdited ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Reset" className="grayButn" onClick={emptyFields} />
          <ModalButt text="Update" className="greenButn" onClick={handleEditReport} />
        </div>
      ) : (
        <ModalButt text="Close" className="redButn" width="100%" onClick={handleCloseReport} />
      )}
    </>
  );

  const UserCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Edited</p>
      <span> Edited successfully.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      headerTxt="Edit Notify Mapper"
      fullHeight={!isReportEdited}
      FooterContnt={FooterComp}
    >
      {!isReportEdited ? (
        <form>
          <ModalInpF
            title="Selected Client"
            name="name"
            value={name}
            // onChange={handleStateChange}
            disabled
          />

          <DropDown
            title="Select Primary Email:  "
            isSelectAll={false}
            style={commonStyles}
            name="PrimaryEmail"
            placeholder={mail1}
            value={notifyClient.PrimaryEmail}
            onChange={handleDropDownState}
            data={users.data}
            isLoading={users.load}
            noOptionsMessage={() => 'Email not found '}
            IndicatorSeparator={false}
            maxMenuHeight={150}
          />
          <DropDown
            title="Select Secondary Email"
            isSelectAll={false}
            placeholder={mail2}
            style={commonStyles}
            name="SecondaryEmail"
            placeHolder={mail1}
            value={notifyClient.SecondaryEmail}
            onChange={handleDropDownState}
            data={users.data}
            isLoading={users.load}
            noOptionsMessage={() => 'Email not found  '}
            IndicatorSeparator={false}
            maxMenuHeight={150}
          />
          <DropDown
            title="Select Tertiary Mail"
            isSelectAll={false}
            style={commonStyles}
            placeholder={mail3}
            name="TertiaryMail"
            value={notifyClient.TertiaryMail}
            onChange={handleDropDownState}
            data={users.data}
            isLoading={users.load}
            noOptionsMessage={() => 'Email not found  '}
            IndicatorSeparator={false}
            maxMenuHeight={150}
          />
        </form>
      ) : (
        <UserCreatedComp />
      )}
    </RightModal>
  );
};

export default EditNotifyMapperModal;
