export const xRefRoutes = [
    {
      label: 'Item Categorization',
      link: '/item-selection',
    },
    {
      label: 'View Formatter',
      link: '/ViewFormatter-Selection',
    },
    {
      label: 'Notification Mapper',
      link: '/item-categorization/notification-mapper',
    },
    {
      label: 'Master Log Table',
      link: '/item-categorization/master-log',
    },
    
  ];
  