import './styles.css';

const _404Page = () => {
  return (
    <section className="_404Cont">
      <h5> 😞 Oop! Nothing Found</h5>
    </section>
  );
};

export default _404Page;
