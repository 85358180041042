import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reportActions, clientActions } from '../../../../store/actions';

// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';

import { OptionBox } from '../../../../components';

import { editReport } from '../../../../api/reports/editReport';

// find in roles
import { _findRoles } from '../../../../helper/_findRoles';

// validate
import validator from 'validator';

// toast
import { toastError } from '../../../../utils/toast';

import { _sortAsc } from '../../../../helper/_sort';

const EditReportModal = ({ row, ...props }) => {
  const { id, ...fields } = row;
  const dispach = useDispatch();

  const clients = useSelector(state => state.clients.data);

  const isSuperAdmin = _findRoles('super admin');

  // feteh all clients
  useEffect(() => {
    if (props.show && clients.length === 0) {
      dispach(clientActions.fetchAllclients({ pageNo: '1', pageSize: '1000' }));
    }
  }, [props.show]);

  const defaultFields = {
    name: '',
    page: '',
    embedURL: '',
    embedID: '',
    embedWorkspaceID: '',
    statusNotification: '',
    isReportFilterEnabled: '',
    isReportActionBar: '',
  };

  // states
  const [isReportEdited, setReportEdited] = useState(false);
  const [report, setReport] = useState(defaultFields);

  useEffect(() => {
    const isReportFilterEnabled =
        fields.isReportFilterEnabled === true ? 'True' : 'False',
      isReportActionBar = fields.isReportActionBar === true ? 'True' : 'False',
      embedURL = fields?.embedURL ? fields?.embedURL : '',
      statusNotification = fields?.statusNotification
        ? fields?.statusNotification
        : '';

    props.show
      ? setReport({
          ...fields,
          embedURL,
          statusNotification,
          isReportFilterEnabled,
          isReportActionBar,
        })
      : setReport(defaultFields);
  }, [props.show]);

  const handleStateChange = e => {
    const { name, value } = e.target;
    setReport(state => ({ ...state, [name]: value }));
  };

  const emptyFields = () => {
    setReport(defaultFields);
  };

  const validateFields = ({
    name,
    page,
    embedURL,
    embedID,
    embedWorkspaceID,
    statusNotification,
    isReportFilterEnabled,
    isReportActionBar,
  }) => {
    if (validator.isEmpty(name)) return 'Please enter report name';
    else if (validator.isEmpty(page)) return 'Please enter  workspace name';
    else if (validator.isEmpty(embedID)) return 'Please enter embed Id';
    else if (validator.isEmpty(embedWorkspaceID))
      return 'Please enter workSpaceId';
    else if (validator.isEmpty(isReportFilterEnabled))
      return 'Please select report filter';
    else if (validator.isEmpty(isReportActionBar))
      return 'Please select report actionBar';

    // validated
    return false;
  };

  const handleEditReport = () => {
    const validate = validateFields(report);
    if (!validate) {
      (async _ => {
        try {
          const res = await editReport(report, id);

          // fetch all reports
          dispach(
            reportActions.fetchAllReports({ pageNo: '1', pageSize: '1000' })
          );

          // empty fields
          emptyFields();
          // show success message
          setReportEdited(true);
        } catch (e) {
          toastError(`${e.message}`);
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const handleCloseReport = () => {
    const { handleClose } = props;

    setTimeout(() => setReportEdited(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!isReportEdited ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Clear" className="grayButn" onClick={emptyFields} />
          <ModalButt
            text="Update"
            className="greenButn"
            onClick={handleEditReport}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseReport}
        />
      )}
    </>
  );

  const UserCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Edited</p>
      <span>Report Edited successfully.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      headerTxt="Edit Report"
      fullHeight={!isReportEdited}
      FooterContnt={FooterComp}
    >
      {!isReportEdited ? (
        <form>
          <ModalInpF
            title="Report Name"
            req
            name="name"
            value={report.name}
            onChange={handleStateChange}
          />

          <OptionBox
            title="Workspace Name"
            req
            name="page"
            value={report.page}
            onChange={handleStateChange}
          >
            <option></option>
            {_sortAsc(clients, 'name').map(c => (
              <option key={c?.name}>{c?.name}</option>
            ))}
          </OptionBox>

          {/* <ModalInpF
            title="Embed URL"
            name="embedURL"
            value={report.embedURL}
            onChange={handleStateChange}
            disabled
          /> */}

          <ModalInpF
            title="Embed Workspace ID"
            req
            name="embedWorkspaceID"
            value={report.embedWorkspaceID}
            onChange={handleStateChange}
            disabled={!isSuperAdmin}
          />

          <ModalInpF
            title="Embed Report ID"
            req
            name="embedID"
            value={report.embedID}
            onChange={handleStateChange}
            disabled={!isSuperAdmin}
          />
          <ModalInpF
            field="TextArea"
            rows="4"
            title="Notification"
            name="statusNotification"
            value={report.statusNotification}
            onChange={handleStateChange}
          />
          <OptionBox
            title="Report Filter Enabled"
            req
            name="isReportFilterEnabled"
            value={report.isReportFilterEnabled}
            onChange={handleStateChange}
            disabled
          >
            <option></option>
            <option>True</option>
            <option>False</option>
          </OptionBox>
          <OptionBox
            title="Report ActionBar"
            req
            name="isReportActionBar"
            value={report.isReportActionBar}
            onChange={handleStateChange}
            disabled
          >
            <option></option>
            <option>True</option>
            <option>False</option>
          </OptionBox>
        </form>
      ) : (
        <UserCreatedComp />
      )}
    </RightModal>
  );
};

export default EditReportModal;
