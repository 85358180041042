// assets
import deleteIcon from '../../assets/img/delete_gray.png';
import editIcon from '../../assets/img/edit_gray.png';
import settingIcon from '../../assets/img/settings_gray.png';

// find in roles
import { _findRoles } from '../../helper/_findRoles';

const userTableConfig = (
  editActionsHandle,
  deleteActionsHandle,
  configureActionsHandle
) => {
  const isSuperAdmin = _findRoles('admin') || _findRoles('super admin');

  const tableColumns = [
    {
      dataField: 'action',
      text: 'Action',
      // align: "center",
      // headerAlign: (column, colIndex) => "center",
      searchable: false,
      csvExport: false,
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span className="TbleActnIcns">
            <img
              src={editIcon}
              alt="eIcon"
              onClick={() => editActionsHandle(row)}
            />
            {isSuperAdmin && (
              <img
                src={deleteIcon}
                alt="dIcon"
                onClick={() => deleteActionsHandle(row)}
              />
            )}
            <img
              src={settingIcon}
              alt="sIcon"
              onClick={() => configureActionsHandle(row)}
            />
          </span>
        );
      },
    },
    {
      dataField: 'userName',
      text: 'Username',
      sort: true,
      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     alert("Click on Name field");
      //   },
      // },
    },
    {
      dataField: 'firstName',
      text: 'First Name',
      sort: true,
    },
    {
      dataField: 'lastName',
      text: 'LastName',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'mobileNumber',
      text: 'Mobile Number',
      sort: true,
    },
    {
      dataField: 'userStatus',
      text: 'Status',
      sort: true,
      searchable: false,
      formatter: (cell, row, rowIndex, extraData) => {
        if (cell) return 'Active';
        return 'Inactive';
      },
    },
  ];
  return tableColumns;
};

export default userTableConfig;
