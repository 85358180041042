// assets
import deleteIcon from '../../assets/img/delete_gray.png';
import editIcon from '../../assets/img/edit_gray.png';

// find in roles
import { _findRoles } from '../../helper/_findRoles';

const config = (editActionsHandle, deleteActionsHandle) => {
  const isSuperAdmin = _findRoles('admin') || _findRoles('super admin');

  // congifure table option here
  const tableColumns = [
    {
      dataField: 'action',
      text: 'Action',
      // align: "center",
      // headerAlign: (column, colIndex) => "center",
      searchable: false,
      csvExport: false,
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span className="TbleActnIcns">
            <img
              src={editIcon}
              alt="eIcon"
              onClick={() => editActionsHandle(row)}
            />
            {isSuperAdmin && (
              <img
                src={deleteIcon}
                alt="dIcon"
                onClick={() => deleteActionsHandle(row)}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'name',
      text: 'Role',
      sort: true,
    },
    {
      dataField: 'createdBy',
      text: 'Created By',
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => {
        if (cell === 0) return 'N/A';
        return cell;
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created at',
      sort: true,
      searchable: false,
    },
    {
      dataField: 'modifiedBy',
      text: 'Updated By',
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => {
        if (cell === 0) return 'N/A';
        return cell;
      },
    },
    {
      dataField: 'modifiedAt',
      text: 'Updated at',
      sort: true,
      searchable: false,
    },
  ];

  return tableColumns;
};

export default config;
