import moment from 'moment';

export const _formatDate = (date, format) => moment(date).format(format);

export const _compareDate = (date1, date2) =>
  moment(_formatDate(date1, 'DD-MM-YYYY')).isSame(_formatDate(date2, 'DD-MM-YYYY'));

export const _validDate = date => isNaN(date) && moment(date).isValid();

// date ranges
const currentDate = moment().subtract(1, 'day');

export const _thisWeek = format => {
  const startDate = moment().startOf('week').add(1, 'day')?._d;
  // endDate = moment().endOf('week').add(1, 'day')?._d;
  return !!format
    ? [_formatDate(startDate, format), _formatDate(currentDate, format)]
    : [startDate, currentDate];
};

export const _monthLastWeek = format => {
  const startDate = moment().startOf('week').subtract(7, 'days')?._d;
  // endDate = moment().endOf('week').subtract(7, 'days')?._d;
  return !!format
    ? [_formatDate(startDate, format), _formatDate(currentDate, format)]
    : [startDate, currentDate];
};

export const _thisMonth = format => {
  const startDate = moment().startOf('month')?._d;
  // endDate = moment().endOf('month')?._d;
  return !!format
    ? [_formatDate(startDate, format), _formatDate(currentDate, format)]
    : [startDate, currentDate];
};

export const _yearToDate = format => {
  const startDate = moment().startOf('year')?._d;
  return !!format
    ? [_formatDate(startDate, format), _formatDate(currentDate, format)]
    : [startDate, currentDate];
};
