import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { clientActions } from '../../../../store/actions';

// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';

import { editClient } from '../../../../api/clients/editClient';

// validate
import validator from 'validator';

// toast
import { toastError } from '../../../../utils/toast';

const EditRoleModal = ({ row, ...props }) => {
  const { id, ...fields } = row;
  const dispach = useDispatch();

  const defaultFields = {
    userId: 0,
    name: '',
  };

  // states
  const [isClientEdited, setClientEdited] = useState(false);
  const [client, setClient] = useState(defaultFields);

  useEffect(() => {
    props.show ? setClient({ name: fields.name }) : setClient(defaultFields);
  }, [props.show]);

  const handleStateChange = e => {
    const { name, value } = e.target;
    setClient(state => ({ ...state, [name]: value }));
  };

  const emptyFields = () => {
    setClient({
      name: '',
    });
  };

  const validateFields = ({ name }) => {
    if (validator.isEmpty(name)) return 'Please enter client Name';

    // validated
    return false;
  };

  const handleEditClient = () => {
    const validate = validateFields(client);
    if (!validate) {
      (async _ => {
        try {
          const res = await editClient(client, id);

          // fetch all clients
          dispach(
            clientActions.fetchAllclients({ pageNo: '1', pageSize: '1000' })
          );

          // empty fields
          emptyFields();
          // show success message
          setClientEdited(true);
        } catch (e) {
          toastError(`${e.message}`);
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const handleCloseClient = () => {
    const { handleClose } = props;

    setTimeout(() => setClientEdited(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!isClientEdited ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Clear" className="grayButn" onClick={emptyFields} />
          <ModalButt
            text="Update"
            className="greenButn"
            onClick={handleEditClient}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseClient}
        />
      )}
    </>
  );

  const ClientCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Edited</p>
      <span>Client Edited successfully.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      headerTxt="Edit Client"
      FooterContnt={FooterComp}
    >
      {!isClientEdited ? (
        <form>
          <ModalInpF
            title="Client Name"
            req
            name="name"
            value={client.name}
            onChange={handleStateChange}
          />
        </form>
      ) : (
        <ClientCreatedComp />
      )}
    </RightModal>
  );
};

export default EditRoleModal;
