// post requests
export const auth = '/user/authenticate';
export const tokenAuth = '/user/tokenauthenticate';
export const register = '/user/register';
export const sendEmail = '/Email/SendEmail';
export const otpAuth = (userName, tempPassword, userOTP) =>
  `/User/OTPAuthenticate?UserName=${userName}&TemporaryPassword=${tempPassword}&UserOTP=${userOTP}`;
export const otpChangePassword = `/User/OTPChangePassword`;
export const forgetPass = emailID => `/User/forgotPassword?emailId=${emailID}`;

// get requests
export const getAllUsers = (pageNum, pageSize) =>
  `/User/GetAllUsers?PageNumber=${pageNum}&PageSize=${pageSize}`;
export const getUserById = id => `/User/${id}`;
export const searchAllUsers = searchKey =>
  `/User/SearchAll?searchText=${searchKey}`;

// delete requests
export const deleteUserById = id => `/User/${id}`;

// put requests
export const updateUserById = id => `/User/${id}`;
