import { CheckBox } from "../../components";

export const ColumnToggle = ({ columns, onColumnToggle, toggles }) => (
  <div
    className="w-75 d-flex justify-content-between flex-wrap gap-2 my-1"
    data-toggle="buttons"
  >
    {columns
      .map(column => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map(column => (
        <CheckBox
          key={column.dataField}
          label={column.text}
          checked={column.toggle}
          onChange={() => onColumnToggle(column.dataField)}
        />
      ))}
  </div>
);
