import { _normStr } from './_normStr';

export const _clientReports = (
  selectedArr,
  allData,
  match = 'label',
  matchWith = 'page'
) => {
  let modifArr = [];
  selectedArr.forEach(i => {
    modifArr = [
      ...modifArr,
      ...allData.filter(j =>
        _normStr(j[matchWith]).includes(_normStr(i[match]))
      ),
    ];
  });

  return modifArr;
};
