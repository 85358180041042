import { useState, useEffect } from 'react';
// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalButt from '../../../modalComp/modalButtons';

// toast
import { toastError } from '../../../../utils/toast';

import { _sortAsc } from '../../../../helper/_sort';
import { getXrefClients } from '../../../../api/xReference/getXrefClients';
import DropDown from '../../../modalComp/dropDown';
import { fetchAllUsers } from '../../../../api/users/getAllUsers';
import { addNewEmailMapper } from '../../../../api/xReference/addNewEmailMapping';

const commonStyles = {
  margin: 0,
  padding: 0,
};

const CreateNotifyModal = ({ CloseReport, data, ...props }) => {
  const defaultFields = {
    Client: '',
    mail1: '',
    mail2: '',
    mail3: '',
  };

  // states

  const [isMailConfigCreated, setIsMailConfigCreated] = useState(false);
  const [report, setReport] = useState(defaultFields);
  const [clients, setclients] = useState({
    load: false,
    data: [],
  });
  const [users, setUsers] = useState({
    load: false,
    data: [],
  });
  const [usersEmail, setUsersEmail] = useState({
    load: false,
    data: [],
  });
  const [notifyClient, setnotifyClient] = useState({
    Client: '',
    PrimaryEmail: '',
    SecondaryEmail: '',
    TertiaryMail: '',
  });

  // on clicking close button
  useEffect(() => {
    const close = CloseReport;
    if (close == false) {
      handleCloseReport();
    }
  }, [CloseReport]);

  // intiates on start of page
  useEffect(() => {
    (async _ => {
      const res = await fetchAllUsers(1, 1000);

      const data = res?.data;
      const mappedData = data.map(k => {
        return { name: k.email };
      });
      setUsers(state => ({ ...state, data: mappedData, load: false }));
    })();
  }, []);

  // get the client details
  const getAllclients = _ => {
    if (clients.data.length === 0) {
      (async _ => {
        try {
          setclients(prev => ({
            ...prev,
            load: true,
          }));
          const res = await getXrefClients();
          setclients({ load: false, data: res?.data });
        } catch (e) {
          setclients(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  };

  useEffect(() => {
    if (notifyClient.Client.length > 0) {
      try {
        setUsersEmail({ data: [], load: true });

        setnotifyClient(state => ({
          ...state,
          PrimaryEmail: [],
          TertiaryMail: [],
          SecondaryEmail: [],
        }));

        setUsersEmail({ load: false, data: users.data });
      } catch (e) {
        setUsersEmail(s => ({ ...s, load: false }));
        toastError(e.message);
      }
    }

    return () => {};
  }, [JSON.stringify(notifyClient.Client)]);

  const emptyFields = () => {
    setReport(defaultFields);
    setnotifyClient({
      Client: [],
      PrimaryEmail: [],
      SecondaryEmail: [],
      TertiaryMail: [],
    });
  };

  const handleDropDownState = (list, { name }) => {
    setnotifyClient(state => ({ ...state, [name]: [list] }));
  };
  

  //create new mail configuration
  const handleCreateMailConfig = () => {
    (async _ => {
      try {
        {
          if (
            notifyClient.Client != '' &&
            notifyClient.PrimaryEmail != '' &&
            notifyClient.SecondaryEmail != '' &&
            notifyClient.TertiaryMail != ''
          ) {
            const searchClient = data.findIndex(client =>  
              client.name.toLowerCase().trim() === notifyClient.Client[0].value.toLowerCase().trim()          
          );
             

            if (searchClient === -1) {

              var client = notifyClient.Client[0].value;
              var mail1 = notifyClient.PrimaryEmail[0].value;
              var mail2 = notifyClient.SecondaryEmail[0].value;
              var mail3 = notifyClient.TertiaryMail[0].value;

              if (mail1 !== mail2 && mail2 !== mail3) {
                const res = await addNewEmailMapper(client, mail1, mail2, mail3);
                setIsMailConfigCreated(true);
                emptyFields();
              } else {
                toastError('please select unique mail');
              }
            } else {
              toastError('Client Already Exists');
            }
          } else {
            toastError('please select all input field');
          }
        }
        //show success message
      } catch (e) {
        toastError(`${e.message}`);
      }
    })();
  };

  const handleCloseReport = () => {
    const { handleClose } = props;

    setTimeout(() => setIsMailConfigCreated(false), 400);
    handleClose();
  };
  // UI dispalyed
  const FooterComp = () => (
    <>
      {!isMailConfigCreated ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Clear" className="grayButn" onClick={emptyFields} />
          <ModalButt text="Create" className="greenButn" onClick={handleCreateMailConfig} />
        </div>
      ) : (
        <ModalButt text="Close" className="redButn" width="100%" onClick={handleCloseReport} />
      )}
    </>
  );

  const ReportCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Created</p>
      <span> Email mapped successfully.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      headerTxt="Create New Email Nofication Mapper"
      fullHeight={!isMailConfigCreated}
      FooterContnt={FooterComp}
    >
      {!isMailConfigCreated ? (
        <form>
          <DropDown
            title="Select Client"
            isSelectAll={false}
            req
            name="Client"
            value={notifyClient.Client}
            onChange={handleDropDownState}
            onMenuOpen={getAllclients}
            data={clients.data}
            isLoading={clients.load}
            noOptionsMessage={() => 'nothing '}
            IndicatorSeparator={false}
            maxMenuHeight={150}
          />

          <DropDown
            title="Select Primary Email"
            req
            isSelectAll={false}
            style={commonStyles}
            name="PrimaryEmail"
            value={notifyClient.PrimaryEmail}
            onChange={handleDropDownState}
            data={usersEmail.data}
            isLoading={usersEmail.load}
            maxMenuHeight={150}
            noOptionsMessage={() =>
              notifyClient.Client.length === 0
                ? 'Please select a Client to proceed'
                : 'Mail does not exists'
            }
          />
          <DropDown
            title="Select Secondary Email"
            req
            isSelectAll={false}
            style={commonStyles}
            name="SecondaryEmail"
            value={notifyClient.SecondaryEmail}
            onChange={handleDropDownState}
            data={usersEmail.data}
            isLoading={usersEmail.load}
            maxMenuHeight={150}
            noOptionsMessage={() =>
              notifyClient.Client.length === 0
                ? 'Please select a  Primary Mail to proceed'
                : 'Mail does not exists'
            }
          />

          <DropDown
            title="Select Tertiary Email"
            req
            isSelectAll={false}
            style={commonStyles}
            name="TertiaryMail"
            menuPlacement="auto"
            value={notifyClient.TertiaryMail}
            onChange={handleDropDownState}
            data={usersEmail.data}
            isLoading={usersEmail.load}
            maxMenuHeight={150}
            noOptionsMessage={() =>
              notifyClient.Client.length === 0
                ? 'Please select Secondary Mail to proceed'
                : 'Mail does not exists'
            }
          />
        </form>
      ) : (
        <ReportCreatedComp />
      )}
    </RightModal>
  );
};

export default CreateNotifyModal;
