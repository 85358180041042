import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/img/back_arow.png";

import "./styles.css";

export default function FormContainer({
  children,
  showBackToLogin,
  subHeading,
  heading,
  descp,
}) {
  const navigate = useNavigate();
  return (
    <section className="FormContainer">
      <header>
        {subHeading && <p className="subHeading">{subHeading}</p>}
        <p className="heading">{heading}</p>
        <p className="descp">{descp}</p>
      </header>
      {children}
      <p className="bottomTxt">
        By selecting Sign in you agree to our <span  onClick={()=> navigate('/info/terms_of_use')}>Terms</span> and have read
        and acknowledge our <span onClick={()=> navigate('/info/privacy_policy')}>Global Privacy Statement</span>
      </p>
      {showBackToLogin && (
        <div className="d-flex justify-content-center mt-3">
          <span
            className="backToSignIn"
            onClick={() => navigate("/auth/signin")}
          >
            <img src={backIcon} alt="backIcon" />
            &ensp;Back to Sign in Page
          </span>
        </div>
      )}
    </section>
  );
}
