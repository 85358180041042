import { Modal } from "react-bootstrap";
import closeIcon from "../../../../assets/img/close.png";

import "./styles.css";

const CenterModal = ({
  FooterContnt,
  handleClose,
  ...props
}) => {
  return (
    <Modal
      {...props}
      centered
      dialogClassName={`centrMdlCont`}
      contentClassName="centrMdlCntnt"
    >
      <header>
        <img src={closeIcon} alt="close" onClick={handleClose} />
      </header>

      <main>{props.children}</main>

      <footer>
        <FooterContnt />
      </footer>
    </Modal>
  );
};

export default CenterModal;
