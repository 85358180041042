import axios from '../api';
import { updteUserConfig } from './endpoints';
import { _commaSepIds } from '../../helper/_commaSepIds';

export const updateUserConfig = async config => {
  const { usersId, roles, clients, reports } = config;

  // normalize ids
  const body = {
    usersId,
    rolesId: _commaSepIds(roles),
    clientId: _commaSepIds(clients),
    reportsId: _commaSepIds(reports),
  };

  const res = await axios.put(updteUserConfig(usersId), body);
  return res;
};
