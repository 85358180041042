export const fetchAllViewerReport = (clientName, accountType, reportType, tab) =>
  `/XReference/GetViewFormatter?ClientsName=${clientName}&LookuptableName=${accountType}&ReporttypeName=${reportType}&ReportName=${tab}`;

export const fetchxrefClients = `/XReference/Clients`;

export const fetchLookupTable = `/XReference/LookupTable`;

export const fetchReportType = `/XReference/Reporttype`;

export const ReportTypeByLookuptable = accountType =>
  `/XReference/GetReporttypeByLookuptable?LookuptableName=${accountType}`;

export const fetchTabNames = `/XReference/ViewFormatterReportsName`;

export const CreateLevel1 = (ClientsName, account, reportType, tab, Parent, Level1, username) =>
  `/XReference/CreateViewFormatter?ClientsName=${ClientsName}&LookuptableName=${account}&ReporttypeName=${reportType}&ReportName=${tab}&Parent=${Parent}&Level1=${Level1}&CreatedBy=${username}`;
export const CreateLevel2 = (ClientsName, account, reportType, tab, Parent, Level2, username) =>
  `/XReference/CreateViewFormatter?ClientsName=${ClientsName}&LookuptableName=${account}&ReporttypeName=${reportType}&ReportName=${tab}&Parent=${Parent}&Level2=${Level2}&CreatedBy=${username}`;
export const CreateLevel3 = (ClientsName, account, reportType, tab, Parent, Level3, username) =>
  `/XReference/CreateViewFormatter?ClientsName=${ClientsName}&LookuptableName=${account}&ReporttypeName=${reportType}&ReportName=${tab}&Parent=${Parent}&Level3=${Level3}&CreatedBy=${username}`;

export const updateDataLevel1 = (id, Level1, username) =>
  `/XReference/UpdateViewformatterDetails?Id=${id}&Level1=${Level1}&ModifiedBy=${username}`;
export const updateDataLevel2 = (id, Level2, username) =>
  `/XReference/UpdateViewformatterDetails?Id=${id}&Level2=${Level2}&ModifiedBy=${username}`;
export const updateDataLevel3 = (id, Level3, username) =>
  `/XReference/UpdateViewformatterDetails?Id=${id}&Level3=${Level3}&ModifiedBy=${username}`;

//Item Categorization
export const fetchItemCategorization = (ClientsName, LookuptableName) =>
  `/XReference/GetItemCategorization?ClientsName=${ClientsName}&LookuptableName=${LookuptableName}`;

export const Level1ByReportType = (ClientsName, LookuptableName, ReporttypeName,ReportName) =>
  `/XReference/GetLevel1ByReporttype?ClientsName=${ClientsName}&LookuptableName=${LookuptableName}&ReporttypeName=${ReporttypeName}&ReportName=${ReportName}`;


export const XrefEmailTrigger = `/Email/SendEmailXReference`;

export const defaultModel = (Id, model,ModifiedBy) =>
  `/XReference/UpdateItemcategorizationetails?Id=${Id}&model=${model}&ModifiedBy=${ModifiedBy}`;
export const defaultShortForm = (Id, shortform,ModifiedBy) =>
  `/XReference/UpdateItemcategorizationetails?Id=${Id}&shortform=${shortform}&ModifiedBy=${ModifiedBy}`;
export const defaultSOT = (Id, sot,ModifiedBy) =>
  `/XReference/UpdateItemcategorizationetails?Id=${Id}&sot=${sot}&ModifiedBy=${ModifiedBy}`;

export const fetchAllEmailMapped = `/XReference/EmailSettings`;
export const createEmailMapping = ( ClientsName,  email1,  email2,  email3,  createdBy) =>
  `/XReference/CreatenewEmailConfig?ClientsName=${ClientsName}&email=${email1}&email2=${email2}&email3=${email3}&createdBy=${createdBy}`;
export const updateEmailMapping =(Id, mail1, mail2, mail3, ModifiedBy)=>
`/XReference/UpdateEmailSettings?Id=${Id}&mail1=${mail1}&mail2=${mail2}&mail3=${mail3}&ModifiedBy=${ModifiedBy}`;

export const fetchMasterLog =`/XReference/LogItemTableData`;


