import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toastError } from '../../utils/toast';
import orderBy from 'lodash/orderBy';

import createNewIcon from '../../assets/img/create_new.png';
import axios from 'axios';
import { MainButton, BootStrapTable } from '../../components';

import masterLogTable from './masterlogTableConfig';
import { getAllMasterLog } from '../../api/xReference/getAllMasterLog';

const commonStyles = {
  margin: 0,
  padding: 0,
};

const MasterLog = () => {
  const [tableDatas, setTableData] = useState({
    loading: true,
    error: true,
    data: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async _ => {
    try {
      //const res = await getItemCategorizationData("pacha", "account");
      const res = await getAllMasterLog();
      let sortedData = orderBy(res.data, ['modifiedAt'], ['desc']);

      setTableData({ data: sortedData, loading: false, error: false });

      if (res.status != 200) {
        toastError(res);
      }
    } catch (e) {
      console.log(e);
      toastError(e);
    }
  };

  // table header options
  const tableConfig = masterLogTable();

  return (
    <>
      <main className="userPageCont">
        <section className="userPageHead">
          <h4 className="userHeading">Master Log </h4>
        </section>
        <section className="userPageTable">
          <BootStrapTable
            enableSearch={{ placeHolder: 'Search By Account or Created By or Modified By' }}
            tableColumns={tableConfig}
            tableData={tableDatas}
          />
        </section>
      </main>
    </>
  );
};

export default MasterLog;
