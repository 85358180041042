// assets
import deleteIcon from '../../assets/img/delete_gray.png';
import editIcon from '../../assets/img/edit_gray.png';
import { _formatDate } from '../../helper/_date';

// find in roles
import { _findRoles } from '../../helper/_findRoles';

const masterLogTable = () => {
//   const isSuperAdmin = _findRoles('admin') || _findRoles('super admin');

  // congifure table option here
  
  const tableColumns = [
    {
      dataField: 'id',
      text: 'Id',
      sort: true,
    },
    {
      dataField: 'clientId',
      text: 'Client Id',
      sort: true,
    },
    {
      dataField: 'datamoveid',
      text: 'Datamove Id',
      sort: true,
    },
    
    {
      dataField: 'masterAccount',
      text: 'Master Account',
      sort: true,
    },
    {
      dataField: 'reportName',
      text: 'Report Name',
      sort: true,
    },
    {
      dataField: 'createdBy',
      text: 'Created By',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      sort: true,
      formatter: cell => (cell ? _formatDate(cell, 'DD/MM/YYYY') : 'N/A'),
    },
    {
      dataField: 'modifiedBy',
      text: 'Modified By',
      sort: true,
      formatter: cell => (cell ? cell  : '-'),
      
    },
    {
      dataField: 'modifiedAt',
      text: 'Modified At',
      sort: true,
      formatter: cell => (cell ? _formatDate(cell, 'DD/MM/YYYY') : 'N/A'),
    },
     ];

  return tableColumns;
};

export default masterLogTable;
