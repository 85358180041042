import React from 'react'
import ModelTable from '../../../pages/xReferencePages/modelTable'

const ReportNameTab = () => {
  return (
  <>
   <ModelTable tabs="pnl" />
  </>

  )
}

export default ReportNameTab