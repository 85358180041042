import axios from 'axios';
import {
  axiosRequestInterceptor,
  axiosResponseInterceptor,
} from './Interceptors';

// base axios instance
const baseInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  // timeout: 8000,
});

// add request interceptor to instance
baseInstance.interceptors.request.use(
  axiosRequestInterceptor.onFulfilled,
  axiosRequestInterceptor.onRejected
);

// add response interceptor to instance
baseInstance.interceptors.response.use(
  axiosResponseInterceptor.onFulfilled,
  axiosResponseInterceptor.onRejected
);

export default baseInstance;
