import './styles.css'

import Spinner from 'react-bootstrap/Spinner'

const Loader = ({size='32', color='#408198', thick=0.123}) => {
  
  const loaderStyles = {
      width: `${size}px`,
      height: `${size}px`,
      borderWidth: `${size * thick}px`,
      color: color
  }
    return (
        <div className="loaderContainer">
        <Spinner style={loaderStyles} animation="border"/>
        </div>
  );
};

export default Loader;
