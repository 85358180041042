import { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';

import './styles.css';

const DailyUpdate = ({ txt }) => {
  const [showAlert, setShowAlert] = useState('');

  useEffect(() => {
    setShowAlert(txt);
  }, [txt]);

  return (
    <>
      {showAlert && (
        <div className="DailyUpdate">
          <Marquee gradient={false} speed={70}>
            <p>{txt}</p>
          </Marquee>
          <span onClick={() => setShowAlert('')}>x</span>
        </div>
      )}
    </>
  );
};

export default DailyUpdate;
