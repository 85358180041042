import axios from '../api';
import { otpChangePassword } from './endpoints';

export const ChangePassword = async (newUserData, password) => {
  const { username, temporaryPassword } = newUserData;

  const body = {};

  const queryParams = {
    params: {
      UserName: username,
      TemporaryPassword: temporaryPassword,
      Password: password,
    },
  };

  try {
    const response = await axios.post(otpChangePassword, body, queryParams);
    return response;
  } catch (error) {
    return { error: true, message: error.message };
  }
};
