export const _renderOptions = (data, nameKey = 'name', idKey = 'id') => {
  const optionsArray = [];
  data.forEach((item, i) => {
    // if item is object
    if (typeof item === 'object') {
      optionsArray.push({
        value: item[nameKey].toLowerCase(),
        label: item[nameKey],
        id: item[idKey],
      });
    } else {
      optionsArray.push({
        value: item.toLowerCase(),
        label: item,
        id: i,
      });
    }
  });

  return optionsArray;
};
