import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { rolesActions } from '../../../../store/actions';

// modal
import RightModal from '../../base/rightModal';

// assets
import successIcon from '../../../../assets/img/success.png';

// components
import ModalInpF from '../../../modalComp/modalInput';
import ModalButt from '../../../modalComp/modalButtons';

import { EditRole } from '../../../../api/roles/editRole';

// validate
import validator from 'validator';

// toast
import { toastError } from '../../../../utils/toast';

const EditRoleModal = ({ row, ...props }) => {
  const { id, ...fields } = row;
  const dispach = useDispatch();

  const defaultFields = {
    userId: 0,
    name: '',
  };

  // states
  const [isRoleEdited, setRoleEdited] = useState(false);
  const [role, setRole] = useState(defaultFields);

  useEffect(() => {
    props.show ? setRole({ name: fields.name }) : setRole(defaultFields);
  }, [props.show]);

  const handleStateChange = e => {
    const { name, value } = e.target;
    setRole(state => ({ ...state, [name]: value }));
  };

  const emptyFields = () => {
    setRole({
      name: '',
    });
  };

  const validateFields = ({ name }) => {
    if (validator.isEmpty(name)) return 'Please enter roll Name';

    // validated
    return false;
  };

  const handleEditRole = () => {
    const validate = validateFields(role);
    if (!validate) {
      (async _ => {
        try {
          const res = await EditRole(role, id);

          // fetch all roles
          dispach(
            rolesActions.fetchAllroles({ pageNo: '1', pageSize: '1000' })
          );

          // empty fields
          emptyFields();
          // show success message
          setRoleEdited(true);
        } catch (e) {
          toastError(`${e.message}`);
        }
      })();
    } else {
      toastError(validate);
    }
  };

  const handleCloseUser = () => {
    const { handleClose } = props;

    setTimeout(() => setRoleEdited(false), 400);
    handleClose();
  };

  const FooterComp = () => (
    <>
      {!isRoleEdited ? (
        <div className="d-flex justify-content-between">
          <ModalButt text="Clear" className="grayButn" onClick={emptyFields} />
          <ModalButt
            text="Update"
            className="greenButn"
            onClick={handleEditRole}
          />
        </div>
      ) : (
        <ModalButt
          text="Close"
          className="redButn"
          width="100%"
          onClick={handleCloseUser}
        />
      )}
    </>
  );

  const UserCreatedComp = () => (
    <section className="UserCreatedComp">
      <img src={successIcon} alt="sIcon" />
      <p>Edited</p>
      <span>Role Edited successfully.</span>
    </section>
  );

  return (
    <RightModal
      {...props}
      backdrop="static"
      headerTxt="Edit Role"
      FooterContnt={FooterComp}
    >
      {!isRoleEdited ? (
        <form>
          <ModalInpF
            title="Role Name"
            req
            name="name"
            value={role.name}
            onChange={handleStateChange}
          />
        </form>
      ) : (
        <UserCreatedComp />
      )}
    </RightModal>
  );
};

export default EditRoleModal;
