// buttons
import AuthButton from './common/buttons/authButton';
import MainButton from './common/buttons/mainButton';

// layout components
import AmpHeader from './layoutComp/header';
import SideBar from './layoutComp/sideBar';
import NavBar from './layoutComp/navBar';

// custom html components
import CheckBox from './common/checkBox';
import RadioButt from './common/radioButt';
import FormInput from './common/formInput';
import FormContainer from './common/formContainer/index';
import { OptionBox } from './common/optionBox';

import DatePicker from './common/datePicker';

// tables
import BootStrapTable from './customTable';
import RsuiteTable from './rSuiteTable';

// user modals
import CreateUserModal from './modals/user/createUser';
import EditUserModal from './modals/user/editUser';
import DeleteUserModal from './modals/user/deleteUser';
import ConfigureUserModal from './modals/user/configureUser';

// roles modals
import CreateRoleModal from './modals/roles/createRole';
import EditRoleModal from './modals/roles/editRole';
import DeleteRoleModal from './modals/roles/deleteRole';

// clients modals
import CreateClientModal from './modals/clients/createClient';
import EditClientModal from './modals/clients/editClient';
import DeleteClientModal from './modals/clients/deleteClient';

// reports Modals
import CreateReportModal from './modals/reports/createReport';
import EditReportModal from './modals/reports/editReport';
import DeleteReportModal from './modals/reports/deleteReport';

// alerts
import DailyAlert from './alerts/dailyUpdate';

// loader
import Loader from './loader';

// udm
import TabsForm from './tabsForm';

// modal fields
import ModalInputF from './modalComp/modalInput';

export {
  AuthButton,
  MainButton,
  CheckBox,
  RadioButt,
  FormInput,
  OptionBox,
  FormContainer,
  DatePicker,
  // home layout comp
  SideBar,
  AmpHeader,
  NavBar,
  // table
  BootStrapTable,
  RsuiteTable,
  // user modals
  CreateUserModal,
  EditUserModal,
  DeleteUserModal,
  ConfigureUserModal,
  // roles modals
  CreateRoleModal,
  EditRoleModal,
  DeleteRoleModal,
  // clients modals
  CreateClientModal,
  EditClientModal,
  DeleteClientModal,
  // reports modals
  CreateReportModal,
  EditReportModal,
  DeleteReportModal,
  DailyAlert,
  Loader,
  TabsForm,
  ModalInputF,
};
