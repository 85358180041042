import { Modal } from 'react-bootstrap';
import closeIcon from '../../../../assets/img/close.png';

import './styles.css';

const RightModal = ({
  headerTxt,
  FooterContnt,
  handleClose,
  fullHeight,
  ...props
}) => {
  return (
    <Modal
      {...props}
      dialogClassName={`rghtMdlCont ${fullHeight ? 'rghtMdlMxH' : ''}`}
      contentClassName="rghtMdlCntnt"
    >
      <header>
        <h5>{headerTxt}</h5>
        <img src={closeIcon} alt="close" onClick={handleClose} />
        <hr />
      </header>

      <main>{props.children}</main>

      <footer>
        <hr />
        <FooterContnt />
      </footer>
    </Modal>
  );
};

export default RightModal;
