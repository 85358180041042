import { useState, useEffect } from 'react';

import { toastError } from '../../utils/toast';
import { _getDataMoveId } from '../../helper/_getDataMoveId';
import DropDown from '../../components/modalComp/dropDown';
import { getAllClients } from '../../api/clients/getAllClients';

import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { MainButton } from '../../components';
import TabBottomSection from '../../components/tabsForm/TabBottomSection';
import { getXrefClients } from '../../api/xReference/getXrefClients';
import { getLookupTable } from '../../api/xReference/getLookupTable';


const commonStyles = {
  margin: 0,
  padding: 0,
};

const ItemCategorization = () => {
  const navigate = useNavigate();
  
  const [clients, setclients] = useState({
    load: false,
    data: [],
  });

  const [LookupTable, setLookupTable] = useState({
    load: false,
    data: [],
  });
  const [currentTab, setCurrentTab] = useState('');

  const [itemCategory, setItemCategory] = useState({
    AllClients: [],
    AllLookUpTble: [],
  });

  const [disabled, setDisabled] = useState(true);
// Get the client's list
  const getAllclients = _ => {
    if (clients.data.length === 0) {
      (async _ => {
        try {
          setclients(prev => ({
            ...prev,
            load: true,
          }));
          const res = await getXrefClients();
          setclients({ load: false, data: res?.data });
        } catch (e) {
          setclients(s => ({ ...s, load: false }));
          toastError(e.message);
        }
      })();
    }
  };

  
//  geeting lookup table list
  useEffect(() => {
    if (itemCategory.AllClients.length > 0) {
      (async _ => {
        try {
          setLookupTable({ data: [], load: true });

          setItemCategory(state => ({ ...state, AllLookUpTble: []}));

          const res = await getLookupTable();
          setLookupTable ({ data:res?.data, load: false });
         
         
        } catch (e) {

          setLookupTable(s => ({ ...s, load: false }));
          toastError("lokuptable cannot fetch",itemCategory.AllClients.length > 0);
        }
      })();
    }
  }, [JSON.stringify(itemCategory.AllClients)]);

  const handleDropDownState = (list, { name }) => {
    setItemCategory(state => ({ ...state, [name]: [list] }));
    if (name == 'AllLookUpTble') {
      setDisabled(false);
    }
  };
 

  const viewDataValue = itemCategory.AllLookUpTble.map(l => l.label);
// navigate to MasterAccount Lookup
  const handleClickEvent = e => {
    navigate(`/item-categorization?clientName=${itemCategory.AllClients[0].value}&accountType=${itemCategory.AllLookUpTble[0].value}&reportType=model&masterAccountValue=undefined`);
  };

  const clear = () => {
    setItemCategory({
      AllClients: [],
      AllLookUpTble: [],
    });
    setclients({
      load: false,
      data:[]
    })
    setLookupTable({
      load: false,
      data:[]
    })
    setDisabled(true);
  };

  // ui
  return (
    <>
      <main className="userPageCont">
        <section className="userPageHead">
          <h4 className="userHeading">Item Categorization</h4>
        </section>

        <section className="userPageTable p-3">
          <form className="row g-3">
            <div className="col-md-4 col-lg-3">
              <DropDown
                title="Select Clients"
                isSelectAll={false}
                style={commonStyles}
                name="AllClients"
                value={itemCategory.AllClients}
                onChange={handleDropDownState}
                onMenuOpen={getAllclients}
                data={clients.data}
                isLoading={clients.load}
                noOptionsMessage={() => 'Client does not exist '}
                IndicatorSeparator={false}
                maxMenuHeight={150}
              />
            </div>

            <div className="col-md-4 col-lg-3">
              <DropDown
                title="Select LookupTable"
                isSelectAll={false}
                style={commonStyles}
                name="AllLookUpTble"
                value={itemCategory.AllLookUpTble}
                onChange={handleDropDownState}
                data={LookupTable.data}
                isLoading={LookupTable.load}
                maxMenuHeight={150}
                noOptionsMessage={() =>
                  itemCategory.AllClients.length === 0
                    ? 'Select the Clients to proceed'
                    : 'Lookup Table Does not exist'
                }
              />
            </div>

            <main className="tabsBotmCont">
              <section className="d-flex justify-content-end">
                <div className="tabBtnCont">
                  <MainButton onClick={clear}>Clear</MainButton>
                  <MainButton
                    style={{ background: '#3fbc92' }}
                    onClick={handleClickEvent}
                    disabled={disabled}
                  >
                    Submit
                  </MainButton>
                </div>
              </section>
              <br />
              <hr />
            </main>
          </form>
        </section>
      </main>
    </>
  );
};

export default ItemCategorization;
