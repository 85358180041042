import './styles.css';

export const OptionBox = ({ title, req, hasError, style = {}, ...props }) => {
  return (
    <section style={style} className="ModalInpFCont">
      <label>
        {title}
        {req && <span> *</span>}
      </label>
      <div className="selectCont">
        <select {...props}>{props.children}</select>
        <div className="select_arrow" />
      </div>
      {hasError && <span className="mdlInpErr">{hasError}</span>}
    </section>
  );
};
